import animation from './working_on_it.json';
import { Button } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import { ApiRoutes, UiRoutes } from 'src/constants';

const WorkingOnIt = () => {
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div
      style={{
        margin: 'auto',
        padding: '4rem',
        fontSize: '2rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      We are working on this feature as we speak.
      <Lottie options={defaultOptions} height={400} width={400} />
      <Button
        size="large"
        variant="contained"
        color="info"
        onClick={() => {
          navigate(`${UiRoutes.ADMIN_AM}${ApiRoutes.EMPLOYEE}`);
        }}
      >
        Go Back
      </Button>
    </div>
  );
};

export default WorkingOnIt;
