import { getMonthName } from 'src/utils/formatters';

export const dateToYearAndMonth = (date: string): string => {
  return date.split('T')[0].split('-').slice(0, 2).join('-');
};

export const labelData = (date, dateToCompare, resultData) => {
  const monthAndYear = dateToYearAndMonth(date);
  const monthName = getMonthName(+dateToYearAndMonth(date).split('-')[1]);
  const [y, _m] = monthAndYear.split('-');
  const [prevY, _prevM] = dateToYearAndMonth(dateToCompare || '');
  return {
    shouldAddYear:
      monthName === 'Ene' ||
      (y !== prevY && resultData.length > 0 && !resultData.some((data) => data[0].f.includes(y))),
    monthAndYear,
    monthName,
  };
};
