import { openToast } from '../toast/toastSlice';
import { AppThunk } from '../types';
import { setLoaderOff, setLoaderOn } from '../ui/actions';
import {
  createNotificationError,
  createNotificationPending,
  createNotificationSuccess,
  deleteNotificationError,
  deleteNotificationPending,
  deleteNotificationSuccess,
  getNotificationsError,
  getNotificationsPending,
  getNotificationsSuccess,
} from './actions';
import { Dispatch } from 'redux';
import { addResourceRequest, deleteResourceRequest, getResourceRequest } from 'src/config/api';
import { ApiRoutes } from 'src/constants';

export const getNotifications: AppThunk = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getNotificationsPending());
    dispatch(setLoaderOn());
    try {
      const response = await getResourceRequest(ApiRoutes.NOTIFICATION);
      if (response.error) throw new Error(response.error);
      dispatch(getNotificationsSuccess(response.data));
    } catch (error) {
      dispatch(openToast({ message: error.message, error: true }));
      dispatch(getNotificationsError({ message: error.message, errorType: error.errorType }));
    } finally {
      dispatch(setLoaderOff());
    }
  };
};

export const getActiveNotifications: AppThunk = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getNotificationsPending());
    dispatch(setLoaderOn());
    try {
      const response = await getResourceRequest(`${ApiRoutes.NOTIFICATION}/active?notice=5`);
      if (response.error) throw new Error(response.error);
      dispatch(getNotificationsSuccess(response.data));
    } catch (error) {
      dispatch(getNotificationsError({ message: error.message, errorType: error.errorType }));
      dispatch(openToast({ message: error.message, error: true }));
    } finally {
      dispatch(setLoaderOff());
    }
  };
};

export const createNotification: AppThunk = (data) => {
  return async (dispatch: Dispatch) => {
    dispatch(createNotificationPending());
    dispatch(setLoaderOn());
    try {
      const response = await addResourceRequest(ApiRoutes.NOTIFICATION, data);
      if (response.error) throw new Error(response.error);
      dispatch(createNotificationSuccess(response.data));
      dispatch(openToast({ message: 'Notificación creada con éxito', error: false }));
    } catch (error) {
      dispatch(createNotificationError({ message: error.message, errorType: error.errorType }));
      dispatch(openToast({ message: error.message, error: true }));
    } finally {
      dispatch(setLoaderOff());
    }
  };
};

export const deleteNotification: AppThunk = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteNotificationPending());
    try {
      const response = await deleteResourceRequest(ApiRoutes.NOTIFICATION, id);
      if (response.error) throw new Error(response.error);
      dispatch(deleteNotificationSuccess(id));
      dispatch(openToast({ message: 'Notificación eliminada con éxito', error: false }));
    } catch (error) {
      dispatch(deleteNotificationError({ message: error.message, errorType: error.errorType }));
      dispatch(openToast({ message: error.message, error: true }));
    }
  };
};
