import { Headers } from 'src/components/shared/ui/table/types';

export const userHeaders: Headers[] = [
  { header: 'Nombre', key: 'name' },
  { header: 'Rol de acceso', key: 'accessTypes' },
  { header: 'Email', key: 'email' },
];

enum AccessRoleType {
  SUPER_ADMIN = 'SUPER_ADMIN',
  EMPLOYEE = 'EMPLOYEE',
  ADMIN_AM = 'ADMIN_AM',
  ACCOUNTANCY_AM = 'ACCOUNTANCY_AM',
  ACCOUNTANCY_AL = 'ACCOUNTANCY_AL',
  ACCOUNTANCY_AD = 'ACCOUNTANCY_AD',
}

export const accessRoles = [
  { value: AccessRoleType.ADMIN_AM, label: 'Admin AM' },
  { value: AccessRoleType.EMPLOYEE, label: 'Empleado' },
  { value: AccessRoleType.ACCOUNTANCY_AM, label: 'Accountancy AM' },
  { value: AccessRoleType.ACCOUNTANCY_AL, label: 'Accountancy AL' },
  { value: AccessRoleType.ACCOUNTANCY_AD, label: 'Accountancy AD' },
  { value: AccessRoleType.SUPER_ADMIN, label: 'Super Admin' },
];

export const userFilterOptions = ['name', 'email'];
