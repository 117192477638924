import Admin from './admin';
// import Employees from './employee';
import RouteSelector from './routeSelector';
import SuperAdmin from './superAdmin';
import React from 'react';
import { Route } from 'react-router-dom';
import Layout from 'src/components/layout';
import { Login } from 'src/components/pages';
import NotAllowed from 'src/components/pages/notAllowed';
import PrivateRoute from 'src/components/shared/common/privateRoute';
import WorkingOnIt from 'src/components/shared/common/workingOnIt';
import { AccessRoleType, UiRoutes } from 'src/constants';

const AppRoutes = (): JSX.Element => {
  return (
    <>
      <Route>
        <Route path={UiRoutes.LOGIN} element={<Login />} />
        <Route element={<Layout />}>
          <Route element={<PrivateRoute accessTypes={AccessRoleType.ADMIN_AM} />}>
            <Route path={`${UiRoutes.ADMIN_AM}/*`} element={<Admin />} />
          </Route>
          <Route element={<PrivateRoute accessTypes={AccessRoleType.SUPER_ADMIN} />}>
            <Route path={UiRoutes.SUPER_ADMIN} element={<SuperAdmin />} />
          </Route>
          <Route element={<PrivateRoute accessTypes={AccessRoleType.EMPLOYEE} />}>
            <Route path={`${UiRoutes.EMPLOYEE}/*`} element={<WorkingOnIt />} />
          </Route>
          <Route path={UiRoutes.NOT_ALLOWED} element={<NotAllowed />} />
          <Route path="/*" element={<RouteSelector />} />
        </Route>
      </Route>
    </>
  );
};

export default AppRoutes;
