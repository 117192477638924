import { CompleteFormType } from '../types';
import { isEqual, orderBy } from 'lodash';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import { ModalIDs, openModal } from 'src/redux/modals/modalsSlice';
import {
  blockNavigation,
  unblockNavigation,
} from 'src/redux/navigationBlocker/navigationBlockerSlice';
import { useAppDispatch } from 'src/redux/store';

const ConfirmButton = () => {
  const dispatch = useAppDispatch();
  const {
    formState: { isDirty, defaultValues, isSubmitting },
    watch,
  } = useFormContext<CompleteFormType>();

  const skills = watch('form.skills');
  const projects = watch('projects');
  const absences = watch('absences');

  const disableConfirmButton =
    !isDirty &&
    isEqual(
      orderBy(defaultValues.form.skills, ['name'], ['asc']),
      orderBy(skills, ['name'], ['asc']),
    ) &&
    !projects.membersToPost.length &&
    !projects.membersToPatch.length &&
    !projects.membersToDelete.length &&
    !absences.absenceToPost.length;

  useEffect(() => {
    if (disableConfirmButton) {
      dispatch(unblockNavigation());
    } else {
      dispatch(blockNavigation());
    }
  }, [disableConfirmButton]);

  useEffect(() => {
    if (isSubmitting) {
      dispatch(unblockNavigation());
    }
  }, [isSubmitting]);

  return (
    <Button
      testId="confirmButton"
      materialVariant={Variant.CONTAINED}
      onClick={() => dispatch(openModal(ModalIDs.EMPLOYEE_CONFIRM))}
      label="Guardar cambios"
      disabled={disableConfirmButton}
    />
  );
};

export default ConfirmButton;
