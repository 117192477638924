export enum Actions {
  GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
  GET_USERS_PENDING = 'GET_USERS_PENDING',
  GET_USERS_ERROR = 'GET_USERS_ERROR',
  ADD_USER_SUCCESS = 'ADD_USER_SUCCESS',
  ADD_USER_PENDING = 'ADD_USER_PENDING',
  ADD_USER_ERROR = 'ADD_USER_ERROR',
  ADD_USER_FROM_CSV_SUCCESS = 'ADD_USER_FROM_CSV_SUCCESS',
  ADD_USER_FROM_CSV_PENDING = 'ADD_USER_FROM_CSV_PENDING',
  ADD_USER_FROM_CSV_ERROR = 'ADD_USER_FROM_CSV_ERROR',
  EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS',
  EDIT_USER_PENDING = 'EDIT_USER_PENDING',
  EDIT_USER_ERROR = 'EDIT_USER_ERROR',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  DELETE_USER_PENDING = 'DELETE_USER_PENDING',
  DELETE_USER_ERROR = 'DELETE_USER_ERROR',
  ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS',
  ACTIVATE_USER_PENDING = 'ACTIVATE_USER_PENDING',
  ACTIVATE_USER_ERROR = 'ACTIVATE_USER_ERROR',
  CLEAN_USER_ERROR = 'CLEAN_USER_ERROR',
  HANDLE_SUCCESS = 'HANDLE_SUCCESS',
  GET_USER_BY_UID_PENDING = 'GET_USER_BY_UID_PENDING',
  GET_USER_BY_UID_SUCCESS = 'GET_USER_BY_UID_SUCCESS',
  GET_USER_BY_UID_ERROR = 'GET_USER_BY_UID_ERROR',
  SET_RESOURCE_NAME = 'SET_RESOURCE_NAME',
}
