import Joi from 'joi';

export const activateProjectValidations = () => {
  return Joi.object({
    startDate: Joi.date()
      .messages({
        'any.required': 'La fecha de inicio es requerida',
        'date.base': 'Campo requerido. Formato dd/mm/aaaa',
      })
      .required(),

    endDate: Joi.date()
      .custom((value, helper) => {
        if (value <= new Date()) {
          return helper.error('any.invalid');
        }
      })
      .messages({
        'any.invalid': 'Debe ser mayor a la fecha actual',
      })
      .allow(null),
  }).options({ allowUnknown: true });
};
