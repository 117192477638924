import { openToast } from '../toast/toastSlice';
import { loginError, loginPending } from './actions';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { Dispatch } from 'redux';
import { auth } from 'src/helper/firebase';

export const login = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loginPending());
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        hd: process.env.REACT_APP_EMAIL_DOMAIN,
      });

      const result = await signInWithPopup(auth, provider);

      const {
        claims: { accessTypes },
      } = await result.user.getIdTokenResult();

      return accessTypes as string[];
    } catch (error: unknown) {
      dispatch(openToast({ message: 'Error de login', error: true }));
      if (typeof error === 'string') {
        return dispatch(loginError(error));
      }
    }
  };
};
