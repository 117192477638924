import { RootState } from './redux/store';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

export type AppDispatch<T> = ThunkDispatch<RootState, T, AnyAction>;

export enum Resources {
  Clientes = 'Clientes',
  Proyectos = 'Proyectos',
  Usuarios = 'Usuarios',
  Empleados = 'Empleados',
  Ausencias = 'Ausencias',
  Miembros = 'Miembros',
  Notificaciones = 'Notificaciones',
  PerformanceFeedback = 'Feedback de desempeño',
  SalaryRanges = 'Rangos salariales',
  GrowthPlan = 'Plan de crecimiento',
}

export interface PotentialRole {
  label: string;
  value: string;
}

export interface NotificationType {
  label: string;
  value: NotificationTypes;
}
export enum EmployeeTypeEnum {
  OPERATIVO = 'OPERATIVO',
  ADMINISTRATIVO = 'ADMINISTRATIVO',
}

export enum ProjectRoleType {
  DEV = 'DEV',
  QA = 'QA',
  UX_UI = 'UX/UI',
  PM = 'PM',
  TL = 'TL',
  QA_L = 'QA L',
  UX_UI_L = 'UX/UI L',
}

export enum ExpertiseAreaType {
  DEV = 'DEV',
  QA = 'QA',
  UX_UI = 'UX/UI',
}

export enum RadiumRoleType {
  TRAINEE = 'TRAINEE',
  EXPLORER = 'EXPLORER',
  CREW_MEMBER = 'CREW_MEMBER',
  PILOT = 'PILOT',
  LIEUTENANT = 'LIEUTENANT',
  COMMANDER = 'COMMANDER',
  CAPTAIN = 'CAPTAIN',
}

export type RankType = 0 | 1 | 2 | 3 | 4 | 5;

export enum NotificationTypes {
  EMPLOYEES_WITHOUT_PROJECTS = 101,
  ABSENCES_EMPLOYEE = 102,
  EMPLOYEES_ENDING_PROJECTS = 103,
  EMPLOYEES_PERFORMANCE_FEEDBACK_PENDING = 104,
  GROWTH_PLAN_OBJECTIVE_DATE = 105,
  CLIENTS_WITHOUT_PROJECTS = 201,
  CLIENTS_WITH_CLOSE_END_DATE = 202,
  PROJECTS_WITHOUT_MEMBERS = 301,
  PROJECTS_ABOUT_TO_END = 302,
}
// TODO: Remove and replace by Skills --> in Services
export type SkillsData = {
  _id?: string;
  name: string;
  description?: string;
  isValidated: boolean;
};

export interface HardSkills {
  technicalLevel: number | string;
  toolsAndLanguages: number | string;
  workQuality: number | string;
}

export interface SoftSkills {
  effectiveCommunication: number | string;
  criticalThinking: number | string;
  teamWork: number | string;
  conflictsResolution: number | string;
  empathy: number | string;
  companyCompromise: number | string;
  clientAccomplishment: number | string;
  teachesAndLearns: number | string;
  proactivity: number | string;
  autonomy: number | string;
}

export enum AvailabilityType {
  Available = 'Disponible',
  NotAvailable = 'No Disponible',
  PartTime = 'Tiempo Parcial',
}

export type Absence = {
  _id?: string;
  motive: AbsenceMotives;
  startDate: Date | string;
  endDate: Date | string;
  state?: string;
  icon?: React.JSX.Element;
};

export enum AbsenceMotives {
  VACATIONS = 'Vacaciones',
  LICENSE = 'Licencia',
  STUDY = 'Estudio',
}

export enum AbsenceState {
  APPROVED = 'Aceptada',
  REJECTED = 'Rechazada',
  CANCELED = 'Cancelada',
  PENDING = 'Pendiente',
}

export interface GrowthPlanData {
  _id?: string;
  employee?: string;
  objectiveRole: string;
  objectiveLevel: number;
  objectiveSalary: number;
  objectiveDate: string;
  description: string;
}

export enum CountriesEnum {
  ARGENTINA = 'ARGENTINA',
  URUGUAY = 'URUGUAY',
  USA = 'USA',
}
