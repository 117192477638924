import { ProjectRoleType } from 'src/types';

//TODO: Refactor to use the new roles, ranks and area of expertise.
export interface FormValues {
  _id: string;
  employee: string;
  objectiveRole: ProjectRoleType | string;
  objectiveLevel: number;
  objectiveSalary: number;
  objectiveDate: string;
  objectiveYear: string;
  description?: string;
}

export interface GrowthPlanFormProps {
  firstName: string;
  lastName: string;
  employeeId: string;
  setReqError: (arg: string) => void;
}

export const objectiveDates = [
  { value: '01-01', label: 'Ene' },
  { value: '02-01', label: 'Feb' },
  { value: '03-01', label: 'Mar' },
  { value: '04-01', label: 'Abr' },
  { value: '05-01', label: 'May' },
  { value: '06-01', label: 'Jun' },
  { value: '07-01', label: 'Jul' },
  { value: '08-01', label: 'Ag' },
  { value: '09-01', label: 'Sep' },
  { value: '10-01', label: 'Oct' },
  { value: '11-01', label: 'Nov' },
  { value: '12-01', label: 'Dic' },
];

export const defaultGrowthValues = {
  _id: '',
  employee: '',
  objectiveRole: '',
  objectiveLevel: 0,
  objectiveSalary: 0,
  objectiveYear: '',
  objectiveDate: '',
  description: '',
};
