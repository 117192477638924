import module from './modal.module.css';
import { ModalProps } from './types';
import { Dialog as MaterialModal } from '@mui/material';
import React from 'react';
import { closeModal } from 'src/redux/modals/modalsSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';

const Modal = (props: ModalProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const activeModal = useAppSelector((state) => state.modals.activeModal);
  const {
    id,
    children,
    testId,
    styles,
    sx,
    maxWidth = 'md',
    fullWidth = false,
    isOpen,
    onClose,
  } = props;

  const open = id === activeModal;

  const handleClose = (_, reason) => {
    if (typeof onClose === 'function') {
      reason === 'backdropClick' && onClose(!isOpen);
    } else {
      dispatch(closeModal());
    }
  };

  return (
    <div aria-hidden={!open}>
      <MaterialModal
        onClose={handleClose}
        open={Boolean(open || isOpen)}
        id={testId}
        sx={sx}
        className={module[styles]}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
      >
        {children}
      </MaterialModal>
    </div>
  );
};

export default Modal;
