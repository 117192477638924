import { availabilityOptions, roleCheckboxOptions } from '../employeeEdit/constants';
import SkillsModal from '../skillsModal';
import { EmployeeFilters, MappedEmployeeData, SearchEmployeeData } from '../types';
import ComparisonModal from './comparisonModal';
import { employeeFilterOptions, header } from './constants';
import styles from './employeeList.module.css';
import { Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { FaPen, FaPeopleArrows } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import EmptyDataHandler from 'src/components/shared/common/emptyDataHandler';
import NoResultsMatched from 'src/components/shared/common/noResultsMatched';
import { Button, Loader, Modal, Table } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import SearchBar from 'src/components/shared/ui/searchbar';
import { TableButton } from 'src/components/shared/ui/table/types';
import { UiRoutes } from 'src/constants';
import { setSelectedEmployee } from 'src/redux/employee/actions';
import { getEmployees } from 'src/redux/employee/thunk';
import { ModalIDs, openModal } from 'src/redux/modals/modalsSlice';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';
import { ErrorType } from 'src/redux/types';
import { AvailabilityType, RadiumRoleType, Resources } from 'src/types';
import { formattedTableData } from 'src/utils/formatters';
import { getFilteredDataByTextSearch } from 'src/utils/getFilteredDataByTextSearch';
import { tableRowDataFormatterComponent } from 'src/utils/tableRowDataFormatter';

const filterData = (list, filters: EmployeeFilters) => {
  let filterDataList = list.filter(
    (item) => item.active === filters.isActive && item.role.includes(filters.role),
  );

  if (filters.availability.length) {
    filterDataList = filterDataList.filter((item) => filters.availability === item.availability);
  }

  if (filters.search) {
    filterDataList = getFilteredDataByTextSearch(
      filterDataList,
      employeeFilterOptions,
      filters.search,
    );
  }

  return filterDataList;
};

const EmployeeList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [dataList, setDataList] = useState([]);
  const {
    list: employeeList,
    isLoading,
    error: employeeError,
  } = useAppSelector((state: RootState) => state.employee);
  const activeModal = useAppSelector((state) => state.modals.activeModal);

  const [filters, setFilters] = React.useState<EmployeeFilters>({
    isActive: true,
    role: '',
    search: '',
    availability: '',
  });
  const [employeeIdToCompare, setEmployeeIdToCompare] = React.useState('');

  const listEmployee = useMemo(() => {
    const mappedEmployees = employeeList.reduce((acc, item) => {
      acc.push({
        _id: item?._id,
        name: `${item?.user?.firstName} ${item?.user?.lastName}`,
        projects: formattedTableData(
          item?.memberHistory.filter((item) => item.project?.isActive),
          'project',
          'projectName',
        ),
        email: item?.user?.email,
        active: item?.user?.isActive,
        growthPlan: item?.growthPlan,
        notes: item?.notes,
        skills: item?.skills?.join('-'),
        availability: item?.availability?.description,
        role: item?.role,
      });
      return acc;
    }, []);
    const filteredData = filterData(mappedEmployees, filters);
    return filteredData;
  }, [employeeList, filters.role, filters.search, filters.availability]);

  useEffect(() => {
    const employeesToDisplay = tableRowDataFormatterComponent(listEmployee, 'projects');
    setDataList(employeesToDisplay);
  }, [employeeList, filters.role, filters.search, filters.availability]);

  const showErrorMessage =
    employeeError?.errorType === ErrorType.NETWORK_ERROR || !employeeList.length;

  useEffect(() => {
    dispatch(getEmployees());
  }, []);

  const buttonsArray: TableButton<MappedEmployeeData>[] = [
    // {
    //   active: true,
    //   testId: 'growth-button',
    //   variant: Variant.CONTAINED,
    //   onClick: (row) => {
    //     setEmployeeIdToCompare(row._id);
    //     dispatch(openModal(ModalIDs.EMPLOYEE_GROWTH));
    //   },
    //   icon: <IoStatsChartSharp color={'#373867'} fontSize={'25px'} />,
    // },
    {
      active: true,
      testId: 'compare-button',
      variant: Variant.CONTAINED,
      onClick: (row) => {
        setEmployeeIdToCompare(row._id);
        dispatch(openModal(ModalIDs.EMPLOYEE_COMPARISON));
      },
      icon: <FaPeopleArrows color={'#373867'} fontSize={'25px'} />,
      tooltipLabel: 'Comparar',
    },
    {
      active: true,
      testId: 'edit-button',
      variant: Variant.CONTAINED,
      onClick: (row) => {
        const selectedEmployee = employeeList.find((employee) => employee._id === row._id);
        dispatch(setSelectedEmployee(selectedEmployee));
        navigate(`${UiRoutes.ADMIN_AM}${UiRoutes.EDIT_EMPLOYEES}/${row._id}`);
      },
      icon: <FaPen color={'#373867'} fontSize={'20px'} />,
      tooltipLabel: 'Editar',
    },
  ];

  if (!employeeList || isLoading) return <Loader />;

  return showErrorMessage ? (
    <EmptyDataHandler
      resource={Resources.Empleados}
      handleReload={() => navigate(0)}
      error={employeeError}
      isEmployee
    />
  ) : (
    <>
      {activeModal && (
        <>
          <Modal
            testId={'employee-comparison-modal'}
            id={ModalIDs.EMPLOYEE_COMPARISON}
            fullWidth={true}
            maxWidth="xl"
          >
            <ComparisonModal employeeId={employeeIdToCompare} />
          </Modal>
          {/* <Modal
            maxWidth="lg"
            styles="noScroll"
            testId={'employee-growth-modal'}
            id={ModalIDs.EMPLOYEE_GROWTH}
          >
            <GrowthChartModal id={employeeIdToCompare} />
          </Modal> */}
        </>
      )}
      {activeModal && (
        <Modal testId="skills-modal" id={ModalIDs.SKILLS}>
          <SkillsModal />
        </Modal>
      )}
      <div className={styles.tableContainer}>
        <div className={styles.welcomeMessage}>
          <Typography variant="h1">Empleados</Typography>
        </div>
        <div className={styles.searchBarAndAvailabilityButton}>
          <div className={styles.searchInput}>
            <SearchBar<SearchEmployeeData>
              setFilter={(stringValue) => setFilters({ ...filters, search: stringValue })}
              filter={filters.search}
              keywords="Nombre o Proyecto"
            />
          </div>
          <div className={styles.buttonGroup}>
            <Tooltip placement={'top'} arrow title="Agregar una skill global">
              <span>
                <Button
                  label="ABM de Skills"
                  type="button"
                  materialVariant={Variant.CONTAINED}
                  color="primary"
                  size="large"
                  onClick={() => dispatch(openModal(ModalIDs.SKILLS))}
                />
              </span>
            </Tooltip>
            <Button
              label="Ver Disponibilidad"
              type="button"
              materialVariant={Variant.CONTAINED}
              color="primary"
              size="large"
              onClick={() => {
                navigate(`${UiRoutes.ADMIN_AM}${UiRoutes.EMPLOYEES}${UiRoutes.AVAILABILITY}`);
              }}
            />
          </div>
        </div>
        <div className={styles.checkboxInput} data-testid="filters-container">
          <select
            className={styles.filterDropdown}
            onChange={(e) => {
              setFilters({ ...filters, role: e.target.value as '' | RadiumRoleType });
            }}
            value={filters.role}
          >
            <option value={''} className={styles.option}>
              {'Rol'}
            </option>
            {roleCheckboxOptions?.map((item) => (
              <option key={item.value} value={item.value} className={styles.option}>
                {item.label}
              </option>
            ))}
          </select>
          <select
            className={styles.filterDropdown}
            onChange={(e) => {
              setFilters({ ...filters, availability: e.target.value as '' | AvailabilityType });
            }}
            value={filters.availability}
          >
            <option value={''} className={styles.option}>
              {'Disponibilidad'}
            </option>
            {availabilityOptions?.map((item) => (
              <option key={item.value} value={item.value} className={styles.option}>
                {item.label}
              </option>
            ))}
          </select>
          <div className={styles.filterButtons}>
            <Button
              materialVariant={Variant.TEXT}
              onClick={() => {
                setFilters({
                  ...filters,
                  role: '',
                  search: '',
                  availability: '',
                });
              }}
              label={'Resetear filtros'}
              testId={'reset-filter'}
            />
          </div>
        </div>
        {dataList?.length ? (
          <Table<MappedEmployeeData>
            showButtons
            testId={'employee-table'}
            headers={header}
            value={dataList}
            setDataList={(data) => setDataList(data)}
            profileIcon={true}
            buttons={buttonsArray}
            isActive={filters.isActive}
            filters={filters}
          />
        ) : (
          <NoResultsMatched />
        )}
      </div>
    </>
  );
};

export default EmployeeList;
