import { HardSkills, SoftSkills } from '../../types';

export const SkillsLabels = {
  technicalLevel: 'Grado técnico',
  toolsAndLanguages: 'Lenguajes o herramientas que domina',
  workQuality: 'Calidad del trabajo entregado',
  effectiveCommunication: 'Comunicación efectiva',
  criticalThinking: 'Pensamiento crítico',
  teamWork: 'Trabajo en equipo',
  conflictsResolution: 'Resolución de conflictos',
  empathy: 'Empatía',
  companyCompromise: 'Compromiso con la empresa',
  clientAccomplishment: 'Cumplimiento con el cliente',
  teachesAndLearns: 'Enseña/Aprende',
  proactivity: 'Proactividad',
  autonomy: 'Autonomía',
};

export interface SkillsProps {
  title: string;
  skills: HardSkills | SoftSkills;
  testId: string;
}
