import ConfirmationMessage from '../confirmationMessage';
import Modal from '../modal';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useBlocker } from 'react-router-dom';
import { ModalIDs, closeModal, openModal } from 'src/redux/modals/modalsSlice';
import { unblockNavigation } from 'src/redux/navigationBlocker/navigationBlockerSlice';
import { useAppSelector } from 'src/redux/store';

const NavigationBlocker = () => {
  const dispatch = useDispatch();
  const shouldBlock = useAppSelector((state) => state.navigationBlocker.shouldBlock);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      shouldBlock && currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    const confirmExit = (event) => {
      if (shouldBlock) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.onbeforeunload = confirmExit;
  }, [shouldBlock]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      dispatch(openModal(ModalIDs.NAVIGATION_BLOCKER));
    }
  }, [blocker]);

  return (
    <Modal id={ModalIDs.NAVIGATION_BLOCKER} testId="navigation-blocker-modal">
      <ConfirmationMessage
        title={'Tiene cambios sin guardar'}
        description={'¿Desea cambiar de ruta de todos modos?'}
        handleConfirm={() => {
          blocker.proceed();
          dispatch(unblockNavigation());
          dispatch(closeModal());
        }}
        handleClose={() => {
          blocker.reset();
          dispatch(closeModal());
        }}
      />
    </Modal>
  );
};

export default NavigationBlocker;
