import LinkTab from './linkTab';
import { NavbarProps } from './types';
import { Box, Tabs } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccessRoleType, navbarItems, Orientations } from 'src/constants';
import { RootState, useAppSelector } from 'src/redux/store';
import { closeConfirmationModal } from 'src/redux/ui/actions';
import { AppDispatch } from 'src/types';

const Navbar = (props: NavbarProps) => {
  const navigate = useNavigate();
  const pathValue = useLocation().pathname;
  const accessTypes = useSelector((state: RootState) => state.auth.authUser.accessTypes);
  const shouldBlock = useAppSelector((state) => state.navigationBlocker.shouldBlock);

  const [value, setValue] = React.useState<number>(0);
  const { iconVersion, orientation } = props;
  const dispatch: AppDispatch<null> = useDispatch();

  const accessType = accessTypes?.find(
    (accessType) =>
      accessType === AccessRoleType.ADMIN_AM ||
      accessType === AccessRoleType.EMPLOYEE ||
      accessType === AccessRoleType.SUPER_ADMIN,
  );

  useEffect(() => {
    const initialValue = navbarItems[accessType]?.findIndex((route) =>
      pathValue.includes(route.path),
    );

    setValue(initialValue);
  }, [accessTypes]);

  const handleChange = (_: React.SyntheticEvent, value: number) => {
    !shouldBlock && setValue(value);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  useEffect(() => {
    dispatch(closeConfirmationModal());
  }, [value]);

  return (
    <Box>
      <Tabs
        textColor="secondary"
        indicatorColor="primary"
        value={value}
        onChange={handleChange}
        orientation={orientation || Orientations.HORIZONTAL}
      >
        {navbarItems[accessType]?.map((item) => {
          const Icon = item.icon;
          return (
            <LinkTab
              handleNavigation={handleNavigation}
              key={item.name}
              label={item.name}
              href={item.path}
              tabIcon={iconVersion ? <Icon /> : undefined}
            ></LinkTab>
          );
        })}
      </Tabs>
    </Box>
  );
};

export default Navbar;
