import animation from './server_error.json';
import React from 'react';
import Lottie from 'react-lottie';

const ServerError = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
  };
  return <Lottie options={defaultOptions} height={400} width={400} />;
};

export default ServerError;
