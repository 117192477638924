import { CompleteFormType } from '../types';
import { skillsHeadersEmp } from './constants';
import AutocompleteChip from './skillsAutocompleteChip';
import styles from './skillsModal.module.css';
import { addSkillsValidations } from './validations';
import { joiResolver } from '@hookform/resolvers/joi';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { BsCheckLg } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa6';
import { RxCross2 } from 'react-icons/rx';
import { Button } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import { closeModal } from 'src/redux/modals/modalsSlice';
import { useAppDispatch } from 'src/redux/store';
import { AppDispatch, SkillsData } from 'src/types';

const EmployeeSkillsModal = () => {
  const dispatch: AppDispatch<null> = useAppDispatch();

  const { getValues: getContextValues, setValue: setContextValue } =
    useFormContext<CompleteFormType>();

  const [skills, setSkills] = useState<SkillsData[]>(getContextValues('form.skills')); // Handling state locally.

  const { control, handleSubmit } = useForm<{ skills: SkillsData[] }>({
    defaultValues: {
      skills: [],
    },
    mode: 'onChange',
    resolver: joiResolver(addSkillsValidations(skills.map((data) => data.name))),
  });

  const handleDelete = async (rowId) => {
    setSkills((prevValue) => prevValue.filter((skill) => skill._id !== rowId));
  };

  const onSubmit = async () => {
    const skillsWithoutLabel = (skills as Array<SkillsData & { label: string }>).map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ label, ...propertiesToKeep }) => propertiesToKeep,
    );

    setContextValue('form.skills', skillsWithoutLabel, { shouldDirty: true });
    dispatch(closeModal());
  };

  return (
    <div className={styles.tableContainer}>
      <span className={styles.title}>Agregar skill</span>
      <AutocompleteChip
        control={control}
        name={'skills'}
        currentSkills={skills}
        setCurrentSkills={setSkills}
      />
      <div className={styles.asideIndividualContainer}>
        <span>Lista de skills</span>
      </div>
      {skills?.length ? (
        <>
          <table className={styles.table}>
            <thead>
              <tr>
                {skillsHeadersEmp?.map((header, index) => {
                  return (
                    <th className={styles.header} key={index}>
                      {header.header}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {skills !== undefined &&
                skills?.map((data) => {
                  return (
                    <tr key={data._id}>
                      <td className={styles.rows}>{data.name}</td>
                      <td className={styles.rows}>{data.isValidated ? 'Validado' : 'Pendiente'}</td>
                      <td className={styles.rows}>
                        {data.isValidated ? (
                          <IconButton onClick={() => handleDelete(data._id)}>
                            <FaTrash
                              className={styles.trashButton}
                              color={'#373867'}
                              size={'15px'}
                            />
                          </IconButton>
                        ) : (
                          // TODO: add functionality for this in the future.
                          <div className={styles.checkAndCrossContainer}>
                            <BsCheckLg
                              className={styles.checkAndCross}
                              color={'green'}
                              size={'20px'}
                            />
                            <RxCross2
                              className={styles.checkAndCross}
                              color={'red'}
                              size={'20px'}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </>
      ) : (
        <div>
          <div className={styles.noContent}>Esta tabla de datos se encuentra vacía</div>
        </div>
      )}
      <div className={styles.buttonsContainer}>
        <div>
          <Button
            testId="cancelButton"
            materialVariant={Variant.OUTLINED}
            onClick={() => dispatch(closeModal())}
            label="Cancelar"
          />
        </div>
        <div>
          <Button
            testId="confirmButton"
            materialVariant={Variant.CONTAINED}
            onClick={handleSubmit(onSubmit)}
            label="Confirmar"
            disabled={JSON.stringify(getContextValues('form.skills')) === JSON.stringify(skills)}
          />
        </div>
      </div>
    </div>
  );
};

export default EmployeeSkillsModal;
