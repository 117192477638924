import MemberForm from './memberForm';
import MemberTable from './memberTable';
import ProjectForm from './projectForm';
import styles from './projectMembersLayout.module.css';
import { IconButton } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { FaBell } from 'react-icons/fa6';
import { useParams } from 'react-router-dom';
import CustomNotifications from 'src/components/shared/common/customNotificationForm';
import { Resource } from 'src/components/shared/common/customNotificationForm/types';
import { Modal, Spinner } from 'src/components/shared/ui';
import { cleanSelectedProject } from 'src/redux/project/actions';
import { getProjectAndClients } from 'src/redux/project/thunk';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';
import { closeFormModal, closeModal, openFormModal } from 'src/redux/ui/actions';
import { AppDispatch } from 'src/types';

const ProjectMembersLayout = () => {
  const { id } = useParams();

  const dispatch: AppDispatch<null> = useAppDispatch();
  const showNotificationModal = useAppSelector((state: RootState) => state.ui.showFormModal);

  const showModal = useAppSelector((state: RootState) => state.ui.showModal);
  const membersList = useAppSelector((state: RootState) => state.member.list);
  const employeeList = useAppSelector((state: RootState) => state.employee.list);
  const isLoading = useAppSelector((state: RootState) => state.member.isLoading);
  const [memberId, setMemberId] = React.useState<string>('');

  const matchedMember = membersList.find((member) => memberId === member._id);

  const helperArray = useMemo(() => {
    return matchedMember?.helper?.map((item, index) => {
      return (
        matchedMember.helper.length && {
          ...matchedMember.helper[index],
          helperReference: {
            value: matchedMember.helper[index]?.helperReference?._id,
            label: `${matchedMember.helper[index]?.helperReference?.user?.firstName} ${matchedMember.helper[index]?.helperReference?.user?.lastName}`,
          },
        }
      );
    });
  }, [membersList, memberId]);

  const activeMembersList = id ? membersList?.filter((member) => member.active) : [];

  useEffect(() => {
    dispatch(getProjectAndClients(id));
    return () => {
      dispatch(cleanSelectedProject());
    };
  }, []);

  const formattedMatchedMember = matchedMember && {
    ...matchedMember,
    employee: {
      value: matchedMember?.employee?._id,
      label: `${matchedMember.employee?.user?.firstName} ${matchedMember.employee?.user?.lastName}`,
    },
    helper: helperArray,
    project: matchedMember.project?._id,
  };

  const employeeDropdownList = () => {
    const activeEmployees = employeeList.filter((employee) => employee?.user?.isActive);
    return matchedMember
      ? activeEmployees
      : activeEmployees.reduce((acc, item) => {
          !activeMembersList.some((member) => member?.employee?._id === item._id) && acc.push(item);
          return acc;
        }, []);
  };

  return (
    <div className={styles.container}>
      <div className={styles.welcomeMessage}>
        <div> {id ? 'Editar Proyecto' : 'Nuevo Proyecto'}</div>
        {id && (
          <IconButton onClick={() => dispatch(openFormModal())}>
            <FaBell color={'#373867'} fontSize={'25px'} />
          </IconButton>
        )}
      </div>
      <div>
        <ProjectForm>
          {id && isLoading ? (
            <div className={styles.spinnerContainer}>
              <Spinner />
            </div>
          ) : (
            <MemberTable list={activeMembersList} setMemberId={setMemberId} projectId={id} />
          )}
        </ProjectForm>
      </div>
      <div>
        <Modal
          testId={'project-custom-notification'}
          isOpen={showNotificationModal}
          onClose={() => dispatch(closeFormModal())}
        >
          <CustomNotifications resource={Resource.PROJECT} id={id} />
        </Modal>
      </div>
      <div>
        <Modal
          testId={'User-access-modal'}
          isOpen={!showNotificationModal && showModal}
          onClose={() => dispatch(closeModal())}
        >
          <MemberForm
            projectId={id}
            memberData={formattedMatchedMember}
            dropdownData={employeeDropdownList()}
          />
        </Modal>
      </div>
    </div>
  );
};

export default ProjectMembersLayout;
