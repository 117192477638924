import { Actions } from './constants';
import { ActionsType, State } from './types';
import { Reducer } from 'react';

const initialState: State = {
  isLoading: false,
  showModal: false,
  showComparisonModal: false,
  showFormModal: false,
  showConfirmModal: false,
  showLogoutModal: false,
  showAddProjectFormModal: false,
  showGrowthPlanFormModal: false,
  showSkillsModal: false,
  showProjectModal: false,
  showPerformanceFeedbackModal: false,
  openSidebar: false,
  showApiKeyModal: false,
  showHoursModal: false,
  customLoading: false,
};

const uiReducer: Reducer<State, ActionsType> = (state = initialState, action): State => {
  switch (action.type) {
    case Actions.SET_LOADER_ON:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.SET_LOADER_OFF:
      return {
        ...state,
        isLoading: false,
      };
    case Actions.SET_CUSTOMLOADER_ON:
      return {
        ...state,
        customLoading: true,
      };
    case Actions.SET_CUSTOMLOADER_OFF:
      return {
        ...state,
        customLoading: false,
      };

    case Actions.OPEN_MODAL:
      return {
        ...state,
        showModal: true,
      };
    case Actions.CLOSE_MODAL:
      return {
        ...state,
        showModal: false,
      };
    case Actions.OPEN_FORM_MODAL:
      return {
        ...state,
        showFormModal: true,
      };
    case Actions.CLOSE_FORM_MODAL:
      return {
        ...state,
        showFormModal: false,
      };
    case Actions.OPEN_CONFIRMATION_MODAL:
      return {
        ...state,
        showConfirmModal: true,
      };
    case Actions.CLOSE_CONFIRMATION_MODAL:
      return {
        ...state,
        showConfirmModal: false,
      };
    case Actions.OPEN_LOGOUT_MODAL:
      return {
        ...state,
        showLogoutModal: true,
      };
    case Actions.CLOSE_LOGOUT_MODAL:
      return {
        ...state,
        showLogoutModal: false,
      };
    case Actions.OPEN_PROJECTS_MODAL:
      return {
        ...state,
        showProjectModal: true,
      };
    case Actions.CLOSE_PROJECTS_MODAL:
      return {
        ...state,
        showProjectModal: false,
      };
    case Actions.OPEN_SIDEBAR:
      return {
        ...state,
        openSidebar: true,
      };
    case Actions.CLOSE_SIDEBAR:
      return {
        ...state,
        openSidebar: false,
      };
    case Actions.OPEN_API_KEY_MODAL:
      return {
        ...state,
        showApiKeyModal: true,
      };
    case Actions.CLOSE_API_KEY_MODAL:
      return {
        ...state,
        showApiKeyModal: false,
      };
    case Actions.OPEN_HOURS_MODAL:
      return {
        ...state,
        showHoursModal: true,
      };
    case Actions.CLOSE_HOURS_MODAL:
      return {
        ...state,
        showHoursModal: false,
      };
    default:
      return state;
  }
};

export default uiReducer;
