import styles from './autocompleteChip.module.css';
import { AutocompleteProps } from './types';
import { Alert } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { FieldValues, useController, FieldError } from 'react-hook-form';
import { Loader } from 'src/components/shared/ui';
import api from 'src/config/api';
import { ApiRoutes } from 'src/constants';
import { SkillsData } from 'src/types';

const AutocompleteChip = <Form extends FieldValues>(
  props: AutocompleteProps<Form>,
): JSX.Element => {
  const [possibleSkills, setPossibleSkills] = useState<SkillsData[]>(null);
  const [allSkills, setAllSkills] = useState<SkillsData[]>(null);
  const [loading, setLoading] = useState(true);
  const { control, name, currentSkills, setCurrentSkills } = props;
  const {
    field: { value: skillChips, onChange },
    fieldState: { error },
  } = useController({ name, control });

  const errorArray = Array.isArray(error) ? error : [error];
  const lastErrorMessage = errorArray.filter((error: FieldError) => error?.message).pop();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`${ApiRoutes.SKILLS}`);
        const result = response.data.data.filter((skill) => {
          return !currentSkills.some((empSkill) => empSkill._id === skill._id);
        });

        setAllSkills(response.data.data);
        setPossibleSkills(result);
      } catch (error) {
        // TODO: handle error in toast
        console.error('Error al obtener skills:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!allSkills) return;

    // when removing from list, we need to remove the chip.
    if (skillChips) {
      skillChips.forEach((skillChip: SkillsData) => {
        if (!currentSkills.find((currentSkill) => currentSkill._id === skillChip._id)) {
          // remove from skillChips
          onChange(skillChips.filter((item) => item._id !== skillChip._id));
        }
      });
    }

    // And also update the possible skills for the dropdown list.
    const result = allSkills.filter((skill) => {
      return !currentSkills.some((empSkill) => empSkill._id === skill._id);
    });

    setPossibleSkills(result); // Update list with new possibleSkills after removing a skill
  }, [currentSkills]);

  const handleDelete = async (rowId: string) => {
    setCurrentSkills((prevValue) => prevValue.filter((skill) => skill._id !== rowId));
  };

  if (loading) return <Loader />;

  return (
    <div className={styles.container}>
      {currentSkills.length === allSkills.length && (
        <Alert severity="warning" variant="filled">
          No hay más skills para seleccionar
        </Alert>
      )}
      {(skillChips.length > 0 || possibleSkills.length > 0) && (
        <Autocomplete
          multiple
          value={skillChips}
          id="tags-filled"
          options={possibleSkills?.map((option) => {
            return {
              ...option,
              label: option.name,
            };
          })}
          renderTags={(value, getTagProps) =>
            value.map((option, index: number) => (
              <Chip
                key={index}
                variant="outlined"
                label={option.label}
                {...getTagProps({ index })}
                className={styles.chips}
                onDelete={() => {
                  handleDelete(option._id);
                  const newValue = [...value];
                  newValue.splice(index, 1);
                  onChange(newValue);
                }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={lastErrorMessage?.message || ' '}
              error={Boolean(error)}
              variant="outlined"
              label="Skills"
              size="medium"
              placeholder="Select or add skill"
              color="info"
            />
          )}
          onChange={(_, value) => {
            const combinedSkills = [...currentSkills, ...(value as SkillsData[])];

            // De-duplicate based on a unique property.
            const uniqueSkillsArray = combinedSkills.reduce((acc, currentValue) => {
              const existingItem = acc.find((item) => item._id === currentValue._id);
              if (!existingItem) {
                return [...acc, currentValue];
              }
              return acc;
            }, [] as SkillsData[]);

            setCurrentSkills(uniqueSkillsArray); // Update the state with de-duplicated skills
            onChange(value);
          }}
          data-testid={'autocompleteTestId'}
        />
      )}
    </div>
  );
};

export default AutocompleteChip;
