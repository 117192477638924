import { criticalityOptions, projectTypeOptions } from './constants';
import styles from './projectForm.module.css';
import { ProjectFormProps } from './types';
import { projectValidation } from './validations';
import { joiResolver } from '@hookform/resolvers/joi';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Criticality, ProjectFormValues, ProjectType } from 'src/components/pages/projects/types';
import {
  Button,
  ConfirmationMessage,
  DatePicker,
  Dropdown,
  Modal,
  TextInput,
} from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import EndDateCheckbox from 'src/components/shared/ui/inputs/endDateCheckbox';
import { getResourceRequest } from 'src/config/api';
import { ApiRoutes, UiRoutes } from 'src/constants';
import { Client } from 'src/redux/client/types';
import { createProject, editProject } from 'src/redux/project/thunk';
import { RootState } from 'src/redux/store';
import { closeConfirmationModal, openConfirmationModal } from 'src/redux/ui/actions';
import { AppDispatch, Resources } from 'src/types';

const ProjectForm = (props: ProjectFormProps) => {
  const handleNavigation = (path) => {
    navigate(path);
  };
  const { children } = props;

  const { id } = useParams();
  const dispatch: AppDispatch<null> = useDispatch();
  const navigate = useNavigate();

  const showConfirmModal = useSelector((state: RootState) => state.ui.showConfirmModal);
  const selectedProject = useSelector((state: RootState) => state.project.selectedProject);
  const clientList = useSelector((state: RootState) => state.client.list);

  const [endDateDisabled, setEndDateDisabled] = useState(false);
  const [selectedClient, setSelectedClient] = useState({} as Client);
  const [boxNotChecked, setboxNotChecked] = useState(false);
  const [projectNameValidation, setProjectNameValidation] = useState(false);
  const [projectReqState, setProjectReqState] = useState<boolean>(false);

  const {
    formState: { isDirty, isValid },
    control,
    reset,
    watch,
    trigger,
    getValues,
    setValue,
    handleSubmit,
  } = useForm<ProjectFormValues>({
    defaultValues: {
      projectName: '',
      clientName: '',
      startDate: null,
      endDate: null,
      projectType: ProjectType.STAFF_AUGMENTATION,
      isCritic: Criticality.ALTA,
      description: '',
      notes: '',
    },
    mode: 'all',
    resolver: joiResolver(
      projectValidation(
        selectedClient?.relationshipStart,
        selectedClient?.relationshipEnd,
        projectNameValidation,
      ),
    ),
  });

  const nameValidationTrigger = async () => {
    await trigger('projectName');
  };

  useEffect(() => {
    if (getValues('projectName')) {
      nameValidationTrigger();
    }
  }, [projectNameValidation]);

  useEffect(() => {
    if (Object.keys(selectedProject).length) {
      reset({
        projectName: selectedProject.projectName,
        clientName: selectedProject.clientName?._id,
        startDate: new Date(selectedProject.startDate),
        endDate: selectedProject.endDate ? new Date(selectedProject.endDate) : undefined,
        projectType: selectedProject.projectType as ProjectType,
        isCritic: selectedProject.isCritic as Criticality,
        description: selectedProject.description,
        notes: selectedProject.notes,
      });
      setEndDateDisabled(!selectedProject.endDate);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (!boxNotChecked) setValue('endDate', undefined, { shouldDirty: true });
  }, [boxNotChecked]);

  const clientDropdownList = clientList?.reduce((acc, item) => {
    if (item.isActive) {
      acc.push({ value: item._id, label: item.name });
    }
    return acc;
  }, []);

  const handleEndDateDisable = (data) => {
    setEndDateDisabled(data);
  };

  const startDate = watch('startDate');
  const clientId = watch('clientName');
  const projectName = getValues('projectName');

  const datesValidationsTrigger = async () => {
    await trigger('startDate');
    await trigger('endDate');
  };
  const nameChangeHandler = useCallback(
    debounce(async (e, project) => {
      const inputValue = e.target.value.trim();
      if (id && inputValue.toLowerCase() === project.projectName.toLowerCase()) {
        return;
      }
      try {
        setProjectReqState(true);
        const response = await getResourceRequest(
          `${ApiRoutes.PROJECTS}?projectName=${inputValue}`,
        );

        if (response.error) throw new Error(response.error);
        setProjectNameValidation(Boolean(response.data.length));
      } catch (error: unknown) {
        setProjectNameValidation(true);
      } finally {
        setProjectReqState(false);
      }
    }, 500),
    [],
  );

  useEffect(() => {
    datesValidationsTrigger();
  }, [selectedClient, startDate]);

  useEffect(() => {
    setSelectedClient(clientList.find((client) => client._id === clientId));
  }, [clientId]);

  const onSubmit = async (data) => {
    const body: ProjectFormValues = {
      projectName: projectName,
      clientName: data.clientName,
      startDate: data.startDate,
      projectType: data.projectType,
      isCritic: data.isCritic,
      description: data.description,
      notes: data.notes,
    };

    if (!endDateDisabled) body.endDate = data.endDate;

    const options = {
      id: id,
      body,
    };

    if (id) {
      await dispatch(editProject(options));
    } else {
      await dispatch(createProject(options));
    }
    dispatch(closeConfirmationModal());
    navigate(`${UiRoutes.ADMIN_AM}${UiRoutes.PROJECTS}`);
  };

  const formChanged = !isDirty || projectReqState || !isValid;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formContainer}>
          <div className={styles.handleLeftContainer}>
            <div className={styles.userInfoContainer}>
              <div className={styles.firstRow}>
                <div className={styles.elementContainer}>
                  <TextInput
                    control={control}
                    testId={'projectName'}
                    label="Nombre del Proyecto"
                    name="projectName"
                    type={'text'}
                    variant="outlined"
                    fullWidth
                    handleOnChange={(e) => nameChangeHandler(e, selectedProject)}
                  />
                </div>
                <div className={styles.elementContainer}>
                  <Dropdown
                    control={control}
                    testId={'projectType'}
                    label="Tipo de Proyecto"
                    name="projectType"
                    options={projectTypeOptions}
                    fullWidth
                  />
                </div>
              </div>
              <div className={styles.secondRow}>
                <div className={styles.elementContainer}>
                  <Dropdown
                    control={control}
                    testId={'clientName'}
                    label={'Cliente'}
                    name="clientName"
                    options={clientDropdownList}
                    fullWidth
                    disabled={id && true}
                  />
                </div>
                <div className={styles.elementContainer}>
                  <Dropdown
                    control={control}
                    testId={'criticality'}
                    label="Criticidad"
                    name="isCritic"
                    options={criticalityOptions}
                    fullWidth
                  />
                </div>
              </div>
              <div className={styles.thirdRow}>
                <div className={styles.dateContainer}>
                  <div className={styles.dateSelection}>
                    <DatePicker
                      label={'Inicio'}
                      testId={'startDate'}
                      name="startDate"
                      minDate={selectedClient?.relationshipStart}
                      maxDate={selectedClient?.relationshipEnd}
                      control={control}
                    />
                    <EndDateCheckbox
                      changed={boxNotChecked}
                      setChanged={() => {
                        setboxNotChecked(!boxNotChecked);
                      }}
                      endDateDisabled={endDateDisabled}
                      handleEndDateDisable={handleEndDateDisable}
                      resource={Resources.Proyectos}
                    />
                  </div>
                  <div className={styles.dateSelection}>
                    <DatePicker
                      disabled={endDateDisabled}
                      label={'Fin'}
                      testId={'endDate'}
                      name="endDate"
                      minDate={startDate}
                      maxDate={selectedClient?.relationshipEnd}
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.fourthRow}>
                <div className={styles.rightInputs}>
                  <TextInput
                    control={control}
                    testId={'description'}
                    label="Descripcion"
                    name="description"
                    type={'text'}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={5}
                  />
                </div>
                <div className={styles.rightInputs}>
                  <TextInput
                    control={control}
                    testId={'notesInput'}
                    label="Notas"
                    name="notes"
                    type={'text'}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={5}
                  />
                </div>
              </div>
            </div>
            {children}
            <div className={styles.buttonContainer}>
              <div className={styles.saveButton}>
                <Button
                  testId="saveButton"
                  materialVariant={Variant.CONTAINED}
                  onClick={id ? () => dispatch(openConfirmationModal()) : handleSubmit(onSubmit)}
                  label="Guardar"
                  disabled={formChanged}
                />
                <Button
                  testId="cancelButton"
                  materialVariant={Variant.OUTLINED}
                  onClick={() => handleNavigation(`${UiRoutes.ADMIN_AM}${UiRoutes.PROJECTS}`)}
                  label="Volver"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal
        testId="editProjectModal"
        styles={styles.modal}
        isOpen={showConfirmModal}
        onClose={() => dispatch(closeConfirmationModal())}
      >
        <ConfirmationMessage
          description={`¿Desea editar al proyecto ${selectedProject.projectName}?`}
          title={'Editar Proyecto'}
          handleConfirm={handleSubmit(onSubmit)}
          handleClose={() => dispatch(closeConfirmationModal())}
        />
      </Modal>
    </>
  );
};

export default ProjectForm;
