import { Variant } from '../buttons/button/types';
import { Button } from '../index';
import styles from './confirmationMessage.module.css';
import { ConfirmationMessageProps } from './types';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, Collapse, IconButton } from '@mui/material';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { FaArrowRightFromBracket } from 'react-icons/fa6';

const ConfirmationMessage = (props: ConfirmationMessageProps) => {
  const {
    title,
    handleConfirm,
    handleClose,
    description,
    testIdDescription,
    testIdTitle,
    color,
    userModal,
    confirmationLabel,
    confirmAndExitLabel,
    handleConfirmAndExit,
    submitting,
  } = props;

  const [open, setOpen] = React.useState(true);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          {title === 'Alerta' ? (
            <Alert data-testid={testIdTitle} severity="warning" className={styles.warning}>
              <AlertTitle>¡Alerta!</AlertTitle>
            </Alert>
          ) : (
            <div data-testid={testIdTitle} className={styles.title}>
              {title}
            </div>
          )}
          {color && (
            <div className={styles.logoutIcon}>
              <FaArrowRightFromBracket color={color} />
            </div>
          )}
        </div>
        <div className={styles.description}>
          <p data-testid={testIdDescription}>{description}</p>
        </div>
        <div className={styles.buttonsContainer}>
          {submitting ? (
            <CircularProgress size={25} color={'info'} />
          ) : (
            <>
              <Button
                testId="cancel-button"
                materialVariant={Variant.OUTLINED}
                label="Cancelar"
                onClick={handleClose}
              />
              <Button
                testId="confirm-button"
                materialVariant={Variant.CONTAINED}
                label={confirmationLabel || 'Confirmar'}
                onClick={handleConfirm}
                color={color}
              />
            </>
          )}
          {handleConfirmAndExit && (
            <Button
              testId="confirm-exit-button"
              materialVariant={Variant.CONTAINED}
              label={confirmAndExitLabel || 'Confirmar y salir'}
              onClick={handleConfirmAndExit}
            />
          )}
        </div>
      </div>
      <div className={styles.warningContainer}>
        {userModal?.length && props.title !== 'Activar usuario' && (
          <>
            <Collapse in={open}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <AlertTitle>¡Cuidado!</AlertTitle>
                El empleado que está intentando desactivar está asignado a uno o más proyectos
              </Alert>
            </Collapse>
          </>
        )}
      </div>
    </>
  );
};

export default ConfirmationMessage;
