export enum Actions {
  GET_CLIENTS_PENDING = 'GET_CLIENTS_PENDING',
  GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS',
  GET_CLIENTS_ERROR = 'GET_CLIENTS_ERROR',
  GET_CLIENT_BY_ID_PENDING = 'GET_CLIENT_BY_ID_PENDING',
  GET_CLIENT_BY_ID_SUCCESS = 'GET_CLIENT_BY_ID_SUCCESS',
  GET_CLIENT_BY_ID_ERROR = 'GET_CLIENT_BY_ID_ERROR',
  DELETE_CLIENT_PENDING = 'DELETE_CLIENT_PENDING',
  DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS',
  DELETE_CLIENT_ERROR = 'DELETE_CLIENT_ERROR',
  ADD_CLIENT_PENDING = 'ADD_CLIENT_PENDING',
  ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS',
  ADD_CLIENT_ERROR = 'ADD_CLIENT_ERROR',
  EDIT_CLIENT_PENDING = 'EDIT_CLIENT_PENDING',
  EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS',
  EDIT_CLIENT_ERROR = 'EDIT_CLIENT_ERROR',
  CLEAR_SELECTED_CLIENT = 'CLEAR_SELECTED_CLIENT',
}
