import { Actions } from './constants';
import { action } from 'typesafe-actions';

export const setLoaderOn = () => action(Actions.SET_LOADER_ON);
export const setLoaderOff = () => action(Actions.SET_LOADER_OFF);
export const openModal = () => action(Actions.OPEN_MODAL);
export const closeModal = () => action(Actions.CLOSE_MODAL);
export const openFormModal = () => action(Actions.OPEN_FORM_MODAL);
export const closeFormModal = () => action(Actions.CLOSE_FORM_MODAL);
export const openConfirmationModal = () => action(Actions.OPEN_CONFIRMATION_MODAL);
export const closeConfirmationModal = () => action(Actions.CLOSE_CONFIRMATION_MODAL);
export const openLogoutModal = () => action(Actions.OPEN_LOGOUT_MODAL);
export const closeLogoutModal = () => action(Actions.CLOSE_LOGOUT_MODAL);
export const openProjectsModal = () => action(Actions.OPEN_PROJECTS_MODAL);
export const closeProjectsModal = () => action(Actions.CLOSE_PROJECTS_MODAL);
export const setOpenSidebar = () => action(Actions.OPEN_SIDEBAR);
export const setCloseSidebar = () => action(Actions.CLOSE_SIDEBAR);
export const openApiKeyModal = () => action(Actions.OPEN_API_KEY_MODAL);
export const closeApiKeyModal = () => action(Actions.CLOSE_API_KEY_MODAL);
export const openHoursModal = () => action(Actions.OPEN_HOURS_MODAL);
export const closeHoursModal = () => action(Actions.CLOSE_HOURS_MODAL);
export const setCustomLoaderOn = () => action(Actions.SET_CUSTOMLOADER_ON);
export const setCustomLoaderOff = () => action(Actions.SET_CUSTOMLOADER_OFF);
