import { Actions } from './constants';
import { MovementsCardProps } from 'src/components/shared/ui/movementsCard/types';
import { ErrorFormat } from 'src/redux/types';
import { action } from 'typesafe-actions';

export const getMovementsPending = () => action(Actions.GET_MOVEMENTS_PENDING);

export const getMovementsError = (error: ErrorFormat) => action(Actions.GET_MOVEMENTS_ERROR, error);

export const getMovementsSuccess = (movementsList: MovementsCardProps[]) =>
  action(Actions.GET_MOVEMENTS_SUCCESS, movementsList);
