import { ButtonProps } from './types';
import { Button as MaterialButton } from '@mui/material';
import React from 'react';

const Button = (props: ButtonProps): JSX.Element => {
  const { onClick, label, disabled, materialVariant, styles, testId, color, type, sx, size } =
    props;

  return (
    <MaterialButton
      data-testid={testId}
      variant={materialVariant}
      onClick={onClick}
      disabled={disabled}
      className={styles}
      color={color || 'info'}
      type={type}
      sx={sx}
      size={size}
    >
      {label}
    </MaterialButton>
  );
};

export default Button;
