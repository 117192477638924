import { openToast } from '../toast/toastSlice';
import { AppThunk } from '../types';
import { setLoaderOff, setLoaderOn } from '../ui/actions';
import {
  activateUserError,
  activateUserPending,
  activateUserSuccess,
  addUserPending,
  addUsersError,
  addUsersFromCSVError,
  addUsersFromCSVPending,
  addUsersFromCSVSuccess,
  addUserSuccess,
  deleteUserError,
  deleteUserPending,
  deleteUserSuccess,
  editUserError,
  editUserPending,
  editUserSuccess,
  getUserByUidError,
  getUserByUidPending,
  getUserByUidSuccess,
  getUsersError,
  getUsersPending,
  getUsersSuccess,
  handleSuccess,
} from './actions';
import { User } from './types';
import { Dispatch } from 'redux';
import { router } from 'src';
import {
  activateResourceRequest,
  addManyResourcesRequest,
  addResourceRequest,
  deleteResourceRequest,
  editResourceRequest,
  getResourceRequest,
} from 'src/config/api';
import { ApiRoutes } from 'src/constants';
import firebaseApp from 'src/helper/firebase';

export const getUsers: AppThunk = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getUsersPending());
    dispatch(setLoaderOn());
    try {
      const response = await getResourceRequest(ApiRoutes.USER);
      if (response.error) throw new Error(response.error);
      dispatch(getUsersSuccess(response.data));
    } catch (error) {
      dispatch(getUsersError({ message: error.message, errorType: error.errorType }));
      dispatch(openToast({ message: error.message, error: true }));
    } finally {
      dispatch(setLoaderOff());
    }
  };
};

export const getUserByUid: AppThunk = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getUserByUidPending());
    try {
      const response = await getResourceRequest(`${ApiRoutes.USER}/uid`);
      const data = response.data;
      if (response.error) throw new Error(response.error);
      dispatch(getUserByUidSuccess(data as User));
    } catch (error) {
      dispatch(getUserByUidError({ message: error.message, errorType: error.errorType }));
      await firebaseApp.auth().signOut();
      dispatch(openToast({ error: true, message: 'Error logging in' }));
      router.navigate('/login');
      // TODO: add open toast dispatch after login error is solved
    }
  };
};

export const addUser: AppThunk = (data) => {
  return async (dispatch: Dispatch) => {
    dispatch(addUserPending());
    dispatch(setLoaderOn());
    try {
      const response = await addResourceRequest(ApiRoutes.USER, data);
      if (response.error) throw new Error(response.error);
      dispatch(addUserSuccess(response.data));
      dispatch(handleSuccess());
      dispatch(openToast({ message: 'Usuario agregado con éxito', error: false }));
    } catch (error) {
      dispatch(addUsersError({ message: error.message, errorType: error.errorType }));
      dispatch(openToast({ message: error.message, error: true }));
    } finally {
      dispatch(setLoaderOff());
    }
  };
};

export const addUsersFromCSV: AppThunk = (usersArray) => {
  return async (dispatch: Dispatch) => {
    dispatch(addUsersFromCSVPending());
    dispatch(setLoaderOn());
    try {
      const response = await addManyResourcesRequest(ApiRoutes.USER, usersArray);
      if (response.error) throw new Error(response.error);
      dispatch(addUsersFromCSVSuccess(response.data));
      dispatch(openToast({ message: 'Usuarios agregados con éxito', error: false }));
    } catch (error) {
      dispatch(addUsersFromCSVError({ message: error.message, errorType: error.errorType }));
      dispatch(openToast({ message: error.message, error: true }));
    } finally {
      dispatch(setLoaderOff());
    }
  };
};

export const editUser: AppThunk = (options: { id: string; body: User }, isToProfile = false) => {
  return async (dispatch: Dispatch) => {
    dispatch(editUserPending());
    if (!isToProfile) dispatch(setLoaderOn());
    try {
      const response = await editResourceRequest(ApiRoutes.USER, options);
      if (response.error) throw new Error(response.error);
      dispatch(editUserSuccess(response.data, options.id));

      dispatch(
        openToast({
          message: 'Usuario editado con éxito',
          error: false,
        }),
      );
    } catch (error) {
      dispatch(editUserError({ message: error.message, errorType: error.errorType }));
      dispatch(openToast({ message: error.message, error: true }));
    } finally {
      if (!isToProfile) dispatch(setLoaderOff());
    }
  };
};

export const deleteUser: AppThunk = (id) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteUserPending());
    dispatch(setLoaderOn());
    try {
      const response = await deleteResourceRequest(ApiRoutes.USER, id);
      if (response.error) throw new Error(response.error);
      dispatch(openToast({ message: 'Usuario desactivado con éxito', error: false }));
      dispatch(deleteUserSuccess(id));
    } catch (error) {
      dispatch(deleteUserError({ message: error.message, errorType: error.errorType }));
      dispatch(openToast({ message: error.message, error: true }));
    } finally {
      dispatch(setLoaderOff());
    }
  };
};

export const activateUser: AppThunk = (id) => {
  return async (dispatch: Dispatch) => {
    dispatch(activateUserPending());
    dispatch(setLoaderOn());
    try {
      const response = await activateResourceRequest(ApiRoutes.USER, id);
      if (response.error) throw new Error(response.error);
      dispatch(openToast({ message: 'Usuario activado con éxito', error: false }));
      dispatch(activateUserSuccess(id));
    } catch (error) {
      dispatch(activateUserError({ message: error.message, errorType: error.errorType }));
      dispatch(openToast({ message: error.message, error: true }));
    } finally {
      dispatch(setLoaderOff());
    }
  };
};
