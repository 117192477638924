import { openToast } from '../toast/toastSlice';
import { setLoaderOff, setLoaderOn } from '../ui/actions';
import {
  addMemberError,
  addMemberPending,
  addMemberSuccess,
  deleteMemberError,
  deleteMemberPending,
  deleteMemberSuccess,
  editMemberError,
  editMemberPending,
  editMemberSuccess,
  getMembersError,
  getMembersPending,
  getMembersSuccess,
} from './actions';
import { AddMemberDTO, EditMemberDTO } from './types';
import { Dispatch } from 'redux';
import {
  addResourceRequest,
  deleteResourceRequest,
  editResourceRequest,
  getByFilterResourceRequest,
} from 'src/config/api';
import { ApiRoutes } from 'src/constants';
import { AppThunk } from 'src/redux/types';

export const getMembers: AppThunk = (filter) => {
  return async (dispatch: Dispatch) => {
    dispatch(getMembersPending());
    try {
      const response = await getByFilterResourceRequest(ApiRoutes.MEMBER, filter);
      if (response?.error) throw new Error(response?.error);
      dispatch(getMembersSuccess(response.data));
    } catch (error) {
      dispatch(openToast({ message: error.message, error: true }));
      dispatch(getMembersError({ message: error.message, errorType: error.errorType }));
    }
  };
};

export const addMember: AppThunk = (data: AddMemberDTO) => {
  return async (dispatch: Dispatch) => {
    dispatch(addMemberPending());
    dispatch(setLoaderOn());
    try {
      const response = await addResourceRequest(ApiRoutes.MEMBER, data);
      if (response.error) throw new Error(response.error);
      dispatch(openToast({ message: 'Miembro agregado con exito', error: false }));
      dispatch(addMemberSuccess(response.data));
    } catch (error) {
      dispatch(openToast({ message: error.message, error: true }));
      dispatch(addMemberError({ message: error.message, errorType: error.errorType }));
    } finally {
      dispatch(setLoaderOff());
    }
  };
};

export const editMember: AppThunk = (options: EditMemberDTO) => {
  return async (dispatch: Dispatch) => {
    dispatch(editMemberPending());
    dispatch(setLoaderOn());
    try {
      const response = await editResourceRequest(ApiRoutes.MEMBER, options);
      if (response.error) throw new Error(response.error);
      dispatch(editMemberSuccess(response.data, options.id));
      dispatch(openToast({ message: 'Miembro editado con exito', error: false }));
    } catch (error) {
      dispatch(openToast({ message: error.message, error: true }));
      dispatch(editMemberError({ message: error.message, errorType: error.errorType }));
    } finally {
      dispatch(setLoaderOff());
    }
  };
};
export const deleteMember: AppThunk = (id) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteMemberPending());
    try {
      const response = await deleteResourceRequest(ApiRoutes.MEMBER, id);
      if (response.error) throw new Error(response.error);
      dispatch(openToast({ message: 'Miembro eliminado con exito', error: false }));
      dispatch(deleteMemberSuccess(id));
    } catch (error) {
      dispatch(openToast({ message: error.message, error: true }));
      dispatch(deleteMemberError({ message: error.message, errorType: error.errorType }));
    }
  };
};
