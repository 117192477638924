import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector, RootState } from 'src/redux/store';
import { closeToast } from 'src/redux/toast/toastSlice';
import { AppDispatch } from 'src/types';

const AlertMsg = (props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(AlertMsg);

const Toast = () => {
  const dispatch: AppDispatch<null> = useDispatch();

  const toastMessage = useAppSelector((state: RootState) => state.toast.toastMessage);
  const isError = useAppSelector((state: RootState) => state.toast.isError);

  const handleClose = (_?: React.SyntheticEvent | Event, reason?: string) => {
    dispatch(closeToast());
    if (reason === 'clickaway') {
      return;
    }
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={toastMessage !== ''} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          data-testid="snackbar"
          onClose={handleClose}
          severity={isError ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default Toast;
