import Joi from 'joi';

export const addSkillsValidations = (selectedEmployeeSkillsName) => {
  return Joi.object({
    name: Joi.array()
      .custom((value, helper) => {
        if (!Array.isArray(value)) {
          return helper.error('array.base');
        }
        const invalidSkills = selectedEmployeeSkillsName
          .map((item) => item.toUpperCase())
          .filter((item) => value.includes(item));
        if (invalidSkills.length > 0) {
          return helper.error('any.invalid', { invalidSkills });
        }
      })
      .items(Joi.string().min(3).uppercase())
      .messages({
        'array.base': 'Debe ser un array válido',
        'string.min': 'La skill debe contener al menos 3 caracteres',
        'any.invalid': 'Las siguientes skills ya pertenecen al empleado: {{#invalidSkills}}',
      }),
  }).options({ allowUnknown: true });
};
