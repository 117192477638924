export enum Actions {
  GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS',
  GET_MEMBERS_PENDING = 'GET_MEMBERS_PENDING',
  GET_MEMBERS_ERROR = 'GET_MEMBERS_ERROR',
  ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS',
  ADD_MEMBER_PENDING = 'ADD_MEMBER_PENDING',
  ADD_MEMBER_ERROR = 'ADD_MEMBER_ERROR',
  EDIT_MEMBER_SUCCESS = 'EDIT_MEMBER_SUCCESS',
  EDIT_MEMBER_PENDING = 'EDIT_MEMBER_PENDING',
  EDIT_MEMBER_ERROR = 'EDIT_MEMBER_ERROR',
  DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS',
  DELETE_MEMBER_PENDING = 'DELETE_MEMBER_PENDING',
  DELETE_MEMBER_ERROR = 'DELETE_MEMBER_ERROR',
}
