import { Headers } from 'src/components/shared/ui/table/types';

export const projectHeadersEmp: Headers[] = [
  { header: 'Proyecto', key: 'name' },
  { header: 'Rol', key: 'role' },
  { header: 'Seniority', key: 'seniority' },
  { header: 'Inicio', key: 'startDate' },
  { header: 'Fin', key: 'endDate' },
  { header: 'Horas', key: 'hours' },
  { header: 'Accion', key: 'action' },
];
