import { CompleteFormType } from '../../types';
import styles from './editMemberForm.module.css';
import { EditMemberFormType } from './types';
import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import {
  AutocompleteInput,
  Button,
  DatePicker,
  Dropdown,
  Loader,
  TextInput,
} from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import api from 'src/config/api';
import { ApiRoutes, roles, seniorityOptions } from 'src/constants';
import { Employee } from 'src/redux/employee/types';
import { EditMemberDTO } from 'src/redux/member/types';
import { closeModal } from 'src/redux/modals/modalsSlice';
import { Project } from 'src/redux/project/types';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { ProjectRoleType } from 'src/types';

type Props = { selectedMemberId: string };

function EditMemberForm({ selectedMemberId }: Props) {
  const dispatch = useAppDispatch();
  const [employeeList, setEmployeeList] = useState<Employee[]>([]);
  const [projectsList, setProjectsList] = useState<Project[]>([]);
  const [isLongTerm, setIsLongTerm] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);

  const selectedEmployee = useAppSelector((state) => state.employee.selectedEmployee);

  const { getValues: getContextValues, setValue: setContextValue } =
    useFormContext<CompleteFormType>();

  const member = useMemo(() => {
    const member = getContextValues('projects.membersList').find(
      (mh) => mh._id === selectedMemberId,
    );
    setIsLongTerm(!member.endDate);
    return member;
  }, [selectedEmployee]);

  if (!member) {
    console.error('No member found');
    return <div>Error: No member found</div>;
  }

  const defaultHelper = (member?.helper?.[0]?.isActive && member.helper[0]) || undefined;

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { isDirty },
  } = useForm<EditMemberFormType>({
    defaultValues: {
      ...(defaultHelper
        ? {
            helper: {
              helperReference: {
                value: defaultHelper?.helperReference?._id || '',
                label: defaultHelper
                  ? `${defaultHelper?.helperReference.user.firstName} ${defaultHelper?.helperReference.user.lastName}`
                  : '',
              },
              dedication: defaultHelper?.dedication?.toString() || '0',
            },
          }
        : {
            helper: {
              helperReference: {
                value: undefined,
                label: 'Sin ayudante',
              },
              dedication: undefined,
            },
          }),
      memberDedication: member.memberDedication.toString(),
      project: {
        value: member?.project?._id || '',
        label: member.project.projectName || '',
      },
      role: member.role,
      startDate: member.startDate,
      endDate: member.endDate || null,
      seniority: member.seniority,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = await Promise.all([
          api.get(`${ApiRoutes.EMPLOYEE}`),
          api.get(`${ApiRoutes.PROJECTS}`),
        ]);

        setEmployeeList(responses[0].data.data);
        setProjectsList(responses[1].data.data);
      } catch (error) {
        // TODO: handle error in toast
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const onSubmit = (data: EditMemberFormType) => {
    // Editing local memberList from useContextForm.
    const contextMemberList = getContextValues('projects.membersList');
    const originalMember = contextMemberList.find((m) => m._id === member._id);

    const originalMemberIndex = contextMemberList.findIndex((m) => m._id === member._id);

    const editedMember = {
      ...originalMember,
      employee: originalMember.employee,
      active: originalMember.active,
      role: data.role as ProjectRoleType,
      ...(!!endDate && { endDate: data.endDate }),
      startDate: data.startDate,
      seniority: data.seniority,
      memberDedication: +data.memberDedication,
      ...(helpers.selectedHelperEmployee && data.helper
        ? {
            helper: [
              {
                helperReference: {
                  _id: helpers.selectedHelperEmployee._id,
                  user: {
                    _id: helpers.selectedHelperEmployee.user._id,
                    firstName: helpers.selectedHelperEmployee.user.firstName,
                    lastName: helpers.selectedHelperEmployee.user.lastName,
                  },
                },
                dedication: +data.helper.dedication,
                isActive: true,
                _id: '',
              },
            ],
          }
        : { helper: undefined }),
    };

    contextMemberList[originalMemberIndex] = editedMember;
    setContextValue('projects.membersList', contextMemberList);

    // Updating membersToPost and membersToPatch helper arrays.
    const memberToPatch: EditMemberDTO = {
      id: member._id,
      body: {
        startDate: startDate,
        ...(!!endDate && { endDate: endDate }),
        ...(data.helper.helperReference.value
          ? {
              helper: [
                {
                  helperReference: data.helper.helperReference.value,
                  dedication: +data.helper.dedication,
                  isActive: true,
                },
              ],
            }
          : { helper: undefined }),
        role: data.role as ProjectRoleType,
        memberDedication: +data.memberDedication,
        seniority: data.seniority,
      },
    };

    // Checking if memberToPatch is on the creation array.
    const membersOnPostArray = getContextValues('projects.membersToPost');
    const memberOnPostArray = membersOnPostArray.find((m) => m.id === memberToPatch.id);

    if (memberOnPostArray) {
      // edit on membersToPost array
      const memberToPatchIndex = membersOnPostArray.findIndex((m) => m.id === memberToPatch.id);

      membersOnPostArray[memberToPatchIndex] = {
        ...memberOnPostArray,
        ...memberToPatch.body,
      };
      setContextValue('projects.membersToPost', membersOnPostArray, {
        shouldDirty: true,
      });
    } else {
      // edit on membersToPatch array
      const originalMembersToPatch = getContextValues('projects.membersToPatch');
      const foundMember = originalMembersToPatch.find((m) => m.id === memberToPatch.id);

      // Is member already in the array? Are we editing what we already edited?
      if (foundMember) {
        const originalMemberToEditIndex = originalMembersToPatch.findIndex(
          (m) => m.id === foundMember.id,
        );

        originalMembersToPatch[originalMemberToEditIndex] = memberToPatch;
        setContextValue('projects.membersToPatch', originalMembersToPatch);
      } else {
        // Push to edit array since we hadn't made any changes.
        setContextValue('projects.membersToPatch', [...originalMembersToPatch, memberToPatch]);
      }
    }

    dispatch(closeModal());
  };

  const helpers = useMemo(() => {
    const dropdownList = employeeList.reduce((acc, employee) => {
      if (employee._id !== selectedEmployee._id && employee?.user?.isActive) {
        acc.push({
          value: employee._id,
          label: `${employee.user?.firstName} ${employee.user?.lastName}`,
        });
      }
      return acc;
    }, []);

    dropdownList.unshift({ value: undefined, label: 'Sin ayudante' });

    const selectedHelperEmployee = employeeList.find(
      (e) => e._id === getValues('helper.helperReference.value'),
    );

    return {
      dropdownList,
      selectedHelperEmployee,
    };
  }, [loading, employeeList, watch('helper.helperReference.value')]);

  const selectedProject = useMemo(
    () => projectsList.find((p) => p._id === getValues('project').value),
    [projectsList, loading],
  );

  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const selectedHelper = watch('helper');
  const memberDedication = watch('memberDedication');

  if (loading) return <Loader />;

  return (
    <div className={styles.modalContainer}>
      <div className={styles.headerAddMember} data-testid={'header-message'}>
        <p>
          {`Editar proyecto a ${selectedEmployee.user.firstName} ${selectedEmployee.user.lastName}`}
        </p>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.memberForm}>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <div className={styles.inputsContainer}>
              <div className={styles.memberData}>
                <div className={styles.topContainer}>
                  <AutocompleteInput
                    name={'project'}
                    control={control}
                    options={[]}
                    defaultValue={member.project.projectName}
                    label={member.project.projectName}
                    disable
                  />
                </div>
                <div className={styles.bottomContainer}>
                  <Dropdown
                    control={control}
                    testId={'roles-dropdown'}
                    label={'Rol'}
                    name="role"
                    options={roles}
                    fullWidth
                  />
                  <TextInput
                    control={control}
                    testId={'member-dedication'}
                    label="Dedicacion"
                    name="memberDedication"
                    type={'number'}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <Dropdown
                  control={control}
                  testId={'seniorityDropdown'}
                  label={'Seniority'}
                  name="seniority"
                  options={seniorityOptions}
                  fullWidth
                />
              </div>
              <div className={styles.helperData}>
                <div className={styles.topContainer}>
                  <AutocompleteInput
                    control={control}
                    label={'Ayudante'}
                    name="helper.helperReference"
                    options={helpers.dropdownList}
                  />
                </div>
                <div className={styles.bottomContainer}>
                  <div className={styles.helperDedication}>
                    <TextInput
                      control={control}
                      testId={'helper-dedication'}
                      label="Dedicacion"
                      name="helper.dedication"
                      type={'number'}
                      variant="outlined"
                      fullWidth
                      disabled={!selectedHelper?.helperReference?.value}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.datePickers}>
              <div>
                <DatePicker
                  label={'Inicio'}
                  testId={'start-date'}
                  name="startDate"
                  control={control}
                  popperProps={{
                    placement: 'right',
                  }}
                  minDate={selectedProject?.startDate || null}
                  maxDate={selectedProject?.endDate || null}
                  disabled={!selectedProject}
                />
              </div>
              <div>
                <DatePicker
                  label={'Fin'}
                  testId={'end-date'}
                  name="endDate"
                  popperProps={{
                    placement: 'right',
                  }}
                  minDate={startDate || null}
                  maxDate={selectedProject?.endDate}
                  disabled={!startDate || isLongTerm}
                  control={control}
                />
              </div>
            </div>
            <FormControlLabel
              sx={{
                alignSelf: 'flex-start',
              }}
              control={
                <Checkbox
                  checked={isLongTerm}
                  onChange={(e, checked) => {
                    setIsLongTerm(checked);
                    if (checked) {
                      setValue('endDate', null);
                    }
                  }}
                  disabled={!startDate}
                />
              }
              label="Long term"
            />
            <div className={styles.buttonsContainer}>
              <div>
                <Button
                  testId="cancel-button"
                  materialVariant={Variant.OUTLINED}
                  onClick={() => dispatch(closeModal())}
                  label="Cancelar"
                />
              </div>
              <div>
                <Button
                  testId="confirm-button"
                  materialVariant={Variant.CONTAINED}
                  onClick={handleSubmit(onSubmit)}
                  label="Confirmar"
                  disabled={
                    (!endDate && !isLongTerm) ||
                    !memberDedication ||
                    !isDirty ||
                    (Boolean(selectedHelper?.helperReference?.value) &&
                      Boolean(!selectedHelper?.dedication))
                  }
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditMemberForm;
