import styles from './loader.module.css';
import { LoaderProps } from './types';
import React from 'react';

const Loader = (props: LoaderProps) => {
  return (
    <div className={props.hasBackground ? styles.modalOverlay : styles.noBackground}>
      <div className={styles.loader}></div>
    </div>
  );
};
export default Loader;
