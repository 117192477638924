import { SalaryRangeType } from '../salaryRanges/types';
import styles from './salaryRangesHistory.module.css';
import { minMaxLevelsByRole } from './types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TableHead,
  Box,
  Alert,
} from '@mui/material';
import { getMonth, getYear } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import Chart from 'react-google-charts';
import { FaAngleDown } from 'react-icons/fa6';
import api from 'src/config/api';
import { roles, years } from 'src/constants';
import { RadiumRoleType } from 'src/types';
import { calculateRanksBasedOnRole } from 'src/utils/calculateRanksBasedOnRole';
import { getMonthName } from 'src/utils/formatters';

//TODO: Refactor to use the new roles, ranks and area of expertise.
const SalaryRangesHistory = () => {
  const [salaryData, setSalaryData] = useState([] as SalaryRangeType[]);
  const [selectedRole, setSelectedRole] = useState<RadiumRoleType>(null);
  const [selectedLevel, setSelectedLevel] = useState<number>(0);
  const [selectedYear, setSelectedYear] = useState<number>(0);

  useEffect(() => {
    api.get<{ data: SalaryRangeType[] }>('/salaryRange').then((res) => {
      setSalaryData(res.data.data);
    });
  }, []);

  const salaryRangesFiltered = salaryData
    .filter(
      (item) =>
        !item.isActive &&
        item.role === selectedRole &&
        getYear(new Date(item.createdAt)) === selectedYear &&
        (item.level === selectedLevel ||
          item.level === selectedLevel - 1 ||
          item.level === selectedLevel + 1),
    )
    .sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateA > dateB ? 1 : -1;
    });

  const salaryRangesByMonth = useMemo(() => {
    if (salaryData) {
      const res = salaryRangesFiltered.reduce(
        (acc, curr) => (
          (acc[getMonthName(getMonth(new Date(curr.createdAt)) + 1)] =
            acc[getMonthName(getMonth(new Date(curr.createdAt)) + 1)] || []).push(curr),
          acc
        ),
        {},
      );
      return res;
    }
    return {};
  }, [salaryRangesFiltered]);

  const salaryRangesTableData = useMemo(() => {
    if (selectedRole === null || !selectedLevel || !selectedYear) return [];

    const salaryRangesFiltered = salaryData.filter((range) => {
      const createdAtYear = getYear(new Date(range.createdAt));
      return (
        range.isActive === false &&
        range.role === selectedRole &&
        range.level === selectedLevel &&
        createdAtYear === selectedYear
      );
    });

    salaryRangesFiltered.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateA > dateB ? 1 : -1;
    });

    return salaryRangesFiltered;
  }, [selectedRole, selectedLevel, selectedYear]);

  const levels: number[] = useMemo(() => calculateRanksBasedOnRole(selectedRole), [selectedRole]);

  type ChartDataType = {
    averageValue: number;
    min: number;
    max: number;
    level: number;
  };

  const chartData = useMemo(() => {
    if (selectedRole === null || !selectedLevel || !selectedYear) return [];

    return Object.entries(salaryRangesByMonth).map((item: [string, ChartDataType[]]) => [
      item[0],
      ...item[1].flatMap((item) => [
        (item.min + item.max) / 2,
        item.min,
        item.max,
        `Max: $${item.max}\nMin: $${item.min}`,
      ]),
    ]);
  }, [selectedRole, selectedLevel, selectedYear]);

  const updatedStructure = useMemo(() => {
    const dynamicStructure = [
      'Mes',
      `Nivel ${selectedLevel + 1}`,
      { role: 'interval' },
      { role: 'interval' },
      { role: 'tooltip' },
      `Nivel ${selectedLevel}`,
      { role: 'interval' },
      { role: 'interval' },
      { role: 'tooltip' },
    ];

    const foundItem = minMaxLevelsByRole.find((item) => {
      if (selectedLevel === item.min) {
        (dynamicStructure[1] = `Nivel ${selectedLevel + 1}`),
          (dynamicStructure[5] = `Nivel ${selectedLevel}`);
      } else if (selectedLevel === item.max && selectedRole === item.role) {
        (dynamicStructure[1] = `Nivel ${selectedLevel}`),
          (dynamicStructure[5] = `Nivel ${selectedLevel - 1}`);
      }
      return (
        selectedRole === item.role && (selectedLevel === item.min || selectedLevel === item.max)
      );
    });

    if (foundItem) {
      return dynamicStructure;
    } else {
      return [
        ...dynamicStructure,
        `Nivel ${selectedLevel - 1}`,
        { role: 'interval' },
        { role: 'interval' },
        { role: 'tooltip' },
      ];
    }
  }, [selectedRole, selectedLevel]);

  const data = [updatedStructure, ...chartData];

  const options = {
    series: {
      0: { color: '#373867' },
      1: { color: '#E05353' },
      2: { color: '#4F987B' },
    },
    intervals: { style: 'bars' },
    legend: {
      position: 'top',
      alignment: 'center',
    },
    height: 350,
    width: 750,
    hAxis: {
      title: 'Meses',
    },
    vAxis: {
      title: 'Rango ($)',
    },
  };

  return (
    <div className={styles.container}>
      <div className={styles.bottom}>
        <div className={styles.selectors}>
          <div className={styles.roleAndLevelContainer}>
            <FormControl sx={{ width: '40%' }}>
              <InputLabel>Rol</InputLabel>
              <Select
                label="Rol"
                onChange={(e) => setSelectedRole(e.target.value as RadiumRoleType)}
              >
                {roles.map((role, idx) => (
                  <MenuItem key={idx} value={role.value}>
                    {role.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: '40%' }}>
              <InputLabel>Nivel</InputLabel>
              <Select
                label="Nivel"
                onChange={(e) => setSelectedLevel(e.target.value as number)}
                disabled={!selectedRole}
              >
                {levels?.map((level, idx) => (
                  <MenuItem key={idx} value={level}>
                    {level}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <FormControl sx={{ width: '40%' }}>
            <InputLabel>Año</InputLabel>
            <Select label="Año" onChange={(e) => setSelectedYear(e.target.value as number)}>
              {years.map((month, idx) => (
                <MenuItem key={idx} value={month.label}>
                  {month.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedRole !== null && selectedLevel && selectedYear ? (
            salaryRangesTableData.length > 0 ? (
              <div className={styles.accordionStyles}>
                <Paper>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<FaAngleDown color={'#373867'} fontSize={'25px'} />}
                    >
                      <Typography
                        paddingRight={'10px'}
                        variant="h6"
                        fontSize={'17px'}
                        color="#373867"
                        component="div"
                      >
                        Datos de la franja salarial de {selectedRole} Nivel {selectedLevel}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell className={styles.rows}>Mes</TableCell>
                              <TableCell className={styles.rows}>Mínimo</TableCell>
                              <TableCell className={styles.rows}>Máximo</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {salaryRangesTableData.map((item, index) => {
                              const month = getMonth(new Date(item.createdAt)) + 1;

                              return (
                                <TableRow key={index} sx={{ paddingRight: 100, paddingLeft: 50 }}>
                                  <TableCell className={styles.rows}>
                                    {getMonthName(month)}
                                  </TableCell>
                                  <TableCell className={styles.rows}>{item.min}</TableCell>
                                  <TableCell className={styles.rows}>{item.max}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </Paper>
              </div>
            ) : (
              <Alert severity="info">
                No hay datos para esta franja salarial en el periodo seleccionado
              </Alert>
            )
          ) : (
            <Alert severity="info">
              Seleccione los datos correspondientes para ver la franja salarial
            </Alert>
          )}
        </div>
        <div className={styles.chart}>
          {selectedRole === null || !selectedLevel || !selectedYear ? (
            <div className={styles.alert}>
              <Alert severity="info">Seleccione rol, nivel y año para ver el gráfico</Alert>
            </div>
          ) : chartData.length > 0 ? (
            <>
              <Typography align="center" variant="h6">
                Franja salarial para el rol {selectedRole} Nivel {selectedLevel}
              </Typography>
              <Paper>
                <Box>
                  <Chart chartType="LineChart" data={data} options={options} />
                </Box>
              </Paper>
            </>
          ) : (
            <Alert severity="info">No hay datos para este rol en el periodo seleccionado</Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default SalaryRangesHistory;
