import { skillsHeadersEmp } from '../employeeEdit/EmployeeSkillsModal/constants';
import styles from '../skillsModal/modal.module.css';
import { CircularProgress, IconButton } from '@mui/material';
import { Dialog as MaterialModal } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { FaPen, FaPlus, FaTrash, FaCheck, FaXmark } from 'react-icons/fa6';
import { ConfirmationMessage, Loader } from 'src/components/shared/ui';
import {
  useEditSkillMutation,
  useDeleteSkillMutation,
  useCreateSkillMutation,
} from 'src/services/skills/mutations';
import { useSkillsQuery } from 'src/services/skills/queries';

const SkillsModal = () => {
  const query = useSkillsQuery();
  const createSkillMutation = useCreateSkillMutation();
  const deleteSkillMutation = useDeleteSkillMutation();
  const editSkillMutation = useEditSkillMutation();

  const [newSkillName, setNewSkillName] = useState('');
  const [selectedSkill, setSelectedSkill] = useState<{ id: string; name: string }>();
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef(null);

  const handleCreateSkill = () => {
    createSkillMutation.mutate({ name: newSkillName }, { onSettled: () => setNewSkillName('') });
  };

  const handleDeleteSkill = () => {
    deleteSkillMutation.mutate(selectedSkill.id, { onSettled: () => setIsOpen(false) });
  };

  const handleEditSkill = () => {
    editSkillMutation.mutate(
      { body: { name: selectedSkill.name }, id: selectedSkill.id },
      {
        onSettled: () => {
          setSelectedSkill({ id: '', name: '' });
          setIsEditing(false);
          setSelectedRow(null);
          setIsOpen(false);
        },
      },
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (isEditing && selectedSkill.id.length > 0) {
        setIsOpen(true);
      } else {
        handleCreateSkill();
      }
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, createSkillMutation.isLoading]);

  if (isOpen) {
    return (
      <MaterialModal open={isOpen} onClose={() => setIsOpen(false)}>
        <ConfirmationMessage
          description={`Al ${!isEditing ? 'eliminar' : 'editar'} el skill '${
            selectedSkill.name
          }', esta se ${
            !isEditing ? 'eliminará' : 'editará'
          } en todas las asignaciones a empleados. ¿Estás seguro de que deseas continuar?`}
          title={'Alerta'}
          confirmationLabel="Confirmar"
          handleConfirm={isEditing ? handleEditSkill : handleDeleteSkill}
          handleClose={() => setIsOpen(false)}
          submitting={deleteSkillMutation.isLoading || editSkillMutation.isLoading}
        />
      </MaterialModal>
    );
  }

  return (
    <div className={styles.tableContainer}>
      <span className={styles.title}>ABM - Gestor de Skills</span>
      <div className={styles.addContainer}>
        <input
          ref={inputRef}
          type="text"
          className={styles.input}
          placeholder="Agregar nueva skill..."
          value={newSkillName}
          onChange={(e) => setNewSkillName(e.target.value)}
          onKeyDown={handleKeyPress}
          disabled={createSkillMutation.isLoading || isEditing || editSkillMutation.isLoading}
        />
        {createSkillMutation.isLoading ? (
          <CircularProgress size={15} color={'info'} />
        ) : (
          <IconButton onClick={handleCreateSkill} disabled={isEditing}>
            <FaPlus color={isEditing ? '#888888' : '#373867'} size={'15px'} />
          </IconButton>
        )}
      </div>
      <div className={styles.tableDataContainer}>
        {query.isLoading ? (
          <Loader />
        ) : query.data?.length > 0 ? (
          <>
            <table className={styles.table}>
              <thead>
                <tr className={styles.trContainer}>
                  {skillsHeadersEmp?.map((header, index) => (
                    <th className={styles.header} key={index}>
                      {header.header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {query.data.map((skill, index) => {
                  const selected = selectedRow === index;
                  const isLoading = createSkillMutation.isLoading || editSkillMutation.isLoading;
                  return (
                    <tr
                      key={skill._id}
                      className={`${styles.trContainer} ${selected ? styles.rowFocused : ''}`}
                    >
                      <td className={styles.rows}>
                        {isEditing && selected ? (
                          <input
                            ref={inputRef}
                            type="text"
                            className={styles.rowEdit}
                            value={selectedSkill.name}
                            onChange={(e) => {
                              setSelectedSkill({ name: e.target.value, id: skill._id });
                            }}
                            onKeyDown={handleKeyPress}
                            disabled={isLoading}
                          />
                        ) : (
                          skill.name
                        )}
                      </td>
                      <td className={styles.rows}>
                        {skill.isValidated ? 'Validado' : 'Pendiente'}
                      </td>
                      <td className={styles.rows}>
                        {!selected ? (
                          <div className={styles.iconButton}>
                            <IconButton
                              onClick={() => {
                                setIsOpen(true);
                                setSelectedSkill({ id: skill._id, name: skill.name });
                              }}
                              disabled={isLoading}
                            >
                              <FaTrash
                                className={styles.editButton}
                                color={'#373867'}
                                size={'15px'}
                              />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setIsEditing(true);
                                setSelectedRow(index);
                                setSelectedSkill({ id: skill._id, name: skill.name });
                              }}
                              disabled={isLoading}
                            >
                              <FaPen
                                className={styles.editButton}
                                color={'#373867'}
                                size={'15px'}
                              />
                            </IconButton>
                          </div>
                        ) : (
                          <div className={styles.iconButton}>
                            <IconButton
                              onClick={() => {
                                setIsEditing(false);
                                setSelectedRow(null);
                                setSelectedSkill({ id: '', name: '' });
                              }}
                              disabled={isLoading}
                            >
                              <FaXmark
                                className={styles.editButton}
                                color={'#373867'}
                                size={'15px'}
                              />
                            </IconButton>
                            <IconButton onClick={() => setIsOpen(true)} disabled={isLoading}>
                              <FaCheck
                                className={styles.editButton}
                                color={'#373867'}
                                size={'15px'}
                              />
                            </IconButton>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <div>
            <div className={styles.noContent}>Esta tabla de datos se encuentra vacía</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SkillsModal;
