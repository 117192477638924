export type SalaryRangeType = {
  _id?: string;
  level: number;
  min: number;
  max: number;
  createdAt?: string;
  role: string;
  isActive?: boolean;
};

export type FormValues = {
  adjustment: number;
};

export enum ReqStates {
  LOADING = 'Loading',
  DONE = 'Done',
  NONE = 'None',
}
