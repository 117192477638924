export enum Actions {
  SET_LOADER_ON = 'SET_LOADER_ON',
  SET_LOADER_OFF = 'SET_LOADER_OFF',
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  OPEN_FORM_MODAL = 'OPEN_FORM_MODAL',
  CLOSE_FORM_MODAL = 'CLOSE_FORM_MODAL',
  OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL',
  CLOSE_CONFIRMATION_MODAL = 'CLOSE_CONFIRMATION_MODAL',
  OPEN_LOGOUT_MODAL = 'OPEN_LOGOUT_MODAL',
  CLOSE_LOGOUT_MODAL = 'CLOSE_LOGOUT_MODAL',
  OPEN_PROJECTS_MODAL = 'OPEN_PROJECTS_MODAL',
  CLOSE_PROJECTS_MODAL = 'CLOSE_PROJECTS_MODAL',
  OPEN_SIDEBAR = 'OPEN_SIDEBAR',
  CLOSE_SIDEBAR = 'CLOSE_SIDEBAR',
  OPEN_API_KEY_MODAL = 'OPEN_API_KEY_MODAL',
  CLOSE_API_KEY_MODAL = 'CLOSE_API_KEY_MODAL',
  OPEN_HOURS_MODAL = 'OPEN_HOURS_MODAL',
  CLOSE_HOURS_MODAL = 'CLOSE_HOURS_MODAL',
  SET_CUSTOMLOADER_ON = 'SET_CUSTOMLOADER_ON',
  SET_CUSTOMLOADER_OFF = 'SET_CUSTOMLOADER_OFF',
}
