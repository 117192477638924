import { setLoaderOff, setLoaderOn } from '../ui/actions';
import { getMovementsError, getMovementsPending, getMovementsSuccess } from './actions';
import { Dispatch } from 'redux';
import { getResourceRequest } from 'src/config/api';
import { ApiRoutes } from 'src/constants';
import { AppThunk } from 'src/redux/types';

export const getMovements: AppThunk = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getMovementsPending());
    dispatch(setLoaderOn());
    try {
      const response = await getResourceRequest(ApiRoutes.MOVEMENTS);
      if (response?.data) {
        dispatch(getMovementsSuccess(response.data));
        dispatch(setLoaderOff());
      }
    } catch (error) {
      dispatch(getMovementsError({ message: error.message, errorType: error.errorType }));
      dispatch(setLoaderOff());
    }
  };
};
