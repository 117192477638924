import styles from './searchbar.module.css';
import { SearchBarProps, SearchData } from './types';
import React, { useEffect, useState } from 'react';
import { FaSistrix } from 'react-icons/fa6';

const SearchBar = <T extends SearchData>(props: SearchBarProps<T>): JSX.Element => {
  const { setFilter, filter, keywords } = props;
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (filter === '') {
      setInputValue('');
    }
  }, [filter]);

  const handleSubmit = (e, value) => {
    e.preventDefault();
    setFilter(value);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setFilter(!value.length ? ' ' : value.trim());
  };

  return (
    <div>
      <form
        onSubmit={(e) => {
          handleSubmit(e, inputValue);
        }}
      >
        <div className={styles.searchInputContainer}>
          <div className={styles.iconContainer}>
            <FaSistrix color={'#767676'} fontSize={'20px'} />
          </div>
          <input
            data-testid="searchbar-input"
            className={styles.searchInput}
            placeholder={keywords ? `Búsqueda por ${keywords}` : 'Búsqueda por palabra clave'}
            onChange={handleChange}
            value={inputValue}
          />
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
