import { EmployeeData } from '../../types';
import ComparisonCard from './ComparisonCard';
import styles from './comparisonModal.module.css';
import { EmployeeComparisonModalFilters, ComparisonModalFilteredEmployee } from './types';
import mapEmployees from './utils/mapEmployees';
import React, { useMemo, useState } from 'react';
import { Button, SearchBar } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import { dropdownData } from 'src/constants';
import { closeModal } from 'src/redux/modals/modalsSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { AvailabilityType, RadiumRoleType, RankType } from 'src/types';
import { calculateRanksBasedOnRole } from 'src/utils/calculateRanksBasedOnRole';

const ComparisonModal = ({ employeeId }: { employeeId: string }) => {
  const dispatch = useAppDispatch();
  const reduxFullEmployeeList = useAppSelector((state) => state.employee?.list);
  const [filters, setFilters] = useState<EmployeeComparisonModalFilters>({
    role: null,
    rank: 0,
    search: '',
    availability: AvailabilityType.Available,
  });
  const [selectedEmployeeId, setSelectedEmployeeId] = useState('');

  const levels: RankType[] = useMemo(() => calculateRanksBasedOnRole(filters.role), [filters.role]);

  const filteredEmployees: ComparisonModalFilteredEmployee[] = useMemo(
    () => mapEmployees(reduxFullEmployeeList, filters, employeeId),
    [reduxFullEmployeeList, filters],
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <p className={styles.title}>Comparar empleados</p>
          <p className={styles.subtitle}>Seleccione el empleado a comparar</p>
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.filtersAndList}>
          <div className={styles.searchbar}>
            <SearchBar<EmployeeData>
              setFilter={(stringValue) => setFilters({ ...filters, search: stringValue })}
              filter={filters.search}
              keywords="Nombre"
            />
          </div>
          <div className={styles.filters}>
            <select
              className={styles.filterDropdown}
              onChange={(e) => {
                setFilters({ ...filters, role: e.target.value as RadiumRoleType });
              }}
            >
              <option value={''} selected={filters.role === null} className={styles.option}>
                {'Rol'}
              </option>
              {dropdownData?.map((item) => (
                <option key={item.value} value={item.value} className={styles.option}>
                  {item.label}
                </option>
              ))}
            </select>

            <select
              disabled={!filters.role}
              className={styles.filterDropdown}
              onChange={(e) => {
                setFilters({ ...filters, rank: +e.target.value as RankType });
              }}
            >
              <option value={''} selected={filters.rank === null} className={styles.option}>
                {'Level'}
              </option>
              {levels?.map((item) => (
                <option key={item} value={item} className={styles.option}>
                  {item}
                </option>
              ))}
            </select>

            <div className={styles.filterButtons}>
              <Button
                materialVariant={
                  filters.availability === AvailabilityType.Available
                    ? Variant.TEXT
                    : Variant.CONTAINED
                }
                onClick={() => {
                  setFilters({
                    ...filters,
                    availability:
                      filters.availability === AvailabilityType.Available
                        ? AvailabilityType.NotAvailable
                        : AvailabilityType.Available,
                  });
                }}
                label={'Disponibles'}
                testId={'reset-filter'}
              />
            </div>

            <div className={styles.filterButtons}>
              <Button
                materialVariant={Variant.TEXT}
                onClick={() => {
                  setFilters({
                    ...filters,
                    role: null,
                    search: '',
                    rank: 0,
                    availability: AvailabilityType.Available,
                  });
                }}
                label={'Reiniciar filtros'}
                testId={'comparison-modal-reset-filter'}
              />
            </div>
          </div>
          <div className={styles.listContainer}>
            {filteredEmployees.length ? (
              <table>
                <thead>
                  <tr>
                    <th>Empleado</th>
                    <th>Rol</th>
                    <th>Level</th>
                    <th>Skills</th>
                    <th>Disponibilidad</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredEmployees.map((employee) => {
                    return (
                      <tr key={employee._id} onClick={() => setSelectedEmployeeId(employee._id)}>
                        <td>{employee.name}</td>
                        <td>{employee.role}</td>
                        <td>{employee.level}</td>
                        <td>{employee.skills}</td>
                        <td>{employee.availability}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <>
                <div>No se encontraron resultados.</div>
              </>
            )}
          </div>
          <div>
            <Button
              label="Volver"
              materialVariant={Variant.CONTAINED}
              onClick={() => dispatch(closeModal())}
            />
          </div>
        </div>
        <ComparisonCard id={employeeId} />
        {selectedEmployeeId && <ComparisonCard id={selectedEmployeeId} shadowColor="blue" />}
      </div>
    </div>
  );
};

export default ComparisonModal;
