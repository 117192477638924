import { DropdownProps } from './types';
import { MenuItem, TextField } from '@mui/material';
import React from 'react';
import { FieldValues, useController } from 'react-hook-form';

const Dropdown = <Form extends FieldValues>(props: DropdownProps<Form>): JSX.Element => {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  const { label, options, styles, testId, disabled, customChangeHandler } = props;

  return (
    <TextField
      {...field}
      data-testid={testId}
      className={styles}
      label={label}
      helperText={error?.message || ' '}
      select
      fullWidth
      color="info"
      error={Boolean(error)}
      disabled={disabled}
      onChange={(e) => {
        field.onChange(e.target.value);
        if (customChangeHandler) {
          customChangeHandler(e);
        }
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value} data-testid="performance-option">
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Dropdown;
