import { Helper } from '../projects/projectMembersLayout/memberForm/types';
import { Criticality, ProjectType } from '../projects/types';
import { Client } from 'src/redux/client/types';
import { Employee } from 'src/redux/employee/types';
import { NotificationType, NotificationTypes, ProjectRoleType } from 'src/types';

// TODO: Remove this type, get type from base type
export interface Member {
  _id?: string;
  employee: Employee;
  role?: ProjectRoleType;
  memberDedication?: number;
  helper?: Helper;
  active?: boolean;
}

export enum Order {
  RECENT = 'RECENT',
  OLDEST = 'OLDEST',
}

export interface Project {
  isCritic: Criticality;
  _id?: string;
  clientName: Client;
  projectName: string;
  description: string;
  notes: string;
  startDate: Date;
  endDate: Date;
  members: Member[];
  isUpdated: boolean;
  projectType: ProjectType;
  isActive: boolean;
}

export interface HomeDateIntervalsValue {
  startDate: string;
  endDate: string;
  notificationType: NotificationType[];
}

export interface HomeFilterType {
  newest: boolean;
  period: boolean;
  role: string;
  search: string;
  type: string;
  notificationType: NotificationTypes[];
}
