import { EmployeeComparisonModalFilters } from '../types';
import filterData from './filterData';
import { Employee } from 'src/redux/employee/types';
import { AvailabilityType } from 'src/types';
import { formatSkills } from 'src/utils/formatSkills';

const mapEmployees = (
  employeeList: Employee[],
  filters: EmployeeComparisonModalFilters,
  idToCompare: string,
) => {
  // Remove the employee on the left (blue) card from the list.
  const employees = employeeList.filter((e) => e._id !== idToCompare);

  const mappedEmployees = employees.map((item) => {
    const skillsString = formatSkills(item.skills, ', ', 1);

    return {
      _id: item._id,
      active: item.user.isActive,
      name: `${item.user.firstName} ${item.user.lastName}`,
      role: item.role,
      level: item.rank.toString(),
      skills: skillsString,
      availability:
        item.availability.description === AvailabilityType.Available
          ? AvailabilityType.Available
          : AvailabilityType.NotAvailable,
    };
  });

  const filteredData = filterData(mappedEmployees, filters);

  return filteredData;
};

export default mapEmployees;
