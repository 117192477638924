import { LogoProps } from './types';
import React from 'react';

const LoginLogo = (props: LogoProps): JSX.Element => {
  const { testId } = props;
  return (
    <div data-testid={testId}>
      <img src={`${process.env.PUBLIC_URL}/assets/images/login-logo.svg`} alt="radium logo" />
    </div>
  );
};

export default LoginLogo;
