interface Data {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
/**
 * Filters the data based on the provided filters and search string.
 * @param dataToFilter The list of data to filter.
 * @param listFilter The list of fields to filter by.
 * @param search The search string entered by the user.
 * @returns An array containing the filtered data.
 */
export const getFilteredDataByTextSearch = (
  dataToFilter: Data[],
  listFilter: string[],
  search: string,
): Data[] => {
  return dataToFilter.filter((data) =>
    listFilter.some((field) => data[field]?.toLowerCase().includes(search.toLowerCase())),
  );
};
