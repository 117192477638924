import { RankType } from 'src/types';

export const generateIntArrayWithRange = (a: number, b: number): RankType[] => {
  if (a > b) {
    return [];
  }

  const result = [];
  for (let i = a; i <= b; i++) {
    result.push(i);
  }

  return result;
};
