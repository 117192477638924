import { headerMemberTable } from './constants';
import styles from './memberTable.module.css';
import EmptyMemberMessage from './noMembers';
import { MemberTableProps } from './types';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { FaPen, FaTrash, FaCircleUser } from 'react-icons/fa6';
import { useDispatch } from 'react-redux';
import { Button, ConfirmationMessage, Modal } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import { deleteMember } from 'src/redux/member/thunk';
import { getProjectById } from 'src/redux/project/thunk';
import { openModal } from 'src/redux/ui/actions';
import { AppDispatch } from 'src/types';
import { dateFormatter } from 'src/utils/formatters';

const MemberTable = (props: MemberTableProps) => {
  const dispatch: AppDispatch<null> = useDispatch();

  const { list, setMemberId, projectId } = props;

  const filteredList = list.filter((item) => item?.active);

  const [newListData, setNewListData] = useState(
    {} as {
      id?: string;
      employee: string;
      role?: string;
      dedication?: string | number;
      helper?: string;
      date?: string;
    },
  );

  const newList = filteredList.map((item) => {
    const currentHelper = item.helper?.find((helper) => helper.isActive);
    return {
      id: item._id,
      employee: `${item?.employee?.user?.firstName} ${item?.employee?.user?.lastName}` || '-',
      role: item?.role || '-',
      dedication: item?.memberDedication || '-',
      helper: currentHelper?.helperReference
        ? `${currentHelper.helperReference?.user?.firstName} ${currentHelper.helperReference?.user?.lastName}`
        : '-',
      date: dateFormatter(item?.startDate, item?.endDate),
      seniority: item.seniority,
    };
  });

  const [deleteModalDisplay, setDeleteModalDisplay] = useState(false);

  const handleDelete = async (data) => {
    await dispatch(deleteMember(data.id));
    dispatch(getProjectById(projectId));
  };

  const handleEdit = (id) => {
    setMemberId(id);
    dispatch(openModal());
  };

  const handleAdd = () => {
    setMemberId('');
    dispatch(openModal());
  };

  return list?.length ? (
    <>
      <div className={styles.tableContainer}>
        <div className={styles.addMembers}>
          Agregar miembros
          <Button
            testId="addMember"
            materialVariant={Variant.CONTAINED}
            onClick={() => handleAdd()}
            label="+ Agregar miembro"
          />
        </div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.header}></th>
              {headerMemberTable?.map((header, index) => {
                return (
                  <th className={styles.header} key={index}>
                    {header.header}
                  </th>
                );
              })}
              <th className={styles.header}></th>
            </tr>
          </thead>
          <tbody>
            {newList?.map((data) => {
              return (
                <tr key={data.id}>
                  <td className={`${styles.rows}`}>
                    <FaCircleUser fontSize={'40px'} color={'#C7C7C7'} />
                  </td>
                  {headerMemberTable.map((header, index) => {
                    return (
                      <td className={styles.rows} key={index}>
                        {data[header.key]}
                      </td>
                    );
                  })}
                  <td className={`${styles.buttons} ${styles.rows}`}>
                    <div>
                      <IconButton onClick={() => handleEdit(data.id)}>
                        <FaPen color={'#373867'} fontSize={'20px'} />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setNewListData(data);
                          setDeleteModalDisplay(true);
                        }}
                      >
                        <FaTrash color={'#373867'} fontSize={'20px'} />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal
        testId="deleteModal"
        styles={styles.modal}
        isOpen={deleteModalDisplay}
        onClose={() => setDeleteModalDisplay(false)}
      >
        <ConfirmationMessage
          description={`¿Desea borrar al miembro ${newListData.employee}?`}
          title={'Borrar miembro'}
          handleConfirm={() => handleDelete(newListData)}
          handleClose={() => setDeleteModalDisplay(false)}
        />
      </Modal>
    </>
  ) : (
    <EmptyMemberMessage projectId={projectId} />
  );
};
export default MemberTable;
