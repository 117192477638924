import { RootState } from './store';
import { Action, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { UserData } from 'src/components/pages/users/types';

export type AppThunk = ActionCreator<ThunkAction<void, RootState, null, Action<null>>>;

export interface ErrorFormat {
  message: string;
  errorType: ErrorType;
}

export enum ErrorType {
  CLIENT_ERROR = 'CLIENT_ERROR',
  NETWORK_ERROR = 'ERR_NETWORK',
  AUTH_ERROR = 'AUTH_ERROR',
  READER_ERROR = 'READER_ERROR',
}
export interface State<T> {
  [x: string]: unknown;
  list: T[];
  isLoading: boolean;
  userData?: UserData;
  error: ErrorFormat;
  isSuccess?: boolean;
}

export enum SeniorityType {
  TRAINEE = 'TRAINEE',
  JUNIOR = 'JUNIOR',
  SEMI_SENIOR = 'SEMI_SENIOR',
  SENIOR = 'SENIOR',
}
