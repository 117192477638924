import { RadiumRoleType } from 'src/types';

export const minMaxLevelsByRole = [
  { role: RadiumRoleType.TRAINEE, min: 0, max: 0 },
  { role: RadiumRoleType.EXPLORER, min: 1, max: 3 },
  { role: RadiumRoleType.CREW_MEMBER, min: 1, max: 3 },
  { role: RadiumRoleType.PILOT, min: 1, max: 2 },
  { role: RadiumRoleType.LIEUTENANT, min: 1, max: 2 },
  { role: RadiumRoleType.COMMANDER, min: 1, max: 3 },
  { role: RadiumRoleType.CAPTAIN, min: 1, max: 5 },
];
