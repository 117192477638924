import styles from './login.module.css';
import React, { useEffect } from 'react';
import GoogleButton from 'react-google-button';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoginLogo from 'src/components/shared/ui/icons/loginLogo';
import { setAuthError } from 'src/redux/auth/actions';
import { login } from 'src/redux/auth/thunk';
import { AppDispatch } from 'src/types';

const Login = () => {
  const dispatch: AppDispatch<null> = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setAuthError(false));
  }, []);

  const handleGoogleSignIn = async () => {
    const accessTypes = await dispatch(login());

    if (Array.isArray(accessTypes)) {
      if (accessTypes.includes('ADMIN_AM')) {
        navigate('/admin/home');
      } else if (accessTypes.includes('SUPER_ADMIN')) {
        navigate('/super-admin');
      } else if (accessTypes.includes('EMPLOYEE')) {
        navigate('/employee');
      } else if (
        !accessTypes.includes('ADMIN_AM') &&
        !accessTypes.includes('SUPER_ADMIN') &&
        !accessTypes.includes('EMPLOYEE')
      ) {
        navigate('/not-allowed');
      }
    }
  };

  return (
    <div className={styles.container}>
      <LoginLogo testId="radium-logo" />
      <h1>
        <span>Radium</span> Admin
      </h1>
      <GoogleButton
        label="Continuar con Google"
        className={styles.googleButton}
        type="light"
        test-id="google-button"
        onClick={handleGoogleSignIn}
      />
    </div>
  );
};

export default Login;
