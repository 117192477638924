import { CompleteFormType } from '../types';
import EditMemberForm from './EditMemberForm';
import { projectHeadersEmp } from './constants';
import CreateMemberForm from './createMemberForm';
import ProjectList from './projectList';
import { FormattedProjects } from './types';
import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ConfirmationMessage, Modal } from 'src/components/shared/ui';
import AsideTable from 'src/components/shared/ui/asideTable';
import { ModalIDs, closeModal, openModal } from 'src/redux/modals/modalsSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { formatDate } from 'src/utils/formatters';

function Projects() {
  const dispatch = useAppDispatch();
  const activeModal = useAppSelector((state) => state.modals.activeModal);
  const selectedEmployee = useAppSelector((state) => state.employee.selectedEmployee);

  const { getValues } = useFormContext<CompleteFormType>();

  const [memberId, setMemberId] = useState<string>('');
  const [project, setProject] = useState<{ id: string; name: string }>();

  const formattedProjects: FormattedProjects[] = useMemo(
    () =>
      getValues('projects.membersList')
        ?.map((item) => ({
          id: item?.project?._id || '-',
          name: item?.project?.projectName || '-',
          role: item?.role ? item.role : '-',
          seniority: item?.seniority ? item.seniority : '-',
          startDate: formatDate(item?.startDate?.toString()),
          endDate: formatDate(item?.endDate?.toString()),
          hours: item?.memberDedication,
          memberId: item?._id, // Not shown but useful for editting.
        }))
        .reverse(),
    [getValues('projects.membersList')],
  );

  const { setValue } = useFormContext<CompleteFormType>();

  const deactivateMember = (id: string) => {
    setMemberId(id);
    const project = formattedProjects.find((p) => p.memberId === id);
    setProject({ id: project.id, name: project.name });
    dispatch(openModal(ModalIDs.EMPLOYEE_DEACTIVATE_MEMBER));
  };

  const deactivateMemberConfirm = () => {
    // Always remove the item from membersList and membersToPatchList
    const newMembersList = getValues('projects.membersList').filter((m) => m._id !== memberId);
    setValue('projects.membersList', newMembersList);

    const newMembersToPatchList = getValues('projects.membersToPatch').filter(
      (m) => m.id !== memberId,
    );
    setValue('projects.membersToPatch', newMembersToPatchList || []);

    // If on postArray, remove from there and DO NOT add to deleteArray, else add to delete array.
    const memberOnPostArray = getValues('projects.membersToPost').find((m) => m.id === memberId);
    if (memberOnPostArray) {
      const filteredMembersToPost = getValues('projects.membersToPost').filter(
        (m) => m.id !== memberId,
      );
      setValue('projects.membersToPost', filteredMembersToPost);
    } else {
      const deDupedIdsToDelete = new Set([
        ...getValues('projects.membersToDelete'),
        { memberId, projectId: project.id },
      ]);
      setValue('projects.membersToDelete', Array.from(deDupedIdsToDelete));
    }

    dispatch(closeModal());
  };

  return (
    <div>
      <AsideTable
        title="Últimos Proyectos"
        headers={projectHeadersEmp}
        testId="projects-table"
        dataList={formattedProjects?.slice(-2)}
        showHistoryModal={() => dispatch(openModal(ModalIDs.EMPLOYEE_PROJECTS))}
        showFormModal={() => {
          dispatch(openModal(ModalIDs.EMPLOYEE_ADD_MEMBER));
        }}
        showPlusButton
        showViewMore={formattedProjects.length > 2}
        isProjectsTable
        setMemberId={setMemberId}
        deactivateMember={deactivateMember}
      />
      {activeModal && (
        <>
          <Modal testId="project-modal" id={ModalIDs.EMPLOYEE_PROJECTS}>
            <ProjectList
              setProject={setProject}
              formattedProjects={formattedProjects}
              setMemberId={setMemberId}
            />
          </Modal>
          <Modal testId={'user-access-modal'} id={ModalIDs.EMPLOYEE_ADD_MEMBER}>
            <CreateMemberForm />
          </Modal>
          <Modal testId={'user-access-modal'} id={ModalIDs.EMPLOYEE_EDIT_MEMBER}>
            <EditMemberForm selectedMemberId={memberId} />
          </Modal>
          <Modal testId={'user-access-modal'} id={ModalIDs.EMPLOYEE_DEACTIVATE_MEMBER}>
            <ConfirmationMessage
              title="Desactivar miembro"
              description={`¿Está seguro que desea desactivar al miembro ${selectedEmployee.user.firstName} ${selectedEmployee.user.lastName} del projecto ${project?.name}? Deberá guardar cambios para que surta efecto.`}
              handleClose={() => dispatch(closeModal())}
              handleConfirm={() => deactivateMemberConfirm()}
            />
          </Modal>
        </>
      )}
    </div>
  );
}

export default Projects;
