import CV from './cv';
import { CVData } from './cv/types';
import GrowthPlanForm from './growthPlanForm';
import styles from './header.module.css';
import SalaryChartModal from './salaryChart';
import { IconButton, Tooltip } from '@mui/material';
import { Buffer } from 'buffer';
import jsPDF from 'jspdf';
import React from 'react';
import { renderToString } from 'react-dom/server';
import { FaBell, FaCalendarDays } from 'react-icons/fa6';
import { useNavigate, useParams } from 'react-router-dom';
import CustomNotifications from 'src/components/shared/common/customNotificationForm';
import { Resource } from 'src/components/shared/common/customNotificationForm/types';
import { Button, Modal } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import { getResourceRequest } from 'src/config/api';
import { ApiRoutes, IMAGE_URL, UiRoutes } from 'src/constants';
import { ModalIDs, openModal } from 'src/redux/modals/modalsSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';

function Header() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedEmployee = useAppSelector((state) => state.employee.selectedEmployee);

  const generatePDF = async () => {
    const data = await getResourceRequest<CVData>(`${ApiRoutes.EMPLOYEE}/${params.id}/cv`);
    const doc = new jsPDF({
      orientation: 'p',
    });
    const imgFetch = await fetch(
      `${IMAGE_URL}/${data.data[0]?.userId}.${data.data[0]?.photoType}?${new Date().getTime()}`,
    );
    const buffer = await imgFetch.arrayBuffer();
    const contentType = imgFetch.headers.get('Content-type');
    const base64 = `data:${contentType};base64,${Buffer.from(buffer).toString('base64')}`;
    doc.html(renderToString(<CV imgSource={base64} data={data.data[0]} />), {
      callback: (doc) => {
        doc.save(`${selectedEmployee.user?.firstName}-${selectedEmployee.user?.lastName}-cv.pdf`);
      },
      x: 15,
      y: 10,
      width: 170,
      windowWidth: 634,
    });
  };

  return (
    <>
      <div className={styles.container}>
        <div>
          Editar a {selectedEmployee?.user?.firstName} {selectedEmployee?.user?.lastName}
        </div>
        <div className={styles.options}>
          {/* <Button
            testId="growth-plan-button"
            materialVariant={Variant.CONTAINED}
            onClick={() => dispatch(openModal(ModalIDs.EMPLOYEE_GROWTH_PLAN_FORM))}
            label="Plan de crecimiento"
            color="info"
          /> */}
          {/* TODO: Add loader for CV dowload */}
          <Button
            testId="cv-button"
            materialVariant={Variant.CONTAINED}
            onClick={() => generatePDF()}
            label="Descargar CV"
            color="info"
          />
          {/* <Button
            testId="salary-chart-button"
            materialVariant={Variant.CONTAINED}
            onClick={() => dispatch(openModal(ModalIDs.EMPLOYEE_SALARY_CHART))}
            label="Ver progreso salarial"
            color="info"
          /> */}
          <Tooltip placement={'bottom-end'} arrow title="Ver disponibilidad">
            <IconButton
              onClick={() => {
                navigate(
                  `${UiRoutes.ADMIN_AM}${UiRoutes.EMPLOYEES}${UiRoutes.AVAILABILITY}/${params.id}`,
                );
              }}
            >
              <FaCalendarDays color={'#373867'} fontSize={'25px'} cursor={'pointer'} />
            </IconButton>
          </Tooltip>
          <Tooltip placement={'bottom-end'} arrow title="Agregar recordatorio">
            <IconButton onClick={() => dispatch(openModal(ModalIDs.EMPLOYEE_CUSTOM_NOTIFICATION))}>
              <FaBell color={'#373867'} fontSize={'25px'} cursor={'pointer'} />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <Modal testId={'employee-custom-notification'} id={ModalIDs.EMPLOYEE_CUSTOM_NOTIFICATION}>
        <CustomNotifications resource={Resource.EMPLOYEE} id={params.id} />
      </Modal>

      <Modal testId={'Growth-plan-form-modal'} id={ModalIDs.EMPLOYEE_GROWTH_PLAN_FORM}>
        <GrowthPlanForm />
      </Modal>
      <Modal
        styles="noScroll"
        testId={'employee-salary-chart-modal'}
        id={ModalIDs.EMPLOYEE_SALARY_CHART}
      >
        <SalaryChartModal />
      </Modal>
    </>
  );
}

export default React.memo(Header);
