import styles from './salaryRanges.module.css';
import EditSalaryRangesForm from './salaryRangesForm';
import { FormValues, SalaryRangeType, ReqStates } from './types';
import salaryRangeValidation from './validations';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Chart } from 'react-google-charts';
import { useForm } from 'react-hook-form';
import { FaAngleDown } from 'react-icons/fa6';
import { Button, TextInput, ConfirmationMessage, Modal } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import ClockLoader from 'src/components/shared/ui/clockloader';
import api from 'src/config/api';
import { ApiRoutes, roles } from 'src/constants';
import { ModalIDs, openModal } from 'src/redux/modals/modalsSlice';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toastSlice';
import { closeConfirmationModal, openConfirmationModal } from 'src/redux/ui/actions';
import { AppDispatch } from 'src/types';

const SalaryRanges = () => {
  const dispatch: AppDispatch<null> = useAppDispatch();
  const showConfirmModal = useAppSelector((state: RootState) => state.ui.showConfirmModal);
  const [salaryData, setSalaryData] = useState([] as SalaryRangeType[]);
  const [selected, setSelected] = useState<string>('');

  const employeeList = useAppSelector((state: RootState) => state.employee.list);
  const [showAdjust, setShowAdjust] = useState<boolean>(false);
  const [loadingReq, setLoadingReq] = useState<ReqStates>(ReqStates.DONE);

  const {
    formState: { isDirty, isValid },
    control,
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      adjustment: 0,
    },
    mode: 'onChange',
    resolver: joiResolver(salaryRangeValidation),
  });

  useEffect(() => {
    if (loadingReq !== ReqStates.LOADING)
      api.get<{ data: SalaryRangeType[] }>('/salaryRange').then((res) => {
        const activeRanges = res.data.data.filter((range) => range.isActive);
        setSalaryData(activeRanges);
      });
  }, [loadingReq]);

  const data = useMemo(() => {
    if (salaryData) {
      const res = salaryData.reduce(
        (acc, curr) => ((acc[curr.role] = acc[curr.role] || []).push(curr), acc),
        {},
      );
      return res;
    }
    return {};
  }, [salaryData]);

  const chartData = useMemo(() => {
    if (!data[selected] || selected === '') return [];
    data[selected].sort((a, b) => a.level - b.level);
    return data[selected].map((item) => [
      `${item.level}`,
      item.min,
      'opacity: 0.0',
      item.max - item.min,
      'color: #1a8763',
      `max: $${item.max}\nmin: $${item.min}`,
    ]);
  }, [selected, salaryData]);

  const tableData = useMemo(() => {
    if (selected === '') return {};
    const employeesByRole = employeeList.filter(
      (emp) => emp.role === selected && emp.user.isActive,
    );
    //TODO: Refactor to use the new roles, ranks and area of expertise.
    return employeesByRole.reduce(
      (acc, emp) => {
        acc.total += 1;
        acc.totalsByLvl[emp.rank]
          ? (acc.totalsByLvl[emp.rank] += 1)
          : (acc.totalsByLvl[emp.rank] = 1);
        return acc;
      },
      { total: 0, totalsByLvl: {} },
    );
  }, [selected, salaryData]);

  const submitAdjustment = async () => {
    setLoadingReq(ReqStates.LOADING);
    try {
      await api.patch(`${ApiRoutes.SALARYRANGE}/massiveUpdate`, {
        percentage: +getValues('adjustment'),
      });
      dispatch(openToast({ message: 'Ajuste masivo realizado con exito', error: false }));
    } catch (error) {
      dispatch(openToast({ message: error.message, error: true }));
      setLoadingReq(ReqStates.DONE);
    } finally {
      dispatch(closeConfirmationModal());
      setLoadingReq(ReqStates.DONE);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.bottom}>
        <div className={styles.selectors}>
          <FormControl sx={{ width: '30%' }}>
            <InputLabel>Rol</InputLabel>
            <Select label="Rol" onChange={(e) => setSelected(e.target.value as string)}>
              {roles.map((role, idx) => (
                <MenuItem key={idx} value={role.value}>
                  {role.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={styles.buttonsLeft}>
            <Button
              materialVariant={Variant.CONTAINED}
              label={'Ajuste salarial masivo'}
              color="primary"
              testId={'massive-salary-adjustment'}
              sx={{ padding: '11px 10px', width: 'fit-content' }}
              onClick={() => setShowAdjust(!showAdjust)}
            />
            {showAdjust && (
              <div className={styles.adjustmentContainer}>
                <div className={styles.adjustInput}>
                  <TextInput
                    control={control}
                    testId={'massive-adjust-input'}
                    label="Incremento %"
                    name="adjustment"
                    stylesObject={{ width: '156px' }}
                    type={'number'}
                    variant="outlined"
                    fullWidth
                  />
                  {loadingReq === ReqStates.LOADING && <ClockLoader />}
                </div>
                <Button
                  materialVariant={Variant.OUTLINED}
                  label={'Aceptar'}
                  color="primary"
                  disabled={!(isValid && isDirty)}
                  sx={{ width: 'fit-content', padding: '11px 10px' }}
                  testId={'confirm-adjustment'}
                  onClick={() => dispatch(openConfirmationModal())}
                />
              </div>
            )}
          </div>
          <div>
            {selected !== '' ? (
              tableData['totalsByLvl'] ? (
                <Paper>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<FaAngleDown color={'#373867'} fontSize={'25px'} />}
                    >
                      <Typography
                        paddingRight={'10px'}
                        variant="h6"
                        fontSize={'17px'}
                        color="#373867"
                        component="div"
                      >
                        Empleados con el rol de {selected}: {tableData['total']}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableBody>
                            {Object.entries(tableData['totalsByLvl']).map((item, index) => (
                              <TableRow key={index} sx={{ paddingRight: 100, paddingLeft: 50 }}>
                                <TableCell className={styles.rows} key={index}>
                                  Nivel {item[0]}
                                </TableCell>
                                <TableCell className={styles.rows} key={index}>
                                  {item[1] as number}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </Paper>
              ) : (
                <Alert severity="info">No hay datos para este rol</Alert>
              )
            ) : (
              <Alert severity="info">Seleccione un rol para ver la tabla</Alert>
            )}
          </div>
        </div>
        <div className={styles.chart}>
          {selected === '' ? (
            <Alert severity="info">Seleccione un rol para ver el gráfico</Alert>
          ) : chartData.length > 0 ? (
            <>
              <Typography align="center" variant="h6">
                Franjas salariales para el rol {selected}
              </Typography>
              <Paper>
                <Box>
                  <Chart
                    chartType="ColumnChart"
                    data={[
                      [
                        'Level',
                        'Stacker',
                        { role: 'style' },
                        'Value',
                        { role: 'style' },
                        { role: 'tooltip' },
                      ],
                      ...chartData,
                    ]}
                    options={{
                      series: {
                        0: { enableInteractivity: false },
                      },
                      height: 350,
                      width: 750,
                      hAxis: {
                        title: 'Nivel',
                      },
                      vAxis: {
                        title: 'Rango ($)',
                      },
                      legend: 'none',
                      isStacked: true,
                    }}
                  />
                </Box>
              </Paper>
              <Button
                materialVariant={Variant.OUTLINED}
                label={'Ajustar Franja'}
                color="primary"
                sx={{ width: 'fit-content', marginTop: 2 }}
                testId={'open-individual-adjustment-modal'}
                onClick={() => dispatch(openModal(ModalIDs.SALARY_RANGE_ADJUSTMENT))}
              />
            </>
          ) : (
            <Alert severity="info">No hay datos para este rol</Alert>
          )}
        </div>
      </div>
      <Modal
        testId="massiveAdjustmentModal"
        styles={styles.modal}
        isOpen={showConfirmModal}
        onClose={() => dispatch(closeConfirmationModal())}
      >
        <ConfirmationMessage
          description={`¿Seguro que desea realizar el ajuste masivo? Esto afectará a todas las franjas salariales`}
          title={'Confirmar ajuste masivo'}
          handleConfirm={submitAdjustment}
          handleClose={() => dispatch(closeConfirmationModal())}
        />
      </Modal>
      <Modal testId={'employee-absences-modal'} id={ModalIDs.SALARY_RANGE_ADJUSTMENT}>
        <EditSalaryRangesForm
          role={selected}
          chartData={chartData}
          setLoadingReq={setLoadingReq}
          loadingReq={loadingReq}
        ></EditSalaryRangesForm>
      </Modal>
    </div>
  );
};

export default SalaryRanges;
