import styles from './skills.module.css';
import { SkillsProps, SkillsLabels } from './types';
import React from 'react';

const SkillsCard = (props: SkillsProps) => {
  const { skills, title, testId } = props;
  return (
    <div className={styles.container} data-testid={testId}>
      <h1>{title}</h1>
      <div className={styles.skillsContainer}>
        {Object.entries(skills).map((keyval, index) => (
          <div className={styles.skill} key={index}>
            <span>{keyval[1]}</span>
            <h3>{SkillsLabels[keyval[0]]}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkillsCard;
