export enum Variant {
  CONTAINED = 'contained',
  TEXT = 'text',
  OUTLINED = 'outlined',
}
export interface ButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  label?: string;
  disabled?: boolean;
  materialVariant?: Variant;
  testId?: string;
  styles?: string;
  color?: 'error' | 'inherit' | 'warning' | 'info' | 'primary' | 'secondary' | 'success';
  size?: 'small' | 'medium' | 'large';
  type?: 'submit' | 'button';
  fullWidth?: boolean;
  sx?: Record<string, unknown>;
}
