export const criticalityOptions = [
  { value: 'ALTA', label: 'Alta' },
  { value: 'MEDIA', label: 'Media' },
  { value: 'BAJA', label: 'Baja' },
];

export const projectTypeOptions = [
  { value: 'PRODUCT_BUILDING', label: 'Product Building' },
  { value: 'STAFF_AUGMENTATION', label: 'Staff Augmentation' },
];
