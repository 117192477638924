import ActivateForm from '../../shared/ui/activateForm';
import {
  formattedProjectType,
  optionsIsCritic,
  projectFilterOptions,
  projectHeaders,
} from './constants';
import styles from './projects.module.css';
import { MappedProjectData, ProjectData, ProjectFilters, SearchProjectData } from './types';
import { Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { FaPen } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import EmptyDataHandler from 'src/components/shared/common/emptyDataHandler';
import NoResultsMatched from 'src/components/shared/common/noResultsMatched';
import { Button, CannotDelete, ConfirmationMessage, Modal, Table } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import SearchBar from 'src/components/shared/ui/searchbar';
import { TableButton } from 'src/components/shared/ui/table/types';
import { UiRoutes } from 'src/constants';
import { ModalIDs, closeModal, openModal } from 'src/redux/modals/modalsSlice';
import { cleanSelectedProject } from 'src/redux/project/actions';
import { deleteProject, editProject, getProjects } from 'src/redux/project/thunk';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';
import { ErrorType } from 'src/redux/types';
import { closeConfirmationModal, openConfirmationModal } from 'src/redux/ui/actions';
import { AppDispatch, Resources } from 'src/types';
import { capitalizeFirstLetter, formattedTableData } from 'src/utils/formatters';
import { getFilteredDataByTextSearch } from 'src/utils/getFilteredDataByTextSearch';
import { tableRowDataFormatterComponent } from 'src/utils/tableRowDataFormatter';

const filterData = (list, filters: ProjectFilters) => {
  let filterDataList = list.filter(
    (item) => item.active === filters.isActive && item.criticality.includes(filters.criticality),
  );

  if (filters.search) {
    filterDataList = getFilteredDataByTextSearch(
      filterDataList,
      projectFilterOptions,
      filters.search,
    );
  }

  return filterDataList;
};

const Projects = () => {
  const [row, setRow] = React.useState({} as ProjectData | MappedProjectData);

  const dispatch: AppDispatch<null> = useAppDispatch();
  const projectError = useAppSelector((state: RootState) => state.project?.error);
  const showConfirmModal = useAppSelector((state: RootState) => state.ui.showConfirmModal);
  const projectsList = useAppSelector((state: RootState) => state.project.list);
  const clientList = useAppSelector((state: RootState) => state.client.list);

  const [dataList, setDataList] = useState([]);
  const [checked, setChecked] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState<ProjectFilters>({
    isActive: true,
    criticality: '',
    search: '',
  });
  const confirmationTitle = filters.isActive ? 'Desactivar proyecto' : 'Activar proyecto';
  const confirmationDescription = filters.isActive
    ? `¿Desea desactivar al proyecto ${row.projectName}?`
    : `¿Desea activar al proyecto ${row.projectName}?`;

  const activeProjectsList = useMemo(() => {
    const formattedProjectList = projectsList.map((project) => ({
      ...project,
      members: project?.members?.map((member) => ({
        ...member,
        fullName: `${member.employee?.user?.firstName} ${member.employee?.user?.lastName}`,
      })),
    }));
    const mappedProjects = formattedProjectList.reduce((acc, item) => {
      acc.push({
        _id: item?._id,
        projectName: item?.projectName && `${capitalizeFirstLetter(item.projectName)}`,
        clientName: item?.clientName.name && `${capitalizeFirstLetter(item.clientName.name)}`,
        projectType: item?.projectType && formattedProjectType[item.projectType],
        startDate: item?.startDate?.toString(),
        endDate: item?.endDate?.toString(),
        criticality: item?.isCritic,
        description: item?.description,
        active: item?.isActive,
        members: formattedTableData(item?.members, 'fullName'),
        notes: item?.notes,
      });
      return acc;
    }, []);
    const filteredData = filterData(mappedProjects, filters);
    return filteredData;
  }, [projectsList, filters.isActive, filters.criticality, filters.search]);

  const hasMembers = useMemo(() => {
    const selectedProject = projectsList.find((project) => project._id === row._id);
    return selectedProject?.isActive && selectedProject?.members.some((member) => member.active);
  }, [row]);

  const selectedProject = projectsList.find((project) => project._id === row._id);

  const clientFound = clientList?.find((client) => client.name === row.clientName);

  useEffect(() => {
    dispatch(getProjects());
    dispatch(cleanSelectedProject());
  }, []);

  useEffect(() => {
    const projectsToDisplay = tableRowDataFormatterComponent(activeProjectsList, 'members');
    setDataList(projectsToDisplay);
  }, [projectsList, filters.isActive, filters.criticality, filters.search]);

  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleEdit = (row) => {
    handleNavigation(`${UiRoutes.ADMIN_AM}${UiRoutes.PROJECTS_FORM}/${row._id}`);
  };

  const handleActivate = async (data) => {
    await dispatch(editProject(data));
    dispatch(closeConfirmationModal());
    dispatch(closeModal());
  };

  const handleDelete = async (id) => {
    await dispatch(deleteProject(id));
    dispatch(closeConfirmationModal());
  };

  const buttonsArray: TableButton<MappedProjectData>[] = filters.isActive
    ? [
        {
          active: true,
          testId: 'edit-button',
          variant: Variant.CONTAINED,
          onClick: (row) => handleEdit(row),
          icon: <FaPen color={'#373867'} fontSize={'20px'} />,
          tooltipLabel: 'Editar',
        },

        {
          active: true,
          label: 'Desactivar',
          testId: 'delete-button',
          variant: Variant.TEXT,
          onClick: (data) => {
            dispatch(openConfirmationModal());
            setRow(data);
          },
        },
      ]
    : [
        {
          active: true,
          label: 'Activar',
          testId: 'activate-button',
          variant: Variant.TEXT,
          onClick: (data) => {
            dispatch(openModal(ModalIDs.ACTIVATE_PROJECT));
            setRow(data);
          },
        },
      ];

  const showErrorMessage =
    projectError?.errorType === ErrorType.NETWORK_ERROR || !projectsList.length;

  return showErrorMessage ? (
    <EmptyDataHandler
      resource={Resources.Proyectos}
      handleReload={() => handleNavigation(0)}
      handleAdd={() => handleNavigation(`${UiRoutes.ADMIN_AM}${UiRoutes.PROJECTS_FORM}`)}
      error={projectError}
    />
  ) : (
    <div className={styles.tableContainer}>
      <div className={styles.welcomeMessage}>
        <Typography variant="h1">Proyectos</Typography>
      </div>
      <div className={styles.searchBar}>
        <div className={styles.searchInput}>
          <SearchBar<SearchProjectData>
            setFilter={(stringValue) => setFilters({ ...filters, search: stringValue })}
            filter={filters.search}
            keywords="Miembro, Empleado o Proyecto"
          />
        </div>
        <div className={styles.addProjectButton}>
          <Button
            materialVariant={Variant.CONTAINED}
            onClick={() => {
              handleNavigation(`${UiRoutes.ADMIN_AM}${UiRoutes.PROJECTS_FORM}`);
            }}
            label={'+ Agregar proyecto'}
            testId={'add-project-button'}
            styles={'addButton'}
          />
        </div>
      </div>
      <div className={styles.checkboxInput}>
        <div className={`${checked ? styles.filterButtonsPressed : styles.filterButtons}`}>
          <Button
            materialVariant={checked ? Variant.CONTAINED : Variant.TEXT}
            onClick={() => {
              setFilters({ ...filters, isActive: !filters.isActive });
              setChecked(!checked);
            }}
            label={'Inactivos'}
            testId={'inactive-button'}
            color={'info'}
          />
        </div>
        <select
          className={styles.filterDropdown}
          onChange={(e) => {
            setFilters({ ...filters, criticality: e.target.value });
          }}
          value={filters.criticality}
        >
          <option value={''} className={styles.option}>
            {'Criticidad'}
          </option>
          {optionsIsCritic?.map((item) => (
            <option key={item.value} value={item.value} className={styles.option}>
              {item.label}
            </option>
          ))}
        </select>
        <div className={styles.filterButtons}>
          <Button
            materialVariant={Variant.TEXT}
            onClick={() => {
              setFilters({ isActive: true, criticality: '', search: '' });
              setChecked(false);
            }}
            label={'Resetear filtros'}
            testId={'reset-filter'}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        {dataList?.length ? (
          <Table<MappedProjectData>
            showButtons
            testId={'project-table'}
            headers={projectHeaders}
            value={dataList}
            setDataList={setDataList}
            buttons={buttonsArray}
            isActive={filters.isActive}
            filters={filters}
          />
        ) : (
          <NoResultsMatched />
        )}
      </div>
      <Modal
        testId="delete-modal"
        styles={styles.modal}
        isOpen={showConfirmModal}
        onClose={() => dispatch(closeConfirmationModal())}
      >
        {hasMembers ? (
          <CannotDelete
            testId="project-not-delete"
            handleClose={() => dispatch(closeConfirmationModal())}
            isProjectsTab
          />
        ) : (
          <ConfirmationMessage
            description={confirmationDescription}
            title={confirmationTitle}
            handleConfirm={() => handleDelete(row._id)}
            handleClose={() => dispatch(closeConfirmationModal())}
          />
        )}
      </Modal>
      <Modal testId={'activate-project-modal'} id={ModalIDs.ACTIVATE_PROJECT}>
        <ActivateForm
          name={row.projectName}
          id={row._id}
          entityStartDate={selectedProject?.startDate}
          entityEndDate={selectedProject?.endDate}
          clientStartDate={clientFound?.relationshipStart}
          clientEndDate={clientFound?.relationshipEnd}
          handleActivate={handleActivate}
          title={confirmationTitle}
          description={confirmationDescription}
          isProjectsTab={true}
        />
      </Modal>
    </div>
  );
};

export default Projects;
