import { Header } from '../shared/common';
import { Loader } from '../shared/ui';
import NavigationBlocker from '../shared/ui/NavigationBlocker';
import styles from './layout.module.css';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import firebaseApp from 'src/helper/firebase';
import { closeModal } from 'src/redux/modals/modalsSlice';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';
import { closeConfirmationModal, closeLogoutModal } from 'src/redux/ui/actions';
import { getUserByUid } from 'src/redux/user/thunks';
import { AppDispatch } from 'src/types';

const Layout = (): JSX.Element => {
  const isLoading = useAppSelector((state: RootState) => state.ui.isLoading);

  const dispatch: AppDispatch<null> = useAppDispatch();
  const userData = useAppSelector((state: RootState) => state.user.userData);

  const authError = useAppSelector((state: RootState) => state.auth.authError);
  const accessTypes = useAppSelector((state: RootState) => state.auth.authUser.accessTypes);
  const { isLoading: authIsLoading } = useAppSelector((state: RootState) => state.auth);

  const handleLogout = async () => {
    try {
      await firebaseApp.auth().signOut();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!Object.keys(userData).length && !authIsLoading) {
      dispatch(getUserByUid());
    }
  }, [authIsLoading]);

  authError && handleLogout();

  useEffect(() => {
    dispatch(closeConfirmationModal());
    dispatch(closeLogoutModal());
    dispatch(closeModal());
  }, [location]);

  if (authIsLoading || !Object.keys(userData).length) return <Loader />;

  return (
    <div className={styles.container}>
      {isLoading && <Loader hasBackground />}
      {accessTypes && <Header />}

      <div className={styles.outlet}>
        <Outlet />
      </div>

      <NavigationBlocker />
    </div>
  );
};

export default Layout;
