import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from 'typesafe-actions';

// In order to make use of this slice you have to add the modal ID to ModalIDs and use openModal(ModalIDs.YOUR_NEW_MODAL_ID) to open and closeModal() to close
export const ModalIDs = {
  API_KEY: 'API_KEY' as const,
  EMPLOYEE_COMPARISON: 'EMPLOYEE_COMPARISON' as const,
  EMPLOYEE_GROWTH: 'EMPLOYEE_GROWTH' as const,
  EMPLOYEE_SKILLS: 'EMPLOYEE_SKILLS' as const,
  SKILLS: 'SKILLS' as const,
  EMPLOYEE_PROJECTS: 'EMPLOYEE_PROJECTS' as const,
  EMPLOYEE_ADD_MEMBER: 'EMPLOYEE_ADD_MEMBER' as const,
  EMPLOYEE_EDIT_MEMBER: 'EMPLOYEE_EDIT_MEMBER' as const,
  EMPLOYEE_PERFORMANCE_FEEDBACK: 'EMPLOYEE_PERFORMANCE_FEEDBACK' as const,
  EMPLOYEE_ABSENCES_FORM: 'EMPLOYEE_ABSENCES_LIST_FORM' as const,
  EMPLOYEE_ABSENCES_LIST: 'EMPLOYEE_ABSENCES' as const,
  EMPLOYEE_CUSTOM_NOTIFICATION: 'EMPLOYEE_CUSTOM_NOTIFICATION' as const,
  EMPLOYEE_CONFIRM: 'EMPLOYEE_CONFIRM' as const,
  EMPLOYEE_GROWTH_PLAN_FORM: 'EMPLOYEE_GROWTH_PLAN_FORM' as const,
  EMPLOYEE_DEACTIVATE_MEMBER: 'EMPLOYEE_DEACTIVATE_MEMBER' as const,
  SALARY_RANGE_ADJUSTMENT: 'SALARY_RANGE_ADJUSTMENT' as const,
  NOTIFICATION_RESOLVE: 'NOTIFICATION_RESOLVE' as const,
  EMPLOYEE_SALARY_CHART: 'EMPLOYEE_SALARY_CHART' as const,
  ACTIVATE_PROJECT: 'ACTIVATE_PROJECT' as const,
  NAVIGATION_BLOCKER: 'NAVIGATION_BLOCKER' as const,
  USER_ADD_FORM: 'USER_ADD_FORM' as const,
  USER_EDIT_FORM: 'USER_EDIT_FORM' as const,
  USER_DISABLE: 'USER_DISABLE' as const,
};

export type ModalID = (typeof ModalIDs)[keyof typeof ModalIDs];

type modalsState = {
  activeModal: ModalID;
};

const initialState: modalsState = {
  activeModal: null,
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: {
      reducer: (state, action: PayloadAction<string, ModalID>) => {
        state.activeModal = action.payload;
      },
      prepare: (payload: ModalID) => {
        if (payload === undefined || payload === null) {
          throw new Error('Payload must be provided');
        }
        return { payload };
      },
    },
    closeModal: (state) => {
      state.activeModal = null;
    },
  },
});

export const { openModal, closeModal } = modalsSlice.actions;

export default modalsSlice.reducer;
