import authReducer from './auth/reducer';
import clientReducer from './client/reducer';
import employeeReducer from './employee/reducer';
import memberReducer from './member/reducer';
import modalsSlice from './modals/modalsSlice';
import MovementReducer from './movements/reducer';
import navigationBlockerSlice from './navigationBlocker/navigationBlockerSlice';
import notificationReducer from './notifications/reducer';
import projectReducer from './project/reducer';
import toastSlice from './toast/toastSlice';
import uiReducer from './ui/reducer';
import userReducer from './user/reducer';
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

// TODO: configure redux store to handle types correctly after being exported.
// We are using this in customRender.tsx

// export const configureAppStore = (initialState?: RootState): ToolkitStore => {
//   const store = configureStore({
//     reducer: {
//       user: userReducer,
//       employee: employeeReducer,
//       project: projectReducer,
//       client: clientReducer,
//       ui: uiReducer,
//       auth: authReducer,
//       member: memberReducer,
//       notification: notificationReducer,
//       timesheets: timesheetsReducer,
//       movements: MovementReducer,
//       modals: modalsSlice,
//     },
//     devTools: process.env.NODE_ENV !== 'production',
//     preloadedState: initialState,
//   });

//   return store;
// };

// const store: ToolkitStore = configureAppStore();

const store = configureStore({
  reducer: {
    user: userReducer,
    employee: employeeReducer,
    project: projectReducer,
    client: clientReducer,
    ui: uiReducer,
    auth: authReducer,
    member: memberReducer,
    notification: notificationReducer,
    movements: MovementReducer,
    modals: modalsSlice,
    navigationBlocker: navigationBlockerSlice,
    toast: toastSlice,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;
