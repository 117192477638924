import { QueryCache, QueryClient } from 'react-query';
import store from 'src/redux/store';
import { openToast } from 'src/redux/toast/toastSlice';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      store.dispatch(openToast({ message: `There's been an error: ${error}`, error: true }));
      console.error(error);
    },
  }),
});
