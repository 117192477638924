import styles from './memberForm.module.css';
import { FormValues, MemberFormProps } from './types';
import { memberValidations } from './validations';
import { joiResolver } from '@hookform/resolvers/joi';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  AutocompleteInput,
  Button,
  DatePicker,
  Dropdown,
  TextInput,
} from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import EndDateCheckbox from 'src/components/shared/ui/inputs/endDateCheckbox';
import { roles, seniorityOptions } from 'src/constants/index';
import { getEmployees } from 'src/redux/employee/thunk';
import { addMember, editMember } from 'src/redux/member/thunk';
import { getProjectById } from 'src/redux/project/thunk';
import { RootState } from 'src/redux/store';
import { SeniorityType } from 'src/redux/types';
import { closeModal } from 'src/redux/ui/actions';
import { AppDispatch, Resources, ProjectRoleType } from 'src/types';
import { warningBox } from 'src/utils/memberWarningComponent/warningBox';

const MemberForm = (props: MemberFormProps) => {
  const { projectId, memberData, dropdownData } = props;

  const employeeList = useSelector((state: RootState) => state.employee.list);
  const selectedProject = useSelector((state: RootState) => state.project.selectedProject);

  const [endDateDisabled, setEndDateDisabled] = useState(false);
  const { startDate: projectStartDate, endDate: projectEndDate } = selectedProject;

  const [changed, setChanged] = useState(false);

  const dispatch: AppDispatch<null> = useDispatch();

  const {
    formState: { isValid, dirtyFields },
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      employee: { label: '', value: '' },
      role: ProjectRoleType.DEV,
      memberDedication: 0,
      helper: {
        helperReference: { label: 'Sin ayudante', value: undefined },
        dedication: 0,
        isActive: true,
      },
      startDate: new Date(),
      endDate: new Date(),
      active: true,
      seniority: SeniorityType.TRAINEE,
    },
    mode: 'onBlur',
    resolver: joiResolver(memberValidations()),
  });

  const sxProps = {
    placement: 'right',
  };

  const selectedMember = watch('employee');
  const memberDedication = watch('memberDedication');
  const helperDedication = watch('helper.dedication');
  const helperReference = watch('helper.helperReference');

  useEffect(() => {
    setValue('memberDedication', +memberDedication, { shouldDirty: true });
  }, [memberDedication]);

  useEffect(() => {
    if (helperReference?.label === 'Sin ayudante') {
      setValue('helper.dedication', 0, { shouldDirty: false });
    } else {
      setValue('helper.dedication', +helperDedication, { shouldDirty: true });
    }
  }, [helperDedication]);

  const formChanged = !Object.keys(dirtyFields).length || !isValid;

  const projectMembers = selectedProject?.members?.filter((member) => member.active);

  const employeeFiltered = dropdownData.filter(
    (employee) =>
      !projectMembers.some(
        (member) => (member.helper[0]?.helperReference as unknown as string) === employee._id,
      ),
  );

  const employeeDropdownList = employeeFiltered.map((employee) => {
    return {
      value: employee._id,
      label: `${employee.user?.firstName} ${employee.user?.lastName}`,
    };
  });

  const currentHelperIndex = memberData?.helper?.findIndex((helper) => helper.isActive);

  const filterDropdownList = () => {
    const helperDropdownList = employeeList.reduce((acc, employee) => {
      const hasMatchingMember = projectMembers.some(
        (member) => member.employee?._id === employee._id,
      );
      if (employee._id !== selectedMember.value && employee?.user?.isActive && !hasMatchingMember) {
        acc.push({
          value: employee._id,
          label: `${employee.user?.firstName} ${employee.user?.lastName}`,
        });
      }
      return acc;
    }, []);

    helperDropdownList.unshift({ value: undefined, label: 'Sin ayudante' });
    return helperDropdownList;
  };

  useEffect(() => {
    if (memberData) {
      reset({
        employee: {
          value: memberData.employee.value,
          label: employeeDropdownList?.find((item) => item.value === memberData?.employee.value)
            ?.label,
        },
        role: memberData.role,
        memberDedication: memberData.memberDedication,
        seniority: memberData.seniority,
        helper: {
          helperReference: {
            value: memberData.helper[currentHelperIndex]?.helperReference.value,
            label: filterDropdownList()?.find(
              (item) =>
                item.value === memberData.helper[currentHelperIndex]?.helperReference?.value,
            )?.label,
          },
          dedication:
            currentHelperIndex !== -1 ? memberData.helper[currentHelperIndex].dedication : 0,
          isActive: true,
        },
        startDate: new Date(memberData.startDate),
        endDate: memberData.endDate ? new Date(memberData.endDate) : undefined,
      });
      setEndDateDisabled(!memberData?.endDate);
    }
  }, [memberData]);

  const onSubmit = async (data) => {
    const { helper, employee, ...rest } = data;

    const memberDataHelper = memberData?.helper?.map((item) => {
      return {
        ...item,
        helperReference: item.helperReference.value,
      };
    });

    const helperData = {
      ...helper,
      helperReference: helper?.helperReference?.value,
    };

    if (currentHelperIndex !== undefined && currentHelperIndex !== -1) {
      memberDataHelper[currentHelperIndex].isActive = false;
    }

    if (memberDataHelper && helperData?.helperReference) {
      const helperIndex = memberDataHelper?.findIndex(
        (item) => item?.helperReference === helperData?.helperReference,
      );

      helperIndex !== -1
        ? (memberDataHelper[helperIndex] = helperData)
        : memberDataHelper?.push(helperData);
    }

    if (!endDateDisabled) rest.endDate = data.endDate;

    if (memberDataHelper) {
      rest.helper = memberDataHelper;
      dispatch(editMember({ id: memberData._id, body: rest }));
    } else {
      rest.employee = employee.value;
      rest.project = projectId;
      if (helperData?.helperReference) rest.helper = [helperData];
      dispatch(addMember(rest));
    }
    dispatch(getProjectById(selectedProject._id));
    dispatch(closeModal());
  };

  useEffect(() => {
    dispatch(getEmployees());
  }, []);

  const handleEndDateDisable = (data) => {
    setEndDateDisabled(data);
  };

  return (
    <div className={styles.modalContainer}>
      <div className={styles.headerAddMember} data-testid={'headerMessage'}>
        <p>{memberData ? 'Editar miembro' : 'Agregar miembro al proyecto'}</p>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.memberForm}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.inputsContainer}>
              <div className={styles.memberData}>
                <div className={styles.topContainer}>
                  <AutocompleteInput
                    name={'employee'}
                    control={control}
                    options={employeeDropdownList}
                    label={'Empleado'}
                    disable={memberData ? true : false}
                  />
                </div>
                <div className={styles.bottomContainer}>
                  <Dropdown
                    control={control}
                    testId={'rolesDropdown'}
                    label={'Rol'}
                    name="role"
                    options={roles}
                    fullWidth
                  />
                  <Dropdown
                    control={control}
                    testId={'seniorityDropdown'}
                    label={'Seniority'}
                    name="seniority"
                    options={seniorityOptions}
                    fullWidth
                  />
                  <TextInput
                    control={control}
                    testId={'memberDedication'}
                    label="Dedicación"
                    name="memberDedication"
                    type={'number'}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>
              <div className={styles.helperData}>
                <div className={styles.topContainer}>
                  <AutocompleteInput
                    control={control}
                    label={'Ayudante'}
                    name="helper.helperReference"
                    options={filterDropdownList()}
                    disable={watch('employee').value === ''}
                  />
                </div>
                <div className={styles.bottomContainer}>
                  <div className={styles.helperDedication}>
                    <TextInput
                      control={control}
                      testId={'helperDedication'}
                      label="Dedicacion"
                      name="helper.dedication"
                      type={'number'}
                      variant="outlined"
                      fullWidth
                      disabled={watch('helper.helperReference').value === undefined}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.datePickers}>
              <div>
                <DatePicker
                  label={'Inicio'}
                  testId={'startDate'}
                  name="startDate"
                  control={control}
                  popperProps={sxProps}
                  minDate={projectStartDate}
                  maxDate={projectEndDate}
                />
                <EndDateCheckbox
                  changed={changed}
                  setChanged={() => setChanged(!changed)}
                  endDateDisabled={endDateDisabled}
                  handleEndDateDisable={handleEndDateDisable}
                  resource={Resources.Miembros}
                />
              </div>
              <div>
                <DatePicker
                  disabled={endDateDisabled}
                  label={'Fin'}
                  testId={'endDate'}
                  name="endDate"
                  popperProps={sxProps}
                  minDate={projectStartDate}
                  maxDate={projectEndDate}
                  control={control}
                />
              </div>
            </div>
            {warningBox(
              dropdownData,
              watch('employee.value'),
              watch('helper.helperReference.value'),
            )}
            <div className={styles.buttonsContainer}>
              <div>
                <Button
                  testId="cancelButton"
                  materialVariant={Variant.OUTLINED}
                  onClick={() => dispatch(closeModal())}
                  label="Cancelar"
                />
              </div>
              <div>
                <Button
                  testId="confirmButton"
                  materialVariant={Variant.CONTAINED}
                  onClick={handleSubmit(onSubmit)}
                  label="Confirmar"
                  disabled={formChanged}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MemberForm;
