import { TableButton } from '../../shared/ui/table/types';
import { UserFilters } from '../users/types';
import { accessRoles, userFilterOptions, userHeaders } from './constants';
import { SearchUserData, UserData } from './types';
import UserForm from './userForm';
import styles from './users.module.css';
import { Typography } from '@mui/material';
import { parse } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import { FaPen, FaDeleteLeft } from 'react-icons/fa6';
import NoResultsMatched from 'src/components/shared/common/noResultsMatched';
import { Button, ConfirmationMessage, Modal, Table } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import DragAndDrop from 'src/components/shared/ui/dropZone';
import SearchBar from 'src/components/shared/ui/searchbar';
import { AccessRoleType, formattedRoleType, Modals, UserHeaders } from 'src/constants';
import { ModalIDs, closeModal, openModal } from 'src/redux/modals/modalsSlice';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';
import { ErrorType } from 'src/redux/types';
import { addUsersFromCSVError, cleanUserError } from 'src/redux/user/actions';
import { activateUser, addUsersFromCSV, deleteUser, getUsers } from 'src/redux/user/thunks';
import { User } from 'src/redux/user/types';
import { AppDispatch } from 'src/types';
import { capitalizeFirstLetter } from 'src/utils/formatters';
import { getFilteredDataByTextSearch } from 'src/utils/getFilteredDataByTextSearch';

const filterData = (list, filters: UserFilters) => {
  let filterDataList = list.filter((item) => item.active === filters.isActive);

  if (filters.role !== formattedRoleType.ALL) {
    filterDataList = filterDataList.filter((item) => item.accessTypes?.includes(filters.role));
  }

  if (filters.search) {
    filterDataList = getFilteredDataByTextSearch(filterDataList, userFilterOptions, filters.search);
  }

  return filterDataList;
};

const Users = () => {
  const [row, setRow] = React.useState({} as UserData);
  const [filters, setFilters] = React.useState<UserFilters>({
    isActive: true,
    role: '',
    search: '',
  });
  const [dataList, setDataList] = React.useState([]);
  const [childrenId, setChildrenId] = React.useState('');
  const [checked, setChecked] = React.useState(false);
  const [usersArray, setUsersArray] = React.useState([]);
  const superAdmin = useAppSelector((state: RootState) => state.auth.authUser);
  const userList = useAppSelector((state: RootState) => state.user.list);
  const userError = useAppSelector((state: RootState) => state.user.error);
  const memberList = useAppSelector((state: RootState) => state.member.list);
  const activeModal = useAppSelector((state) => state.modals.activeModal);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const confirmationTitle = filters.isActive ? 'Desactivar usuario' : 'Activar usuario';
  const confirmationDescription = filters.isActive
    ? `¿Desea desactivar al usuario ${row.name}?`
    : `¿Desea activar al usuario ${row.name}?`;

  const dispatch: AppDispatch<null> = useAppDispatch();

  const activeUsers = useMemo(() => {
    const mappedUser = userList.reduce((acc, item) => {
      const accessType = item?.accessTypes?.find(
        (accessType) =>
          accessType === AccessRoleType.SUPER_ADMIN ||
          accessType === AccessRoleType.EMPLOYEE ||
          AccessRoleType.ADMIN_AM,
      );

      if (accessType !== AccessRoleType.SUPER_ADMIN) {
        acc.push({
          _id: item?._id,
          firebaseUid: item?.firebaseUid,
          accessTypes: item?.accessTypes,
          email: item?.email,
          name: `${capitalizeFirstLetter(item?.firstName)} ${capitalizeFirstLetter(
            item?.lastName,
          )}`,
          firstName: item?.firstName,
          lastName: item?.lastName,
          operationalEmployee: item?.operationalEmployee,
          administrativeEmployee: item?.administrativeEmployee,
          location: item?.location,
          phone: item?.phone,
          address: item?.address,
          dni: item?.dni,
          birthDate: item?.birthDate?.toString(),
          active: item?.isActive,
        });
      }
      return acc;
    }, []);
    const filteredData = filterData(mappedUser, filters);
    return filteredData;
  }, [userList, filters.isActive, filters.role, filters.search]);

  const userWithMember = memberList.find((member) => member.employee?.user?._id === row._id);

  useEffect(() => {
    setDataList(activeUsers);
  }, [userList, filters.isActive, filters.role, filters.search]);

  const handleDelete = (data) => {
    dispatch(deleteUser(data._id));
    dispatch(closeModal());
  };

  const handleActivate = (data) => {
    dispatch(activateUser(data.id));
    dispatch(closeModal());
  };

  const options = {
    id: row._id,
    body: {
      isActive: true,
      accessTypes: userList.find((user) => user._id === row._id)?.accessTypes,
    },
  };

  const buttonsArray: TableButton<UserData>[] = filters.isActive
    ? [
        {
          active: true,
          testId: 'delete-button',
          variant: Variant.CONTAINED,
          onClick: (data) => {
            dispatch(openModal(ModalIDs.USER_DISABLE));
            setRow(data);
          },
          icon: <FaDeleteLeft color={'#373867'} fontSize={'20px'} />,
        },
        {
          active: true,
          testId: 'edit-button',
          variant: Variant.CONTAINED,
          onClick: (data) => {
            dispatch(openModal(ModalIDs.USER_EDIT_FORM));
            setRow(data);
          },
          icon: <FaPen color={'#373867'} fontSize={'20px'} />,
        },
      ]
    : [
        {
          active: true,
          label: 'Activar',
          testId: 'activate-button',
          variant: Variant.TEXT,
          onClick: (data) => {
            dispatch(openModal(ModalIDs.USER_DISABLE));
            setRow(data);
          },
        },
      ];

  const showErrorMessage = userError?.errorType === ErrorType.NETWORK_ERROR || !userList.length;

  const parseHeaderName = (header) => {
    switch (header) {
      case UserHeaders.ROL:
        return 'accessTypes';
      case UserHeaders.EMAIL:
        return 'email';
      case UserHeaders.NOMBRE:
        return 'firstName';
      case UserHeaders.APELLIDO:
        return 'lastName';
      case UserHeaders.DNI:
        return 'dni';
      case UserHeaders.DIRECCION:
        return 'address';
      case UserHeaders.TELEFONO:
        return 'phone';
      case UserHeaders.CIUDAD:
        return 'location';
      case UserHeaders.FECHA_DE_NACIMIENTO:
        return 'birthDate';
      case UserHeaders.COMPANY:
        return 'company';
    }
  };

  const onClose = () => {
    dispatch(closeModal());
    setUsersArray([]);
  };

  const onConfirm = () => {
    !userError && dispatch(addUsersFromCSV(usersArray));
    dispatch(closeModal());
    setUsersArray([]);
  };

  //TODO: send the CSV file to the servers and move this logic there
  const handleOnDrop = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () =>
        dispatch(
          addUsersFromCSVError({
            message: 'Lectura del archivo abortada',
            errorType: ErrorType.READER_ERROR,
          }),
        );
      reader.onerror = () =>
        dispatch(
          addUsersFromCSVError({
            message: 'Ocurrio un error al intentar leer el archivo',
            errorType: ErrorType.READER_ERROR,
          }),
        );
      reader.onload = () => {
        dispatch(cleanUserError());

        // Do whatever you want with the file contents
        const result = reader.result;

        if (typeof result !== 'string') return;

        if (result.includes(',,')) {
          dispatch(
            addUsersFromCSVError({
              message: 'El archivo contiene campos vacios',
              errorType: ErrorType.READER_ERROR,
            }),
          );
          return;
        }

        const [headersLine, ...lines] = result.split('\n').map((item) => {
          return item.replace(/(\r\n|\n|\r)/gm, '');
        });

        const headers = headersLine
          .split(',')
          .map((header) => parseHeaderName(header.toLocaleLowerCase()));

        if (headers.some((header) => !header)) {
          dispatch(
            addUsersFromCSVError({
              message:
                'Los encabezados deben ser: Rol, Email, Nombre, Apellido, DNI, Direción, Teléfono, Localidad y Fecha de nacimiento.',
              errorType: ErrorType.READER_ERROR,
            }),
          );
          return;
        }
        const objectsArray = lines.map((userData) => {
          const userObject = userData.split(',').reduce((acc, cur, index) => {
            if (headers[index] === 'birthDate') {
              const formattedDate = parse(cur, 'dd/MM/yyyy', new Date());
              return {
                ...acc,
                [headers[index]]: formattedDate,
              };
            }
            return {
              ...acc,
              [headers[index]]: cur,
            };
          }, {});

          return { ...userObject, isActive: true };
        });
        setUsersArray(objectsArray);
      };
      reader.readAsText(file);
    });
  };

  const childrenModalSelector = (id) => {
    switch (id) {
      case Modals.DRAG_ZONE:
        return (
          <DragAndDrop
            handleOnDrop={handleOnDrop}
            onClose={onClose}
            onConfirm={onConfirm}
            testId={'drop-zone'}
            list={usersArray}
          />
        );
      case Modals.USERS_FORM:
        return <UserForm />;
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.welcomeMessage}>
          <div className={styles.title}>
            <Typography data-testid="user-welcome-title" variant="h1">
              ¡Bienvenido {superAdmin.name}!
            </Typography>
          </div>
          <p data-testid="user-subtitle">
            Esta es la lista de usuarios. Puedes asignarles el acceso que desees.
          </p>
        </div>
        <div className={styles.topTableContainer}>
          <div className={styles.searchBar}>
            <SearchBar<SearchUserData>
              setFilter={(stringValue) => setFilters({ ...filters, search: stringValue })}
              filter={filters.search}
            />
          </div>
          <div className={styles.buttonsContainer}>
            <div className={styles.addUserButton}>
              <Button
                materialVariant={Variant.CONTAINED}
                onClick={() => {
                  dispatch(openModal(ModalIDs.USER_ADD_FORM));
                  setChildrenId(Modals.USERS_FORM);
                }}
                label={'+ Agregar un nuevo usuario'}
                testId={'add-user-button'}
              />
            </div>
            <div className={styles.addCSVButton}>
              <Button
                materialVariant={Variant.CONTAINED}
                onClick={() => {
                  dispatch(openModal(ModalIDs.USER_ADD_FORM));
                  setChildrenId(Modals.DRAG_ZONE);
                }}
                label={'+ Agregar CSV'}
                testId={'add-csv-button'}
                disabled
              />
            </div>
          </div>
        </div>
        <div className={styles.checkboxInput}>
          <div className={`${checked ? styles.filterButtonsPressed : styles.filterButtons}`}>
            <Button
              materialVariant={checked ? Variant.CONTAINED : Variant.TEXT}
              onClick={() => {
                setFilters({ ...filters, isActive: !filters.isActive });
                setChecked(!checked);
              }}
              label={'Inactivos'}
              testId={'inactive-filter-button'}
              color={'info'}
            />
          </div>
          <select
            className={styles.filterDropdown}
            data-testid="role-dropdown"
            onChange={(e) => {
              setFilters({ ...filters, role: e.target.value as '' | AccessRoleType });
            }}
          >
            <option value={''} className={styles.option}>
              {'Rol de acceso'}
            </option>
            {accessRoles.map((item) => (
              <option
                data-testid={item.label}
                key={item.value}
                value={item.value}
                className={styles.option}
              >
                {item.label}
              </option>
            ))}
          </select>
          <div className={styles.filterButtons}>
            <Button
              materialVariant={Variant.TEXT}
              onClick={() => {
                setFilters({ isActive: true, role: formattedRoleType.ALL, search: '' });
                setChecked(false);
              }}
              label={'Resetear filtros'}
              testId={'reset-filter-button'}
            />
          </div>
        </div>
        <div className={styles.tableContainer}>
          {dataList?.length ? (
            <Table<UserData>
              showButtons
              testId={'user-table'}
              headers={userHeaders}
              value={dataList}
              buttons={buttonsArray}
              setDataList={setDataList}
              isActive={filters.isActive}
              filters={filters}
            />
          ) : (
            <NoResultsMatched />
          )}
        </div>
      </div>
      {activeModal && (
        <>
          <div className={styles.modalContainer}>
            <Modal
              onClose={() => dispatch(closeModal())}
              id={ModalIDs.USER_ADD_FORM}
              testId="add-user-modal"
              maxWidth="xl"
            >
              {childrenModalSelector(childrenId)}
            </Modal>
          </div>
          {!showErrorMessage && (
            <Modal
              testId={'user-access-modal'}
              id={ModalIDs.USER_EDIT_FORM}
              onClose={() => dispatch(closeModal())}
              maxWidth="xl"
            >
              <UserForm userToEdit={row as User} />
            </Modal>
          )}
          <Modal
            testId="delete-user-modal"
            styles={styles.modal}
            id={ModalIDs.USER_DISABLE}
            onClose={() => dispatch(closeModal())}
          >
            <ConfirmationMessage
              userModal={userWithMember?._id}
              description={confirmationDescription}
              title={confirmationTitle}
              handleConfirm={() => (filters.isActive ? handleDelete(row) : handleActivate(options))}
              handleClose={() => dispatch(closeModal())}
              testIdDescription="delete-modal-desc"
              testIdTitle="delete-modal-title"
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default Users;
