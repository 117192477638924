import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from 'typesafe-actions';

type toastState = {
  toastMessage: string;
  isError: boolean;
};

const initialState: toastState = {
  toastMessage: '',
  isError: false,
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    openToast: {
      reducer: (state, action: PayloadAction<string, { message: string; error: boolean }>) => {
        state.toastMessage = action.payload.message;
        state.isError = action.payload.error;
      },
      prepare: (payload: { message: string; error: boolean }) => {
        if (payload === undefined || payload === null) {
          throw new Error('Payload must be provided');
        }
        if (typeof payload.message !== 'string') {
          payload.message = 'Unknown error';
        }
        return { payload };
      },
    },
    closeToast: (state) => {
      state.toastMessage = '';
      state.isError = false;
    },
  },
});

export const { openToast, closeToast } = toastSlice.actions;

export default toastSlice.reducer;
