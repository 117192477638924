import { Tooltip } from '@mui/material';
import React from 'react';

export const tableRowDataFormatterComponent = (list, indexer: string) => {
  const formattedList = list.map((item) => {
    const { [indexer]: data, ...rest } = item;
    const fieldArray = data.split('!').filter((item) => item.length > 3);
    if (fieldArray.length > 1) {
      return {
        ...rest,
        [indexer]: (
          <Tooltip
            title={fieldArray.join(', ')}
            enterDelay={200}
            arrow
            placement="top"
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -2],
                  },
                },
              ],
            }}
          >
            <div>{`${fieldArray[0]} y ${fieldArray.length - 1} más`}</div>
          </Tooltip>
        ),
      };
    } else if (fieldArray.length === 1) {
      return {
        ...rest,
        [indexer]: `${fieldArray[0]}`,
      };
    }
    return {
      ...rest,
      [indexer]: ' - ',
    };
  });
  return formattedList;
};
