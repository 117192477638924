import { NavtabProps, TabPanelProps } from './types';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...rest } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

const Navtab = (props: NavtabProps) => {
  const { labels, components, activeTab, onTabChange } = props;

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    onTabChange(newValue);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Tabs value={activeTab} onChange={handleChange} aria-label="basic tabs example">
          {labels.map((label, index) => (
            <Tab key={index} label={label} />
          ))}
        </Tabs>
      </Box>
      {components.map((component, index) => (
        <TabPanel key={index} value={activeTab} index={index}>
          {component}
        </TabPanel>
      ))}
    </Box>
  );
};

export default Navtab;
