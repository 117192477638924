import styles from './activateForm.module.css';
import { FormValues, ActivateProjectFormProps } from './types';
import { activateProjectValidations } from './validations';
import { joiResolver } from '@hookform/resolvers/joi';
import { addDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, ConfirmationMessage, DatePicker, Modal } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import EndDateCheckbox from 'src/components/shared/ui/inputs/endDateCheckbox';
import { closeModal } from 'src/redux/modals/modalsSlice';
import { RootState, useAppSelector, useAppDispatch } from 'src/redux/store';
import { closeConfirmationModal, openConfirmationModal } from 'src/redux/ui/actions';
import { Resources } from 'src/types';

const ActivateForm = (props: ActivateProjectFormProps) => {
  const {
    name,
    id,
    entityStartDate,
    entityEndDate,
    clientStartDate,
    clientEndDate,
    handleActivate,
    title,
    description,
    isProjectsTab = false,
  } = props;
  const showConfirmModal = useAppSelector((state: RootState) => state.ui.showConfirmModal);

  const [endDateDisabled, setEndDateDisabled] = useState(false);
  const [changed, setChanged] = useState(false);

  const dispatch = useAppDispatch();

  const {
    formState: { errors },
    control,
    watch,
    reset,
    clearErrors,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      startDate: null,
      endDate: null,
    },
    mode: 'onBlur',
    shouldFocusError: true,
    resolver: joiResolver(activateProjectValidations()),
  });

  const sxProps = {
    placement: 'right',
  };
  const startDateValue = watch('startDate');
  const endDateValue = watch('endDate');

  const minEndDate = startDateValue ? addDays(new Date(startDateValue), 1) : null;

  useEffect(() => {
    if (changed) {
      clearErrors();
      setValue('endDate', null);
    } else {
      setValue('endDate', entityEndDate, { shouldValidate: true });
    }
  }, [changed]);

  useEffect(() => {
    reset({
      startDate: entityStartDate,
      endDate: entityEndDate,
    });
    setEndDateDisabled(!entityEndDate);
  }, []);

  const handleEndDateDisable = (data) => {
    setEndDateDisabled(data);
  };

  const options = {
    id: id,
    [isProjectsTab ? 'projectName' : 'clientName']: name,
    body: {
      isActive: true,
      [isProjectsTab ? 'startDate' : 'relationshipStart']: startDateValue,
      [isProjectsTab ? 'endDate' : 'relationshipEnd']: endDateValue,
    },
  };

  return (
    <div className={styles.modalContainer}>
      <div className={styles.header} data-testid={'header-message'}>
        <p>{`Activar ${isProjectsTab ? 'proyecto' : 'cliente'} ${name}`}</p>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.datePickers}>
          <div>
            <DatePicker
              label={'Inicio'}
              testId={'start-date'}
              name="startDate"
              control={control}
              popperProps={sxProps}
              minDate={isProjectsTab ? clientStartDate : new Date(2017, 0, 1)}
              maxDate={clientEndDate}
            />
            <EndDateCheckbox
              changed={changed}
              setChanged={() => setChanged(!changed)}
              endDateDisabled={endDateDisabled}
              handleEndDateDisable={handleEndDateDisable}
              resource={isProjectsTab ? Resources.Proyectos : Resources.Clientes}
            />
          </div>
          <div className={styles.datePicker}>
            <DatePicker
              disabled={endDateDisabled}
              label={'Fin'}
              testId={'end-date'}
              name="endDate"
              popperProps={sxProps}
              control={control}
              minDate={minEndDate}
              maxDate={
                isProjectsTab
                  ? clientEndDate
                  : new Date(
                      new Date(startDateValue).getFullYear() + 10,
                      new Date(startDateValue).getMonth(),
                      new Date(startDateValue).getDate(),
                    )
              }
            />
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <div>
            <Button
              testId="cancel-button"
              materialVariant={Variant.OUTLINED}
              onClick={() => dispatch(closeModal())}
              label="Cancelar"
            />
          </div>
          <div>
            <Button
              testId="confirm-button"
              materialVariant={Variant.CONTAINED}
              onClick={() => dispatch(openConfirmationModal())}
              label="Confirmar"
              disabled={Object.keys(errors).length !== 0}
            />
          </div>
        </div>
      </div>
      <Modal
        testId="delete-modal"
        styles={styles.modal}
        isOpen={showConfirmModal}
        onClose={() => dispatch(closeConfirmationModal())}
      >
        <ConfirmationMessage
          description={description}
          title={title}
          handleConfirm={() => handleActivate(options)}
          handleClose={() => dispatch(closeConfirmationModal())}
        />
      </Modal>
    </div>
  );
};

export default ActivateForm;
