import styles from './EndDateCheckbox.module.css';
import { EndDateCheckboxProps } from './types';
import React from 'react';
import { cutLastLetter } from 'src/utils/formatters';

const EndDateCheckbox = (props: EndDateCheckboxProps) => {
  const { handleEndDateDisable, endDateDisabled, resource, setChanged, changed } = props;

  return (
    <div className={styles.container}>
      <input
        onChange={() => setChanged(!changed)}
        type="checkbox"
        checked={endDateDisabled}
        onClick={() => handleEndDateDisable(!endDateDisabled)}
        id={resource}
        className={styles.input}
      />
      <label htmlFor={resource} className={styles.checkboxMsg}>
        {cutLastLetter(resource)} long term
      </label>
    </div>
  );
};

export default EndDateCheckbox;
