import { ReqStates } from '../types';
import { Dispatch, SetStateAction } from 'react';

export interface FormValues {
  [key: string]: {
    min: number;
    max: number;
  };
}

export interface EditSalaryRangesFormProps {
  role: string;
  chartData: [string, number, number, number][];
  setLoadingReq: Dispatch<SetStateAction<ReqStates>>;
  loadingReq: ReqStates;
}

const levels = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'];

export const inputNames = levels.map((level) => ({
  min: `level${level}.min`,
  max: `level${level}.max`,
}));
