import { addYears } from 'date-fns';
import Joi from 'joi';

const clientValidation = (clientNameValidation, startDate, endDate) => {
  const minDate = new Date(2017, 0, 1);
  const maxDateRelationshipEnd = addYears(new Date(startDate), 10);

  return Joi.object({
    name: Joi.string()
      .min(3)
      .max(35)
      .trim()
      .custom((value, helper) => {
        if (value && clientNameValidation) {
          return helper.error('any.invalid');
        }
      })
      .messages({
        'string.min': 'El nombre debe tener al menos 3 caracteres',
        'string.max': 'El nombre debe tener máximo 35 caracteres',
        'string.empty': 'Este campo es requerido',
        'any.invalid': 'Cliente ya registrado',
      })
      .required(),

    localContact: Joi.object({
      name: Joi.string()
        .min(3)
        .max(35)
        .regex(/^[a-zA-ZñáéíóúüÁÉÍÓÚÜ\s]*$/)
        .trim()
        .messages({
          'string.min': 'El nombre debe contener al menos 3 letras',
          'string.empty': 'Este campo es requerido',
          'string.max': 'El nombre debe tener máximo 35 letras',
          'string.pattern.base': 'El nombre debe contener solo letras',
        })
        .required(),
      email: Joi.string()
        .regex(/^[a-zA-Z]+\.+[a-zA-Z]+@(radiumrocket.com)$/)
        .min(20)
        .trim()
        .messages({
          'string.empty': 'Este campo es requerido',
          'string.min': 'El email debe contener al menos 3 letras',
          'string.pattern.base': 'El formato debe ser nombre.apellido@radiumrocket.com',
        })
        .required(),
    }),

    clientContact: Joi.object({
      name: Joi.string()
        .min(3)
        .max(35)
        .regex(/^[a-zA-ZñáéíóúüÁÉÍÓÚÜ\s]*$/)
        .trim()
        .messages({
          'string.min': 'El nombre debe contener al menos 3 letras',
          'string.empty': 'Este campo es requerido',
          'string.max': 'El nombre debe tener máximo 35 letras',
          'string.pattern.base': 'El nombre debe contener solo letras',
        })
        .required(),

      email: Joi.string()
        .regex(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
        .trim()
        .messages({
          'string.empty': 'Este campo es requerido',
          'string.min': 'El email debe contener al menos 3 letras',
          'string.pattern.base': 'Formato de email no es válido',
        })
        .required(),
    }),

    relationshipStart: Joi.date()
      .min(minDate)
      .custom((value, helper) => {
        if (value && endDate && value > new Date(endDate)) {
          return helper.error('date.max');
        }
      })
      .messages({
        'date.base': 'Formato dd/mm/aaaa',
        'date.min': 'La fecha de inicio debe ser mayor a 01/01/2017',
        'date.max': 'La fecha máxima es inválida',
      })
      .allow(null),

    relationshipEnd: Joi.date()
      .min(startDate)
      .max(new Date(maxDateRelationshipEnd))
      .messages({
        'date.min': 'Fecha de fin debe ser mayor a la fecha de inicio',
        'date.max': 'La relación no puede durar más de 10 años',
        'date.base': 'Formato dd/mm/aaaa',
      })
      .allow(null),

    notes: Joi.string()
      .min(0)
      .max(500)
      .trim()
      .custom((value, helpers) => {
        if (value.trim() !== value) {
          return helpers.error('string.trim');
        }
        return value;
      }, 'custom validation')
      .messages({
        'string.base': 'Notas tiene que ser un string',
        'string.max': 'Notes no debe contener más de  500 caracteres',
        'string.trim': 'La nota no puede contener espacios al principio o al final',
      }),
  }).options({ allowUnknown: true });
};

export default {
  clientValidation,
};
