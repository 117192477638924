import { PrivateRouteProps } from './types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { unblockNavigation } from 'src/redux/navigationBlocker/navigationBlockerSlice';
import store, { RootState } from 'src/redux/store';

const PrivateRoute = ({
  accessTypes,
  redirectPath = '/not-allowed',
  children,
}: PrivateRouteProps): JSX.Element => {
  const { error, isLoading, authUser } = useSelector((state: RootState) => state.auth);

  if (isLoading) {
    return null;
  }

  const sessionAccessTypes = authUser.accessTypes;
  const sessionToken = authUser.token;

  if (!authUser.isActive || !sessionAccessTypes.includes(accessTypes) || error || !sessionToken) {
    store.dispatch(unblockNavigation());
    return <Navigate to={redirectPath} />;
  }
  return children ? children : <Outlet />;
};

export default PrivateRoute;
