export const AbsencesBackgroundColor = {
  Vacaciones: '#4f987b',
  Estudio: '#89ccaf',
  Licencia: '#1a4b3b',
};

export enum AbsencesStates {
  Aceptada = 'Aceptada',
  Rechazada = 'Rechazada',
  Cancelada = 'Cancelada',
  Pendiente = 'Pendiente',
}
