import styles from './card.module.css';
import { dataCards } from './constants';
import { CardProps } from './types';
import { Avatar, AvatarGroup, Grow, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { format, isAfter } from 'date-fns';
import React from 'react';
import { FaUserGroup, FaRegIdCard, FaRegFileLines, FaRegCircleCheck } from 'react-icons/fa6';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Member } from 'src/components/pages/home/types';
import { UiRoutes } from 'src/constants';
import { ModalIDs, openModal } from 'src/redux/modals/modalsSlice';
import { AppDispatch } from 'src/types';

const defineIcon = (resourceType) => {
  switch (resourceType) {
    case 'EMPLOYEE':
      return {
        icon: <FaUserGroup fontSize={'18px'} color={'#ffffff'} />,
        color: styles.employeeTabIcon,
      };
    case 'CLIENT':
      return {
        icon: <FaRegIdCard fontSize={'18px'} color={'#ffffff'} />,
        color: styles.clientTabIcon,
      };
    default:
      return {
        icon: <FaRegFileLines fontSize={'18px'} color={'#ffffff'} />,
        color: styles.projectTabIcon,
      };
  }
};

const defineCriticality = (criticality) => {
  switch (criticality) {
    case 'MEDIA':
      return styles.mediumCriticality;
    case 'ALTA':
      return styles.highCriticality;
    case 'BAJA':
      return styles.lowCriticality;
    default:
      return '';
  }
};

const Card = (props: CardProps) => {
  const {
    name,
    resource,
    members,
    notification,
    criticality,
    customMessage,
    isCustom,
    resourceId,
    id,
    limitDate,
    cardId,
  } = props;
  const navigate = useNavigate();
  const dispatch: AppDispatch<null> = useDispatch();

  const isProject = !!(resource === 'PROJECT');
  const isEmployee = !!(resource === 'EMPLOYEE');
  const cardIcon = defineIcon(resource);
  const criticalityColor = defineCriticality(criticality);
  const isExpired = isAfter(new Date(), new Date(limitDate));
  const shownNotification = isCustom ? 'Notificación Personalizada' : 'Notificación Automática';
  const resourcesTranslations = {
    PROJECT: 'PROYECTO',
    CLIENT: 'CLIENTE',
    EMPLOYEE: 'EMPLEADO',
  };

  const translatedResource = resourcesTranslations[resource];

  const changedNotificationData = (notification) => {
    const newData = dataCards.find((item) => item.key === notification);
    return newData?.data;
  };

  const redirectClick = (data) => {
    if (isProject) {
      navigate(`${UiRoutes.ADMIN_AM}${UiRoutes.PROJECTS_FORM}/${data}`);
    } else if (isEmployee) {
      navigate(`${UiRoutes.ADMIN_AM}${UiRoutes.EDIT_EMPLOYEES}/${data}`);
    } else {
      navigate(`${UiRoutes.ADMIN_AM}${UiRoutes.CLIENTS_FORM}/${data}`);
    }
  };

  return (
    <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
      <div data-testid={'card-component'} className={styles.cardComponent}>
        <div className={`${styles.baseIconTab} ${cardIcon.color}`} data-testid="card-icon">
          {cardIcon.icon}
        </div>
        <div
          onClick={() => redirectClick(resourceId)}
          className={
            isExpired
              ? `${styles.expiredCardContainer} ${styles.expiredCard}`
              : `${styles.cardContainer} ${styles.card}`
          }
        >
          <div className={styles.cardContent}>
            <div className={styles.title}>
              <div className={styles.nameContainer}>
                {isEmployee && <Avatar className={styles.avatars} />}
                <span key={resourceId} data-testid="name-resource">
                  {name}
                </span>
              </div>
              <span data-testid="resource-tittle">{translatedResource}</span>
            </div>
            {isProject && (
              <div className={styles.projectInfo}>
                <>
                  <div className={styles.membersContainer}>
                    <div className={styles.avatarsContainer}>
                      <AvatarGroup className={styles.avatars}>
                        {members.map((member: Member) => {
                          return <Avatar key={member._id} />;
                        })}
                      </AvatarGroup>
                    </div>
                    <p>{members?.length} involucrados</p>
                  </div>
                  <div className={`${styles.criticality} ${criticalityColor}`}>
                    {criticality?.toUpperCase()}
                  </div>
                </>
              </div>
            )}
            <div className={styles.customMessage}>
              {isCustom ? customMessage : changedNotificationData(notification)}
            </div>
            <Typography fontSize={10} sx={{}}>
              Fecha límite: {format(new Date(limitDate || null), 'dd/MM/yyyy')}
            </Typography>
          </div>
          <div className={styles.notification}>
            <p>{shownNotification}</p>
            <Tooltip
              title="Marcar como resuelta."
              enterDelay={100}
              arrow
              classes={{
                tooltip: styles.customTooltip,
              }}
              placement="top"
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -2],
                    },
                  },
                ],
              }}
            >
              <div
                className={styles.tickIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(openModal(ModalIDs.NOTIFICATION_RESOLVE));
                  cardId(id);
                }}
                data-testid="delete-card"
              >
                <FaRegCircleCheck color="#373867" />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </Grow>
  );
};

export default Card;
