import { availabilityOptions, roleCheckboxOptions } from '../employees/employeeEdit/constants';
import styles from './availability.module.css';
import { AbsencesStates } from './byId/types';
import { FilteredData } from './types';
import { TablePagination, Typography } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import NoResultsMatched from 'src/components/shared/common/noResultsMatched';
import { Button, SearchBar } from 'src/components/shared/ui';
import { Loader } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import { UiRoutes } from 'src/constants';
import { getEmployees } from 'src/redux/employee/thunk';
import { Employee } from 'src/redux/employee/types';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';

const GeneralAvailability = () => {
  const navigate = useNavigate();
  const employeeList = useAppSelector((state: RootState) => state.employee?.list);
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState<Employee[]>([] as Employee[]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [chartMaxDate, setChartMaxDate] = React.useState('');
  const [selectedRole, setSelectedRole] = React.useState('');
  const [selectedAvailability, setSelectedAvailability] = React.useState('');
  const [filteredData, setFilteredData] = React.useState<FilteredData>({});
  const [filters, setFilters] = React.useState({
    isActive: true,
    role: '',
    period: '',
    availability: '',
    search: '',
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (employeeList.length > 0) {
      setDataList(employeeList);
    } else {
      dispatch(getEmployees());
    }
    setLoading(false);
  }, [employeeList]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const generateDates = (months: number) => {
    const dates = [];
    const currentDate = new Date();
    for (let i = 0; i <= months; i++) {
      dates.push(currentDate.toISOString().split('T')[0]);
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return dates;
  };

  const handleMonthFilters = (e) => {
    setFilters({ ...filters, period: e.target.value });
    let months;
    switch (e.target.value) {
      case 'three':
        months = 3;
        break;
      case 'six':
        months = 6;
        break;
      case 'twelve':
        months = 12;
        break;
      default:
        months = 0;
    }
    const dates = generateDates(months);
    const maxDate = dates[dates.length - 1];
    setChartMaxDate(maxDate);
  };

  const dataMemo = useMemo(() => {
    const labelsToShow = dataList?.map((item) => ({
      label: `${item.user?.lastName} ${item.user?.firstName} - ${item.role}`,
      role: item.role,
      availability: item.availability?.description,
    }));

    const projectsToShow = [
      ...new Set(
        dataList.flatMap((item) =>
          item.currentProjects?.map((project) => project.project.projectName),
        ),
      ),
    ].map((projectName) => ({
      label: projectName,
      backgroundColor: '#373867cc',
      borderWidth: 1,
      borderRadius: 5,
      height: 20,
      fill: false,
      data: dataList?.map((employee) => {
        const projectData = employee.currentProjects?.find(
          (project) => project.project.projectName === projectName,
        );
        if (!projectData) {
          return null;
        }
        if (!projectData?.endDate) {
          return [
            projectData?.startDate.slice(0, 10),
            new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
          ];
        }
        return [projectData?.startDate.slice(0, 10), projectData?.endDate?.slice(0, 10)];
      }),
      skipNull: true,
    }));

    const absencesToShow = [
      ...new Set(dataList?.flatMap((item) => item.absences?.map((absence) => absence.motive))),
    ].map((absenceName) => ({
      label: absenceName,
      backgroundColor: '#4F987B',
      borderWidth: 1,
      borderRadius: 5,
      data: dataList?.map((employee) => {
        const absenceData = employee.absences?.find((absence) => absence.motive === absenceName);
        if (!absenceData || absenceData?.state !== AbsencesStates.Aceptada) {
          return null;
        }
        return [
          (absenceData?.startDate as string).slice(0, 10),
          (absenceData?.endDate as string)?.slice(0, 10),
        ];
      }),
      skipNull: true,
    }));

    const chartJSData = {
      labels: labelsToShow,
      datasets: [...projectsToShow, ...absencesToShow],
    };

    const combinedData = chartJSData.labels.map((label, index) => ({
      label: label,
      data: chartJSData.datasets.map((dataset) => dataset.data[index]),
    }));

    const filteredAndSortedData = combinedData
      .filter(
        (item) =>
          item.label.label.toLowerCase().includes(filters.search.toLowerCase()) &&
          (selectedRole === '' || item.label.role === selectedRole) &&
          (selectedAvailability === '' || item.label.availability === selectedAvailability),
      )
      .sort((a, b) => a.label.label.localeCompare(b.label.label));

    const chartJSFilteredData = {
      labels: filteredAndSortedData.map((item) => item.label),
      datasets: chartJSData.datasets.map((dataset, i) => ({
        ...dataset,
        data: filteredAndSortedData.map((item) => item.data[i]),
      })),
    };

    setFilteredData(chartJSFilteredData || {});

    let currentLabels = [];
    let currentDatasets = [];
    let currentRoles = [];
    let currentAvailability = [];

    if (chartJSFilteredData && chartJSFilteredData.labels && chartJSFilteredData.datasets) {
      currentLabels = chartJSFilteredData.labels
        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
        .map((label) => label.label);

      currentDatasets = chartJSFilteredData.datasets.map((dataset) => ({
        ...dataset,
        data: dataset.data.slice(page * rowsPerPage, (page + 1) * rowsPerPage),
      }));

      currentRoles = chartJSFilteredData.labels
        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
        .map((label) => label.role);

      currentAvailability = chartJSFilteredData.labels
        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
        .map((label) => label.availability);
    }

    const currentChartJSData = {
      labels: currentLabels,
      datasets: currentDatasets,
      roles: currentRoles,
      availability: currentAvailability,
    };

    const resetFilters = () => {
      setSelectedRole('');
      setSelectedAvailability('');
      setChartMaxDate('');
      setFilters({
        ...filters,
        role: '',
        period: '',
        availability: '',
        search: '',
      });
      setFilteredData(chartJSData);
    };

    const chartJSConfig: ChartOptions<'bar'> = {
      responsive: true,
      indexAxis: 'y' as const,
      plugins: {
        legend: {
          position: 'bottom' as const,
          align: 'end' as const,
          display: false,
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            title: (tooltipItem) => tooltipItem[0].dataset?.label,
            label: (tooltipItem) => {
              const startDate = new Date(`${tooltipItem.raw[0]}T00:00:00`).toLocaleDateString(
                'es-ES',
                {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                },
              );
              const endDate = new Date(`${tooltipItem.raw[1]}T00:00:00`).toLocaleDateString(
                'es-ES',
                {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                },
              );
              return endDate === 'Invalid Date'
                ? `${startDate} - Long Term`
                : `${startDate} - ${endDate}`;
            },
          },
          titleFont: { size: 14, family: 'Roboto' },
          bodyFont: { size: 12, family: 'Roboto' },
          footerFont: { size: 12, family: 'Roboto' },
        },
      },
      scales: {
        x: {
          type: 'time' as const,
          time: {
            unit: 'month' as const,
          },
          min: new Date().toISOString(),
          max:
            chartMaxDate ||
            new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
          bounds: 'ticks',
          stacked: false,
          ticks: {
            callback: (value) => {
              const date = new Date(value);
              const month = new Intl.DateTimeFormat('es-ES', { month: 'long' })
                .format(date)
                .slice(0, 3);
              const year = date.getFullYear().toString();
              const formattedDate = `${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
              return formattedDate;
            },
          },
        },
        y: {
          stacked: false,
        },
      },
    };

    return {
      chartJSData,
      currentChartJSData,
      chartJSConfig,
      resetFilters,
    };
  }, [
    dataList,
    chartMaxDate,
    page,
    rowsPerPage,
    selectedRole,
    selectedAvailability,
    filters.search,
  ]);

  ChartJS.register(CategoryScale, TimeScale, BarElement, Title, Tooltip, Legend);

  if (loading) return <Loader />;
  return (
    <div className={styles.availabilityContainer}>
      <div className={styles.welcomeMessage}>
        <Typography variant="h1">Disponibilidad general</Typography>
      </div>
      <div className={styles.searchbar}>
        <SearchBar
          setFilter={(stringValue) => setFilters({ ...filters, search: stringValue })}
          filter={filters.search}
          keywords="Nombre o Rol de empleado"
        />
      </div>
      <div className={styles.checkboxInput} data-testid="filters-container">
        <select
          className={styles.filterDropdown}
          onChange={(e) => {
            setFilters({ ...filters, role: e.target.value });
            setSelectedRole(e.target.value);
          }}
        >
          <option value={''} selected={filters.role === ''} className={styles.option}>
            {'Rol'}
          </option>
          {roleCheckboxOptions?.map((item) => (
            <option key={item.value} value={item.value} className={styles.option}>
              {item.label}
            </option>
          ))}
        </select>
        <div className={styles.filterButtons}>
          <select
            className={styles.filterDropdown}
            onChange={(e) => {
              setFilters({ ...filters, availability: e.target.value });
              setSelectedAvailability(e.target.value);
            }}
          >
            <option value={''} selected={filters.availability === ''} className={styles.option}>
              {'Disponibilidad'}
            </option>
            {availabilityOptions?.map((item) => (
              <option key={item.value} value={item.value} className={styles.option}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <select className={styles.filterDropdown} onChange={handleMonthFilters}>
          <option value={'twelve'} selected={filters.period === ''} className={styles.option}>
            {'12 meses'}
          </option>
          <option value={'six'} className={styles.option}>
            {'6 meses'}
          </option>
          <option value={'three'} className={styles.option}>
            {'3 meses'}
          </option>
        </select>
        <div className={styles.filterButtons}>
          <Button
            materialVariant={Variant.TEXT}
            onClick={dataMemo.resetFilters}
            label={'Resetear filtros'}
            testId={'reset-filter'}
          />
        </div>
      </div>
      {dataMemo.currentChartJSData?.labels?.length > 0 ? (
        <>
          <div className={styles.graphReferences}>
            <div>
              <div className={styles.projectsRef} />
              <p>Proyectos</p>
            </div>
            <div>
              <div className={styles.absencesRef} />
              <p>Ausencias</p>
            </div>
          </div>
          <Bar options={dataMemo.chartJSConfig} data={dataMemo.currentChartJSData} />
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filteredData.labels ? filteredData.labels.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={'Filas por página'}
          />
        </>
      ) : (
        <NoResultsMatched />
      )}
      <div className={styles.backButton}>
        <Button
          materialVariant={Variant.OUTLINED}
          onClick={() => {
            navigate(`${UiRoutes.ADMIN_AM}${UiRoutes.EMPLOYEES}`);
          }}
          label={'Volver'}
          testId={'back-button'}
        />
      </div>
    </div>
  );
};

export default GeneralAvailability;
