import { projectHeadersEmp } from '../constants';
import { FormattedProjects } from '../types';
import styles from './projectList.module.css';
import { IconButton } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { FaXmark } from 'react-icons/fa6';
import AsideTable from 'src/components/shared/ui/asideTable';
import { ModalIDs, closeModal, openModal } from 'src/redux/modals/modalsSlice';
import { useAppDispatch } from 'src/redux/store';

type Props = {
  formattedProjects: FormattedProjects[];
  setMemberId: Dispatch<SetStateAction<string>>;
  setProject: Dispatch<SetStateAction<{ id: string; name: string }>>;
};

function ProjectList(props: Props) {
  const dispatch = useAppDispatch();

  const deactivateMember = (id: string) => {
    props.setMemberId(id);
    const project = props.formattedProjects.find((p) => p.memberId === id);
    props.setProject({ id: project.id, name: project.name });
    dispatch(openModal(ModalIDs.EMPLOYEE_DEACTIVATE_MEMBER));
  };

  return (
    <div className={styles.container}>
      <div className={styles.crossIcon}>
        <IconButton aria-label="close" onClick={() => dispatch(closeModal())}>
          <FaXmark color={'#373867'} fontSize={'20px'} />
        </IconButton>
      </div>
      <div className={styles.modalTableContainer}>
        <AsideTable
          title="Lista de Proyectos"
          headers={projectHeadersEmp}
          testId="aside-table"
          dataList={props.formattedProjects}
          showHistoryModal={() => dispatch(openModal(ModalIDs.EMPLOYEE_ADD_MEMBER))}
          isProjectsTable
          setMemberId={props.setMemberId}
          deactivateMember={deactivateMember}
        />
      </div>
    </div>
  );
}

export default ProjectList;
