import { FaUserGroup, FaRegFileLines, FaRegIdCard, FaRocket } from 'react-icons/fa6';
import { SeniorityType } from 'src/redux/types';
import { PotentialRole, ProjectRoleType } from 'src/types';

export const WeekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const roles = [
  { value: 'QA', label: 'QA' },
  { value: 'DEV', label: 'DEV' },
  { value: 'UX/UI', label: 'UX/UI' },
  { value: 'PM', label: 'PM' },
  { value: 'TL', label: 'TL' },
  { value: 'QA L', label: 'QA L' },
  { value: 'UX/UI L', label: 'UX/UI L' },
];

export const seniorityOptions = [
  { value: SeniorityType.TRAINEE, label: SeniorityType.TRAINEE },
  { value: SeniorityType.JUNIOR, label: SeniorityType.JUNIOR },
  { value: SeniorityType.SEMI_SENIOR, label: SeniorityType.SEMI_SENIOR },
  { value: SeniorityType.SENIOR, label: SeniorityType.SENIOR },
];

export const years = [
  { value: 2023, label: 2023 },
  { value: 2024, label: 2024 },
  { value: 2025, label: 2025 },
  { value: 2026, label: 2026 },
  { value: 2027, label: 2027 },
  { value: 2028, label: 2028 },
  { value: 2029, label: 2029 },
  { value: 2030, label: 2030 },
];

export enum ApiRoutes {
  USER = '/users',
  EMPLOYEE = '/employees',
  AVAILABILITY = '/availability',
  PERFORMANCE_FEEDBACK = '/performanceFeedback',
  PROJECTS = '/projects',
  CLIENT = '/clients',
  AUTH = '/auth',
  MEMBER = '/members',
  NOTIFICATION = '/notifications',
  MOVEMENTS = '/movements',
  SKILLS = '/skills',
  SALARYRANGE = '/salaryRange',
  CRONJOB = '/cron',
  GROWTH_PLAN = '/growthPlan',
  ABSENCE = '/absence',
  COMPANY = '/company',
}

//Navbar

export const adminNavbar = [
  { path: '/admin/home', name: 'DASHBOARD', icon: FaRocket },
  { path: '/admin/employees', name: 'EMPLEADOS', icon: FaUserGroup },
  { path: '/admin/projects', name: 'PROYECTOS', icon: FaRegFileLines },
  { path: '/admin/clients', name: 'CLIENTES', icon: FaRegIdCard },
];

export const employeeNavbar = [{ path: '/employee/profile', name: 'PERFIL', icon: FaUserGroup }];

export const navbarItems = {
  ADMIN_AM: adminNavbar,
  EMPLOYEE: employeeNavbar,
};

export const formattedRoleType = {
  SUPER_ADMIN: 'Super Admin',
  ADMIN_AM: 'Admin AM',
  EMPLOYEE: 'Empleado',
  ACCOUNTANCY_AM: 'Accountancy AM',
  ACCOUNTANCY_AL: 'Accountancy AL',
  ACCOUNTANCY_AD: 'Accountancy AD',
  ALL: '',
};

export enum AccessRoleType {
  MANAGER = 'MANAGER',
  SUPER_ADMIN = 'SUPER_ADMIN',
  EMPLOYEE = 'EMPLOYEE',
  ADMIN_AM = 'ADMIN_AM',
  ACCOUNTANCY_AM = 'ACCOUNTANCY_AM',
  ACCOUNTANCY_AL = 'ACCOUNTANCY_AL',
  ACCOUNTANCY_AD = 'ACCOUNTANCY_AD',
}

export enum Orientations {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

//ROUTES

export enum UiRoutes {
  LOGIN = '/login',
  STORYBOOK = '/storybook',
  NOT_ALLOWED = '/not-allowed',
  ADMIN_AM = '/admin',
  SUPER_ADMIN = '/super-admin/*',
  EMPLOYEE = 'employee',
  EMPLOYEES = '/employees',
  EDIT_EMPLOYEES = '/employees/edit',
  AVAILABILITY = '/availability',
  PERFORMANCE_FEEDBACK = '/performance-feedback',
  PROFILE = '/profile',
  EDIT_PROFILE = '/profile/edit',
  PROJECTS = '/projects',
  PROJECTS_FORM = '/projects/form',
  CLIENTS = '/clients',
  CLIENTS_FORM = '/clients/form',
}

//MODALS

export enum Modals {
  DRAG_ZONE = 'drag-zone',
  USERS_FORM = 'users-form',
}

//USERS

export enum UserHeaders {
  ROL = 'rol',
  EMAIL = 'email',
  NOMBRE = 'nombre',
  APELLIDO = 'apellido',
  DNI = 'dni',
  DIRECCION = 'direccion',
  TELEFONO = 'telefono',
  CIUDAD = 'ciudad',
  FECHA_DE_NACIMIENTO = 'fecha de nacimiento',
  COMPANY = 'company',
}

// URL
export const customImage =
  'https://cdn-icons-png.flaticon.com/512/727/727399.png?w=740&t=st=1682207755~exp=1682208355~hmac=e46a7bb671b0985b31995c86289a944ac7de9ab5e35fa508b8df5e2ca13c9bee';

export const IMAGE_URL = process.env.REACT_APP_AWS_IMAGE_URL;

export const videoTutorial = 'https://www.youtube.com/embed/IV2DtrTpGTo';

export const dropdownData: PotentialRole[] = [
  { label: 'TL', value: ProjectRoleType.TL },
  { label: 'PM', value: ProjectRoleType.PM },
  { label: 'DEV', value: ProjectRoleType.DEV },
  { label: 'QA', value: ProjectRoleType.QA },
  { label: 'UX/UI', value: ProjectRoleType.UX_UI },
  { label: 'QA Leader', value: ProjectRoleType.QA_L },
  { label: 'UX/UI Leader', value: ProjectRoleType.UX_UI_L },
];
