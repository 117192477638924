import styles from './clients.module.css';
import { clientFilterOptions, header } from './constants';
import { ClientFilters, ClientsData, SearchClientData } from './types';
import { Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { FaPen } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import EmptyDataHandler from 'src/components/shared/common/emptyDataHandler';
import NoResultsMatched from 'src/components/shared/common/noResultsMatched';
import { Button, CannotDelete, ConfirmationMessage, Modal, Table } from 'src/components/shared/ui';
import ActivateForm from 'src/components/shared/ui/activateForm';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import SearchBar from 'src/components/shared/ui/searchbar';
import { UiRoutes } from 'src/constants';
import { clearSelectedClient } from 'src/redux/client/actions';
import { deleteClient, editClient, getClients } from 'src/redux/client/thunks';
import { ModalIDs, closeModal, openModal } from 'src/redux/modals/modalsSlice';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';
import { ErrorType } from 'src/redux/types';
import 'src/redux/ui/actions';
import { closeConfirmationModal, openConfirmationModal } from 'src/redux/ui/actions';
import { AppDispatch, Resources } from 'src/types';
import { formattedTableData } from 'src/utils/formatters';
import { getFilteredDataByTextSearch } from 'src/utils/getFilteredDataByTextSearch';
import { tableRowDataFormatterComponent } from 'src/utils/tableRowDataFormatter';

const filterData = (list, filters: ClientFilters) => {
  let filterDataList = list.filter((item) => item.active === filters.isActive);

  if (filters.search) {
    filterDataList = getFilteredDataByTextSearch(
      filterDataList,
      clientFilterOptions,
      filters.search,
    );
  }

  return filterDataList;
};

const Clients = () => {
  const dispatch: AppDispatch<null> = useAppDispatch();
  const navigate = useNavigate();

  const [row, setRow] = React.useState({} as ClientsData);
  const clientsList = useAppSelector((state: RootState) => state.client?.list);

  const clientError = useAppSelector((state: RootState) => state.client?.error);
  const showConfirmModal = useAppSelector((state: RootState) => state.ui.showConfirmModal);
  const activeModal = useAppSelector((state: RootState) => state.modals.activeModal);

  const [dataList, setDataList] = useState([]);
  const [filters, setFilters] = React.useState<ClientFilters>({
    isActive: true,
    search: '',
  });
  const confirmationTitle = filters.isActive ? 'Desactivar cliente' : 'Activar cliente';
  const confirmationDescription = filters.isActive
    ? `¿Desea desactivar al cliente ${row.name}?`
    : `¿Desea activar al cliente ${row.name}?`;

  const [checked, setChecked] = React.useState(false);

  const activeClientsList = useMemo(() => {
    const mappedClients = clientsList.reduce((acc, item) => {
      acc.push({
        _id: item?._id,
        name: item?.name,
        projects: formattedTableData(item.projects, 'projectName'),
        clientContact: item?.clientContact?.name,
        email: item?.clientContact?.email,
        localContact: item?.localContact?.name,
        localEmail: item?.localContact?.name,
        relationshipEnd: item?.relationshipEnd?.toString(),
        relationshipStart: item?.relationshipStart?.toString(),
        notes: item?.notes,
        active: item?.isActive,
      });
      return acc;
    }, []);
    const filteredData = filterData(mappedClients, filters);
    return filteredData;
  }, [clientsList, filters.isActive, filters.search]);

  const selectedClient = clientsList.find((client) => client._id === row._id);

  const hasProjects = useMemo(() => {
    return selectedClient?.isActive && selectedClient?.projects.some((project) => project.isActive);
  }, [row]);

  useEffect(() => {
    dispatch(getClients());
    dispatch(clearSelectedClient());
  }, []);

  useEffect(() => {
    const clientsToDisplay = tableRowDataFormatterComponent(activeClientsList, 'projects');
    setDataList(clientsToDisplay);
  }, [clientsList, filters.isActive, filters.search]);

  const handleEdit = (row) => {
    navigate(`${UiRoutes.ADMIN_AM}${UiRoutes.CLIENTS_FORM}/${row._id}`);
  };

  const handleActivate = async (data) => {
    await dispatch(editClient(data));
    dispatch(closeConfirmationModal());
    dispatch(closeModal());
  };

  const handleDelete = async (id) => {
    await dispatch(deleteClient(id));
    dispatch(closeConfirmationModal());
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const buttonsArray = filters.isActive
    ? [
        {
          active: true,
          testId: 'edit-button',
          variant: Variant.CONTAINED,
          onClick: (row) => {
            return handleEdit(row);
          },
          icon: <FaPen color={'#373867'} fontSize={'20px'} cursor={'pointer'} />,
          tooltipLabel: 'Editar',
        },
        {
          active: true,
          label: 'Desactivar',
          testId: 'deactivate-button',
          variant: Variant.TEXT,
          onClick: (data) => {
            dispatch(openConfirmationModal());
            setRow(data);
          },
        },
      ]
    : [
        {
          active: true,
          label: 'Activar',
          testId: 'activate-button',
          variant: Variant.TEXT,
          onClick: (data) => {
            dispatch(openModal(ModalIDs.ACTIVATE_PROJECT));
            setRow(data);
          },
        },
      ];

  const showErrorMessage =
    clientError?.errorType === ErrorType.NETWORK_ERROR || !clientsList.length;

  return showErrorMessage ? (
    <EmptyDataHandler
      resource={Resources.Clientes}
      handleReload={() => handleNavigation(0)}
      handleAdd={() => handleNavigation(`${UiRoutes.ADMIN_AM}${UiRoutes.CLIENTS_FORM}`)}
      error={clientError}
    />
  ) : (
    <div className={styles.tableContainer}>
      <div className={styles.welcomeMessage}>
        <Typography variant="h1">Clientes</Typography>
      </div>
      <div className={styles.inputsContainer}>
        <div className={styles.searchBar}>
          <SearchBar<SearchClientData>
            setFilter={(stringValue) => setFilters({ ...filters, search: stringValue })}
            keywords="Cliente, Proyectos o Contacto"
          />
        </div>
        <div className={styles.addClientButton}>
          <Button
            materialVariant={Variant.CONTAINED}
            onClick={() => {
              navigate(`${UiRoutes.ADMIN_AM}${UiRoutes.CLIENTS_FORM}`);
            }}
            label={'+ Agregar cliente'}
            testId={'add-client-button'}
            styles={'addButton'}
          />
        </div>
      </div>
      <div className={styles.checkboxInput}>
        <div className={`${checked ? styles.filterButtonsPressed : styles.filterButtons}`}>
          <Button
            materialVariant={checked ? Variant.CONTAINED : Variant.TEXT}
            onClick={() => {
              setFilters({ ...filters, isActive: !filters.isActive });
              setChecked(!checked);
            }}
            label={'Inactivos'}
            testId={'inactive-button'}
            color={'info'}
          />
        </div>
        <div className={styles.filterButtons}>
          <Button
            materialVariant={Variant.TEXT}
            onClick={() => {
              setFilters({ isActive: true, search: '' });
              setChecked(false);
            }}
            label={'Resetear filtros'}
            testId={'reset-filter'}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        {dataList?.length ? (
          <Table<ClientsData>
            showButtons
            testId={'client-table'}
            headers={header}
            value={dataList}
            setDataList={setDataList}
            buttons={buttonsArray}
            isActive={filters.isActive}
            filters={filters}
          />
        ) : (
          <NoResultsMatched />
        )}
      </div>
      <Modal
        testId="delete-modal"
        styles={styles.modal}
        isOpen={showConfirmModal}
        onClose={() => dispatch(closeConfirmationModal())}
      >
        {hasProjects ? (
          <CannotDelete
            testId="client-not-delete"
            handleClose={() => dispatch(closeConfirmationModal())}
          />
        ) : (
          <ConfirmationMessage
            description={confirmationDescription}
            title={confirmationTitle}
            handleConfirm={() => handleDelete(row._id)}
            handleClose={() => dispatch(closeConfirmationModal())}
          />
        )}
      </Modal>
      {activeModal ? (
        <Modal testId={'activate-project-modal'} id={ModalIDs.ACTIVATE_PROJECT}>
          <ActivateForm
            name={row.name}
            id={row._id}
            entityStartDate={selectedClient?.relationshipStart}
            entityEndDate={selectedClient?.relationshipEnd}
            handleActivate={handleActivate}
            title={confirmationTitle}
            description={confirmationDescription}
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default Clients;
