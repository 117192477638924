import styles from './comparisonCard.module.css';
import { Avatar } from '@mui/material';
import { format, differenceInDays } from 'date-fns';
import React from 'react';
import { IMAGE_URL } from 'src/constants';
import { RootState, useAppSelector } from 'src/redux/store';
import { formatSkills } from 'src/utils/formatSkills';

const ComparisonCard = ({
  id,
  shadowColor = 'green',
}: {
  id: string;
  shadowColor?: 'green' | 'blue';
}) => {
  const employeeList = useAppSelector((state: RootState) => state.employee?.list);

  const employee = employeeList.find((e) => e._id === id);

  const currentProjects = employee.currentProjects
    ?.map((p) => {
      return p.project.projectName;
    })
    .join(' - ');
  const skills = formatSkills(employee.skills, ' - ');
  const memberHistory = employee.memberHistory.map((mh) => mh.project.projectName).join(' - ');

  const data = {
    photoType: employee.user.photoType,
    name: employee.user.firstName + ' ' + employee.user.lastName,
    currentProjects,
    role: employee.role,
    rank: employee.rank,
    skills,
    memberHistory,
    growthPlan: employee.growthPlan.slice(-1)[0]?.description || '',
    ...(employee.closestAvailableDate && {
      closestAvailableDate: new Date(employee.closestAvailableDate),
    }),
  };

  const shadowColorVar = shadowColor === 'blue' ? '--radioactive-green' : '--radium-blue';

  return (
    <div
      className={styles.container}
      style={{
        border: `1px solid var(${shadowColorVar})`,
        WebkitBoxShadow: `0 0 20px -6px var(${shadowColorVar})`,
        MozBoxShadow: `0 0 20px -6px var(${shadowColorVar})`,
        boxShadow: `0 0 20px -6px var(${shadowColorVar})`,
      }}
    >
      <div className={styles.header}>
        <div className={styles.name}>
          <div className={styles.avatar}>
            <Avatar
              src={`${IMAGE_URL}/${id}.${data?.photoType}?${new Date().getTime()}`}
              sx={{ width: 30, height: 30 }}
            />
          </div>
          <span>{data.name}</span>
        </div>
        {data.currentProjects ? (
          <>
            <div className={styles.availability}>
              <span>Actualmente en {data.currentProjects}</span>
              {data.closestAvailableDate && (
                <span>Se libera el {format(data.closestAvailableDate, 'dd/MM/yyyy')}</span>
              )}
            </div>
            {data.closestAvailableDate && (
              <span>
                Quedan {differenceInDays(data.closestAvailableDate, new Date())} días para que
                finalice.
              </span>
            )}
          </>
        ) : (
          <div>Actualmente sin proyecto</div>
        )}
      </div>
      <div className={styles.body}>
        <div className={styles.info}>
          <span>Rol</span>
          <span>{data.role}</span>
        </div>
        <div className={styles.info}>
          <span>Nivel</span>
          <span>{data.rank}</span>
        </div>
        <div className={styles.info}>
          <span>skills</span>
          <span>{data.skills}</span>
        </div>
        <div className={styles.info}>
          <span>Historial de proyectos</span>
          <span>{data.memberHistory}</span>
        </div>
        <div className={styles.info}>
          <span>Plan de crecimiento</span>
          <span>{data.growthPlan}</span>
        </div>
      </div>
    </div>
  );
};

export default ComparisonCard;
