import styles from './cv.module.css';
import { CVProps } from './types';
import { Avatar } from '@mui/material';
import React from 'react';

const CV = (props: CVProps): JSX.Element => {
  const { data, imgSource } = props;
  return (
    <div className={styles.cvContainer}>
      <div className={styles.header}>
        <div className={styles.title}>
          <h1>
            {data?.firstname.toUpperCase()} {data?.lastName.toUpperCase()}
          </h1>
          <h2>Radium Rocket</h2>
        </div>
        <div className={styles.images}>
          <div className={styles.rrLogo}>
            <img src={`/assets/images/rocket.png`} alt="radium logo" />
          </div>
          <Avatar variant="rounded" className={styles.avatar} src={imgSource} />
        </div>
      </div>
      <div className={styles.profileInfo}>
        <h3 className={styles.h3}>PERFIL</h3>
        <p>
          {data?.firstname} {data?.lastName} es un desarrollador de software que se inició en el
          campo de la fábrica de software a una edad temprana. Cuando era niño descubrió su pasión
          por el desarrollo de software y desde entonces ha estado recorriendo el camino del
          conocimiento de la codificación. Siempre en busca de retos que lo desafíen tanto a nivel
          profesional como personal. Aunque comenzó como programador autodidacta, aprendió a
          trabajar en equipos colaborativos y ahora se ha convertido en una pieza clave en cada
          equipo en el que participa. Le encanta involucrarse en proyectos organizados bajo
          metodologías ágiles y donde no falta la comunicación. Maneja perfectamente el frontend con
          React, y está ansioso por aprender y aplicar todas las tecnologías de punta que puedan
          significar una mejora en la calidad del producto.
        </p>
      </div>
      <h3 className={styles.h3}>PRINCIPALES TECNOLOGIAS</h3>
      <div className={styles.list}>
        {data?.skills.map((skill, idx) => (
          <div className={styles.listItem} key={idx}>
            <span className={styles.circle}></span>
            <h4 className={styles.liText}>{skill}</h4>
          </div>
        ))}
      </div>
      <div className={styles.footerContainer}>
        <div>
          <h5>Radium</h5>
          <h6>Rocket</h6>
        </div>
      </div>
    </div>
  );
};

export default CV;
