import { InputProps } from './types';
import { TextField } from '@mui/material';
import React from 'react';
import { FieldValues, useController } from 'react-hook-form';

const Input = <Form extends FieldValues>(props: InputProps<Form>): JSX.Element => {
  const {
    label,
    type,
    variant,
    styles,
    testId,
    size,
    multiline,
    rows,
    color = 'info',
    disabled,
    classes,
    onBlur,
    onFocus,
    inputProps,
    InputProps,
    placeholder,
    handleOnChange,
    stylesObject,
    helperText,
  } = props;
  const {
    field: { onChange, onBlur: useFormBlur, ...rest },
    fieldState: { error },
  } = useController(props);

  const cleanCharacters = (value) => {
    if (!value) return;
    for (let i = 0; i < value.length; i++) {
      if (value.charAt(i) != '0') {
        const res = value.substr(i);
        return res;
      }
    }
    return value;
  };

  return (
    <TextField
      {...rest}
      value={type === 'number' ? cleanCharacters(rest.value) : rest.value}
      data-testid={testId}
      className={styles}
      label={label}
      helperText={helperText ? helperText : !disabled && (error?.message || ' ')}
      type={type}
      fullWidth
      sx={stylesObject}
      size={size}
      onBlur={onBlur ? onBlur : useFormBlur}
      onFocus={onFocus}
      classes={classes}
      error={Boolean(error)}
      variant={variant}
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      color={color}
      inputProps={inputProps}
      InputProps={InputProps ? InputProps : {}}
      placeholder={placeholder}
      onKeyDown={(e) => {
        if (
          (type === 'number' &&
            ![
              '0',
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              'Backspace',
              'Delete',
              'Supr',
              'ArrowLeft',
              'ArrowRight',
              'Tab',
              ',',
              '.',
            ].includes(e.key)) ||
          (placeholder === 'Horas' &&
            ![
              '0',
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              ':',
              'Backspace',
              'Delete',
              'Supr',
              'ArrowLeft',
              'ArrowRight',
              'Tab',
            ].includes(e.key))
        ) {
          e.preventDefault();
        }
      }}
      onChange={(e) => {
        onChange(e);
        handleOnChange && handleOnChange(e);
      }}
    />
  );
};

export default Input;
