import CompleteForm from './completeForm';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NoResultsMatched from 'src/components/shared/common/noResultsMatched';
import { Loader } from 'src/components/shared/ui';
import { cleanSelectedEmployee } from 'src/redux/employee/actions';
import { getEmployeeById } from 'src/redux/employee/thunk';
import { useAppDispatch, useAppSelector } from 'src/redux/store';

function EmployeeEdit() {
  const { isLoading, error, selectedEmployee } = useAppSelector((state) => state.employee);

  const params = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (params.id) {
      dispatch(getEmployeeById(params.id));
    } else {
      console.error('No employee id provided');
    }
    return () => {
      dispatch(cleanSelectedEmployee());
    };
  }, []);

  if (isLoading || Object.keys(selectedEmployee).length === 0) return <Loader />;

  if (error) {
    console.error('A habido un error, no se ha encontrado al empleado.', error);
    return <NoResultsMatched />;
  }

  return selectedEmployee && <CompleteForm />;
}

export default EmployeeEdit;
