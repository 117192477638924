import { labelData } from '../../../employeeList/growthModal/utils';
import styles from './salaryChart.module.css';
import React from 'react';
import { Chart } from 'react-google-charts';
import { RootState, useAppSelector } from 'src/redux/store';

const mockedData = [
  {
    date: '2022-12-01T12:00:00Z',
    salary: 10000,
  },
  {
    date: '2023-01-01T12:00:00Z',
    salary: 10000,
  },
  {
    date: '2023-03-01T12:00:00Z',
    salary: 20000,
  },
  {
    date: '2023-06-15T12:00:00Z',
    salary: 30000,
  },
  {
    date: '2023-09-13T12:00:00Z',
    salary: 30000,
  },
  {
    date: '2023-12-20T12:00:00Z',
    salary: 50000,
  },
  {
    date: '2024-01-10T12:00:00Z',
    salary: 50000,
  },
  {
    date: '2024-03-20T12:00:00Z',
    salary: 70000,
  },
];

type DateValueFormat = {
  f: string;
  v: string;
};

const SalaryChartModal = () => {
  const selectedEmployee = useAppSelector((state: RootState) => state.employee?.selectedEmployee);
  const chartData = React.useMemo(() => {
    const filteredData = mockedData.filter((item) => {
      const itemDate = new Date(item.date);
      const futureDate = new Date();
      futureDate.setMonth(futureDate.getMonth() - 12);
      return itemDate <= new Date() && itemDate >= futureDate;
    });
    const resultData = filteredData.reduce(
      (acc: [[DateValueFormat, number, string]], curr, index) => {
        const value = index > 0 ? acc[index - 1][0]?.v : '00-00';
        const { shouldAddYear, monthAndYear, monthName } = labelData(curr.date, value, acc);
        return [
          ...acc,
          [
            {
              v: monthAndYear,
              f: shouldAddYear ? `${monthName}\n${monthAndYear.split('-')[0]}` : monthName,
            },
            curr.salary,
            `Fecha: ${new Date(curr.date).toLocaleDateString()}\nSalario: $${curr.salary}`,
          ],
        ];
      },
      [[{ v: '', f: '' }, 0, '']],
    );
    resultData.shift();
    return [['Fecha', 'Salario', { role: 'tooltip' }], ...resultData];
  }, [mockedData]);

  return (
    <>
      <div className={styles.title}>
        Crecimiento salarial de {selectedEmployee.user.firstName} {selectedEmployee.user.lastName}
      </div>
      <Chart
        chartType="LineChart"
        data={chartData}
        options={{
          height: 350,
          width: 750,
          interpolateNulls: true,
          legend: 'top',
          hAxis: {
            title: 'Fecha',
            skipNull: true,
          },
          vAxis: {
            title: 'Nivel',
          },
          isStacked: true,
        }}
      />
    </>
  );
};

export default SalaryChartModal;
