export const entities = [
  { label: 'Empleado', value: 'EMPLOYEE' },
  { label: 'Skill', value: 'SKILL' },
  { label: 'Cliente', value: 'CLIENT' },
  { label: 'Proyecto', value: 'PROJECT' },
];

export const notificationHomeTypes = [
  { label: 'Automáticas', value: 'AUTO' },
  { label: 'Personalizadas', value: 'CUSTOM' },
];

export const notificationsFilterOptions = [
  'projectName',
  'employeeName',
  'clientName',
  'customMessage',
];
