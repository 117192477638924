import styles from './performanceFeedback.module.css';
import { PerformanceFeedbackFormValues } from './types';
import performanceFeedbackValidations from './validations';
import { joiResolver } from '@hookform/resolvers/joi';
import React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  ConfirmationMessage,
  Dropdown,
  Loader,
  Modal,
  TextInput,
} from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import api from 'src/config/api';
import { ApiRoutes, UiRoutes } from 'src/constants';
import { RootState, useAppSelector } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toastSlice';
import { closeConfirmationModal, openConfirmationModal } from 'src/redux/ui/actions';
import { ProjectRoleType } from 'src/types';

//TODO: Refactor to use the new roles, ranks and area of expertise.
const PerformanceFeedback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [performanceFeedback, setPerformanceFeedback] =
    React.useState<PerformanceFeedbackFormValues>(null);
  const showConfirmModal = useAppSelector((state: RootState) => state.ui.showConfirmModal);
  const selectedEmployee = useAppSelector((state: RootState) =>
    state.employee.list.find((emp) => emp._id === params.id),
  );

  useEffect(() => {
    const fetchData = async () => {
      await api.get(`${ApiRoutes.PERFORMANCE_FEEDBACK}/?employee=${params.id}`).then((res) => {
        const array = res.data.data;
        const lastFeedback = array[array.length - 1];
        setPerformanceFeedback(lastFeedback);
        setIsLoading(false);
      });
    };
    fetchData();
  }, []);

  const {
    formState: { isDirty, isValidating, isValid },
    handleSubmit,
    control,
    reset,
    watch,
  } = useForm<PerformanceFeedbackFormValues>({
    defaultValues: {
      employee: params.id,
      previousRole: performanceFeedback?.previousRole,
      newRole: '',
      previousLevel: performanceFeedback?.previousLevel,
      newLevel: '',
      notes: performanceFeedback?.notes || '',
      hardSkills: { technicalLevel: '', toolsAndLanguages: '', workQuality: '' },
      softSkills: {
        effectiveCommunication: '',
        criticalThinking: '',
        teamWork: '',
        conflictsResolution: '',
        empathy: '',
        companyCompromise: '',
        clientAccomplishment: '',
        teachesAndLearns: '',
        proactivity: '',
        autonomy: '',
      },
    },
    mode: 'onBlur',
    resolver: joiResolver(performanceFeedbackValidations),
  });

  const formChanged = (!isDirty && !isValidating) || !isValid;

  useEffect(() => {
    if (performanceFeedback) {
      reset({
        employee: params.id,
        previousRole: performanceFeedback.previousRole,
        newRole: '',
        previousLevel: performanceFeedback.previousLevel,
        newLevel: '',
        notes: performanceFeedback.notes || '',
        hardSkills: { technicalLevel: '', toolsAndLanguages: '', workQuality: '' },
        softSkills: {
          effectiveCommunication: '',
          criticalThinking: '',
          teamWork: '',
          conflictsResolution: '',
          empathy: '',
          companyCompromise: '',
          clientAccomplishment: '',
          teachesAndLearns: '',
          proactivity: '',
          autonomy: '',
        },
      });
    }
  }, [performanceFeedback]);

  const onSubmit = async (data) => {
    const { previousRole: _previousRole, previousLevel: _previousLevel, ...dataRest } = data;

    try {
      await api.post(`${ApiRoutes.PERFORMANCE_FEEDBACK}`, dataRest);
      dispatch(openToast({ message: 'Feedback de desempeño agregado con exito', error: false }));
      dispatch(closeConfirmationModal());
      navigate(`${UiRoutes.ADMIN_AM}${UiRoutes.EDIT_EMPLOYEES}/${params.id}`);
    } catch (error) {
      dispatch(openToast({ message: error.message, error: true }));
    }
  };

  const newRole = [
    { value: 'DEV', label: ProjectRoleType.DEV },
    { value: 'QA', label: ProjectRoleType.QA },
    { value: 'UX/UI', label: ProjectRoleType.UX_UI },
    { value: 'QA L', label: ProjectRoleType.QA_L },
    { value: 'UX/UI L', label: ProjectRoleType.UX_UI_L },
    { value: 'PM', label: ProjectRoleType.PM },
    { value: 'TL', label: ProjectRoleType.TL },
  ];

  const newLevel = [];

  const newRoleWatch = watch('newRole');

  switch (newRoleWatch) {
    case 'DEV':
    case 'UX/UI':
    case 'QA':
      for (let i = 1; i <= 10; i++) {
        const element = { value: i, label: i };
        newLevel.push(element);
      }
      break;
    case 'TL':
    case 'UX/UI L':
    case 'QA L':
      for (let i = 1; i <= 3; i++) {
        const element = { value: i, label: i };
        newLevel.push(element);
      }
      break;
    case 'PM':
      for (let i = 1; i <= 5; i++) {
        const element = { value: i, label: i };
        newLevel.push(element);
      }
      break;
    default:
      break;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className={styles.container} data-testid="performance-container">
        <div className={styles.welcomeMessage}>
          <div>
            Nuevo feedback de desempeño para {performanceFeedback?.userData?.firstName}{' '}
            {performanceFeedback?.userData?.lastName}
          </div>
        </div>
        <div className={styles.feedbackContainer}>
          <div className={styles.leftSideContainer} data-testid="left-side-container">
            <div className={styles.titles}>Categoría actual</div>
            <div className={styles.roleLevel}>
              <span>
                <p>{performanceFeedback ? performanceFeedback?.newRole : selectedEmployee.role}</p>
              </span>
              <span>
                <p>{performanceFeedback ? performanceFeedback?.newLevel : selectedEmployee.rank}</p>
              </span>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.titles}>Categoría sugerida</div>
              <div className={styles.elementContainer}>
                <Dropdown
                  control={control}
                  testId={'new-role-dropdown'}
                  label="New Role"
                  name="newRole"
                  options={newRole}
                  fullWidth
                />
                <Dropdown
                  control={control}
                  testId={'new-level-dropdown'}
                  label="New Level"
                  name="newLevel"
                  options={newLevel}
                  fullWidth
                  disabled={!newRoleWatch}
                />
              </div>
              <div className={styles.titles}>Feedback para el empleado</div>
              <div className={styles.textAreaInput}>
                <TextInput
                  control={control}
                  testId={'notes-input'}
                  label="Notas"
                  name="notes"
                  type={'text'}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={5}
                />
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  testId="confirmButton"
                  materialVariant={Variant.CONTAINED}
                  onClick={() => dispatch(openConfirmationModal())}
                  label="Confirmar"
                  disabled={formChanged}
                />
                <Button
                  testId="cancel-button"
                  materialVariant={Variant.OUTLINED}
                  onClick={() =>
                    navigate(`${UiRoutes.ADMIN_AM}${UiRoutes.EDIT_EMPLOYEES}/${params.id}`)
                  }
                  label="Cancelar"
                />
              </div>
            </form>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.skillCard} data-testid="soft-skills-container">
              <div className={styles.cardHead}>
                <p className={styles.cardHeadLeft}>Habilidades Blandas</p>
                <p className={styles.cardHeadRight}>Nota previa</p>
              </div>
              <form>
                <div className={styles.formElements}>
                  <p className={styles.fixedWidth}>Comunicación efectiva</p>
                  <div className={styles.inputForm}>
                    <TextInput
                      control={control}
                      testId={'hard-skills-input'}
                      name="softSkills.effectiveCommunication"
                      type={'number'}
                      variant="outlined"
                    />
                  </div>
                  <p>{performanceFeedback?.softSkills.effectiveCommunication || 0}</p>
                </div>
                <div className={styles.formElements}>
                  <p className={styles.fixedWidth}>Pensamiento crítico</p>
                  <div className={styles.inputForm}>
                    <TextInput
                      control={control}
                      testId={'hard-skills-input'}
                      name="softSkills.criticalThinking"
                      type={'number'}
                      variant="outlined"
                    />
                  </div>
                  <p>{performanceFeedback?.softSkills.criticalThinking || 0}</p>
                </div>
                <div className={styles.formElements}>
                  <p className={styles.fixedWidth}>Trabajo en equipo</p>
                  <div className={styles.inputForm}>
                    <TextInput
                      control={control}
                      testId={'hard-skills-input'}
                      name="softSkills.teamWork"
                      type={'number'}
                      variant="outlined"
                    />
                  </div>
                  <p>{performanceFeedback?.softSkills.teamWork || 0}</p>
                </div>
                <div className={styles.formElements}>
                  <p className={styles.fixedWidth}>Resolución de confictos</p>
                  <div className={styles.inputForm}>
                    <TextInput
                      control={control}
                      testId={'hard-skills-input'}
                      name="softSkills.conflictsResolution"
                      type={'number'}
                      variant="outlined"
                    />
                  </div>
                  <p>{performanceFeedback?.softSkills.conflictsResolution || 0}</p>
                </div>
                <div className={styles.formElements}>
                  <p className={styles.fixedWidth}>Empatía</p>
                  <div className={styles.inputForm}>
                    <TextInput
                      control={control}
                      testId={'hard-skills-input'}
                      name="softSkills.empathy"
                      type={'number'}
                      variant="outlined"
                    />
                  </div>
                  <p>{performanceFeedback?.softSkills.empathy || 0}</p>
                </div>
                <div className={styles.formElements}>
                  <p className={styles.fixedWidth}>Compromiso con la empresa</p>
                  <div className={styles.inputForm}>
                    <TextInput
                      control={control}
                      testId={'hard-skills-input'}
                      name="softSkills.companyCompromise"
                      type={'number'}
                      variant="outlined"
                    />
                  </div>
                  <p>{performanceFeedback?.softSkills.companyCompromise || 0}</p>
                </div>
                <div className={styles.formElements}>
                  <p className={styles.fixedWidth}>Cumplimiento con el cliente</p>
                  <div className={styles.inputForm}>
                    <TextInput
                      control={control}
                      testId={'hard-skills-input'}
                      name="softSkills.clientAccomplishment"
                      type={'number'}
                      variant="outlined"
                    />
                  </div>
                  <p>{performanceFeedback?.softSkills.clientAccomplishment || 0}</p>
                </div>
                <div className={styles.formElements}>
                  <p className={styles.fixedWidth}>Enseña/Aprende</p>
                  <div className={styles.inputForm}>
                    <TextInput
                      control={control}
                      testId={'hard-skills-input'}
                      name="softSkills.teachesAndLearns"
                      type={'number'}
                      variant="outlined"
                    />
                  </div>
                  <p>{performanceFeedback?.softSkills.teachesAndLearns || 0}</p>
                </div>
                <div className={styles.formElements}>
                  <p className={styles.fixedWidth}>Proactividad</p>
                  <div className={styles.inputForm}>
                    <TextInput
                      control={control}
                      testId={'hard-skills-input'}
                      name="softSkills.proactivity"
                      type={'number'}
                      variant="outlined"
                    />
                  </div>
                  <p>{performanceFeedback?.softSkills.proactivity || 0}</p>
                </div>
                <div className={styles.formElements}>
                  <p className={styles.fixedWidth}>Autonomía</p>
                  <div className={styles.inputForm}>
                    <TextInput
                      control={control}
                      testId={'hard-skills-input'}
                      name="softSkills.autonomy"
                      type={'number'}
                      variant="outlined"
                    />
                  </div>
                  <p>{performanceFeedback?.softSkills.autonomy || 0}</p>
                </div>
              </form>
            </div>
            <div className={styles.skillCard} data-testid="hard-skills-container">
              <div className={styles.cardHead}>
                <p className={styles.cardHeadLeft}>Habilidades Técnicas</p>
                <p className={styles.cardHeadRight}>Nota previa</p>
              </div>
              <form>
                <div className={styles.formElements}>
                  <p className={styles.fixedWidth}>Grado técnico</p>
                  <div className={styles.inputForm}>
                    <TextInput
                      control={control}
                      testId={'hard-skills-input'}
                      name="hardSkills.technicalLevel"
                      type={'number'}
                      variant="outlined"
                    />
                  </div>
                  <p>{performanceFeedback?.hardSkills.technicalLevel || 0}</p>
                </div>
                <div className={styles.formElements}>
                  <p className={styles.fixedWidth}>Lenguajes o herramientas que domina</p>
                  <div className={styles.inputForm}>
                    <TextInput
                      control={control}
                      testId={'hard-skills-input'}
                      name="hardSkills.toolsAndLanguages"
                      type={'number'}
                      variant="outlined"
                    />
                  </div>
                  <p>{performanceFeedback?.hardSkills.toolsAndLanguages || 0}</p>
                </div>
                <div className={styles.formElements}>
                  <p className={styles.fixedWidth}>Calidad del trabajo entregado</p>
                  <div className={styles.inputForm}>
                    <TextInput
                      control={control}
                      testId={'hard-skills-input'}
                      name="hardSkills.workQuality"
                      type={'number'}
                      variant="outlined"
                    />
                  </div>
                  <p>{performanceFeedback?.hardSkills.workQuality || 0}</p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        testId="editPerformanceFeedbackModal"
        styles={styles.modal}
        isOpen={showConfirmModal}
        onClose={() => dispatch(closeConfirmationModal())}
      >
        <ConfirmationMessage
          description={`¿Desea agregar el feedback de desempeño para ${performanceFeedback?.userData.firstName} ${performanceFeedback?.userData.lastName}?`}
          title={'Crear feedback de desempeño'}
          handleConfirm={handleSubmit(onSubmit)}
          handleClose={() => dispatch(closeConfirmationModal())}
        />
      </Modal>
    </>
  );
};

export default PerformanceFeedback;
