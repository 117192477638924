import Button from '../buttons/button';
import { Variant } from '../buttons/button/types';
import styles from './dropzone.module.css';
import { DragAndDropProps } from './types';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, Collapse, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import Dropzone from 'react-dropzone';

const DragAndDrop = (props: DragAndDropProps) => {
  const { handleOnDrop, onClose, onConfirm, testId, list } = props;

  const [open, setOpen] = React.useState(true);
  const [disabled, setDisabled] = React.useState(true);

  const closeButton = () => {
    onClose();
    setDisabled(true);
  };

  useEffect(() => {
    if (list.length) {
      setDisabled(false);
    }
  }, [list]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.addCSVMessage}>Agregar CSV</div>
      <div className={styles.container}>
        <Dropzone onDrop={(files) => handleOnDrop(files)} accept={{ key: ['text/csv'] }}>
          {({ getRootProps, getInputProps, acceptedFiles, isDragActive }) => (
            <section>
              <div
                {...getRootProps()}
                data-testid={testId}
                className={
                  acceptedFiles.length || isDragActive
                    ? styles.dropZoneContainerFilled
                    : styles.dropZoneContainer
                }
              >
                <input {...getInputProps()} />
                <div className={styles.textContainer}>
                  {isDragActive ? (
                    <p className={styles.dropzoneText}>Suelte el archivo</p>
                  ) : (
                    <p className={styles.dropzoneText}>
                      Arrastre un archivo CSV para crear usuarios o clickee para seleccionarlo
                    </p>
                  )}
                  {acceptedFiles.length > 0 && <p>{acceptedFiles[0].name}</p>}
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          testId="reset-btn"
          materialVariant={Variant.OUTLINED}
          label="Cancelar"
          onClick={() => closeButton()}
        />
        <Button
          testId="dropzone-button"
          materialVariant={Variant.CONTAINED}
          onClick={() => onConfirm()}
          label="Confirmar"
          disabled={disabled}
        />
      </div>
      <div className={styles.warningContainer}>
        <Collapse in={open} data-testid={'drop-zone-warning'}>
          <Alert
            severity="info"
            action={
              <IconButton
                data-testid={'close-icon'}
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>¡Atención!</AlertTitle>
            Los encabezados deben ser: Rol, Email, Nombre, Apellido, DNI, Direción, Teléfono,
            Localidad y Fecha de nacimiento.
          </Alert>
        </Collapse>
      </div>
    </div>
  );
};

export default DragAndDrop;
