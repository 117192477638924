import Button from '../buttons/button';
import styles from './table.module.css';
import { RowData, SortBy, TableProps } from './types';
import {
  IconButton,
  Table as BasicTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import React, { useEffect } from 'react';
import { FaCircleUser } from 'react-icons/fa6';

const Table = <T extends RowData>(props: TableProps<T>) => {
  const {
    showButtons,
    headers,
    value,
    testId,
    buttons,
    profileIcon,
    setDataList,
    isActive,
    filters,
  } = props;

  const [order, setOrder] = React.useState<SortBy>({ dir: 'asc' });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    filters && page !== 0 && setPage(0);
  }, [filters]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const sorting = (col: string) => {
    if (order.dir === 'asc') {
      const sorted = [...value].sort((a, b) =>
        a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1,
      );
      setDataList(sorted);
      setOrder({ dir: 'desc' });
    }
    if (order.dir === 'desc') {
      const sorted = [...value].sort((a, b) =>
        a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1,
      );
      setDataList(sorted);
      setOrder({ dir: 'asc' });
    }

    return 0;
  };

  return (
    <>
      <TableContainer data-testid={testId}>
        <BasicTable className={styles.table}>
          <TableHead>
            <TableRow className={styles.headers}>
              {profileIcon && <TableCell align="center"></TableCell>}
              {headers.map((row) => (
                <TableCell align="center" key={row.key}>
                  <TableSortLabel
                    data-testid={row.header}
                    onClick={() => sorting(row.key)}
                    direction={order.dir}
                  >
                    {row.header}
                  </TableSortLabel>
                </TableCell>
              ))}
              {showButtons && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {value?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={row['id'] || row['_id']} hover={true}>
                {profileIcon && (
                  <TableCell>
                    <FaCircleUser fontSize={'40px'} color={'#C7C7C7'} />
                  </TableCell>
                )}
                {headers.map((header, index) => (
                  <TableCell
                    data-testid="table-name"
                    align="center"
                    key={index}
                    scope="row"
                    className={!isActive ? styles.inactiveRows : ''}
                  >
                    {row[header.key]}
                  </TableCell>
                ))}
                {buttons?.length && (
                  <TableCell align="right" className={styles.buttonCell}>
                    {buttons.map((button, index) =>
                      button.icon ? (
                        <Tooltip placement={'top'} title={button.tooltipLabel || ''} key={index}>
                          <IconButton
                            data-testid={button.testId}
                            onClick={() => button.onClick(row)}
                            disabled={!button.active}
                          >
                            {button.icon}
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Button
                          key={index}
                          materialVariant={button.variant}
                          testId={button.testId}
                          onClick={() => button.onClick(row)}
                          label={button.label}
                          disabled={!button.active}
                        />
                      ),
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </BasicTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={value.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={'Filas por página'}
      />
    </>
  );
};

export default Table;
