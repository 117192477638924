import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import api from 'src/config/api';
import { loginSuccess, setAuthentication } from 'src/redux/auth/actions';
import store from 'src/redux/store';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);

export const tokenListener = () => {
  auth.onIdTokenChanged(async (user) => {
    try {
      if (user) {
        const {
          token,
          claims: { accessTypes, email, name, isActive },
        } = await user.getIdTokenResult(true);

        store.dispatch(
          loginSuccess({
            token,
            accessTypes,
            email,
            name,
            isActive,
          }),
        );
        api.defaults.headers['token'] = token;
      } else {
        api.defaults.headers['token'] = '';
        store.dispatch(
          setAuthentication({
            token: '',
            accessTypes: undefined,
            email: '',
            name: '',
            isActive: false,
          }),
        );
      }
    } catch (error: unknown) {
      return console.error(error);
    }
  });
};

export default firebaseApp;
