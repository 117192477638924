import SkillsModal from '../../skillsModal';
import EmployeeSkillsModal from '../EmployeeSkillsModal';
import { availabilityOptions, expertiseAreaOptions, roleCheckboxOptions } from '../constants';
import { CompleteFormType } from '../types';
import styles from './styles.module.css';
import { IconButton, Tooltip } from '@mui/material';
import { subYears } from 'date-fns';
import React from 'react';
import { Control, UseFormSetValue, useFormContext, useWatch } from 'react-hook-form';
import { FaClock, FaPlus } from 'react-icons/fa6';
import { Button, DatePicker, Dropdown, Modal, TextInput } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import { ModalIDs, openModal } from 'src/redux/modals/modalsSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { RadiumRoleType } from 'src/types';
import { calculateRanksBasedOnRole } from 'src/utils/calculateRanksBasedOnRole';

const RankDropdown = ({
  control,
  setValue,
}: {
  control: Control<CompleteFormType, unknown>;
  setValue: UseFormSetValue<CompleteFormType>;
}) => {
  const { form } = useWatch({
    control,
  });

  const { role, rank } = form;

  const getRankOptionsByRole = (role: RadiumRoleType) => {
    const ranks = calculateRanksBasedOnRole(role).map((rank) => {
      return { value: rank.toString(), label: rank.toString() };
    });
    return ranks;
  };

  const rankOptions = getRankOptionsByRole(role);

  const rankInDropdownOptions = rankOptions.find(
    (rankOption) => rankOption.value === rank.toString(),
  );

  if (!rankInDropdownOptions) setValue('form.rank', rankOptions[0].value);

  return (
    <Dropdown
      control={control}
      testId={'rankDropdown'}
      name="form.rank"
      label="Rango"
      options={rankOptions}
      error
      fullWidth
    />
  );
};

function Form() {
  const dispatch = useAppDispatch();
  const selectedEmployee = useAppSelector((state) => state.employee.selectedEmployee);
  const activeModal = useAppSelector((state) => state.modals.activeModal);

  const { control, getValues, setValue } = useFormContext<CompleteFormType>();

  const calculateDedicatedHours = () => {
    const dedicatedHours = selectedEmployee?.memberHistory?.reduce((totalDedication, project) => {
      if (project.active) {
        return totalDedication + project.memberDedication;
      }
      return totalDedication;
    }, 0);
    return `${dedicatedHours} hs`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <Dropdown
          control={control}
          testId={'roleDropdown'}
          name="form.role"
          label="Rol"
          options={roleCheckboxOptions}
          error
        />
        <RankDropdown control={control} setValue={setValue} />
        <Dropdown
          control={control}
          testId={'expertiseAreaDropdown'}
          name="form.expertiseArea"
          label="Área de Expertise"
          options={expertiseAreaOptions}
          error
          fullWidth
        />
        <Tooltip
          placement={'bottom-end'}
          arrow
          title="Suma de las horas de los proyectos en los que se encuentra"
          sx={{ maxWidth: '100px' }}
        >
          <div className={styles.hoursDedicatedContainer}>
            <label className={styles.hoursDedicatedLabel}>Horas Dedicadas</label>
            <div className={styles.hoursDedicated}>
              <FaClock color={'#ffffff'} size={'16px'} />
              <div>{calculateDedicatedHours()}</div>
            </div>
          </div>
        </Tooltip>
      </div>
      <div className={styles.splitContainer}>
        <div className={styles.elementContainer}>
          <TextInput
            control={control}
            testId={'email-input'}
            label="Email"
            name="form.email"
            type={'text'}
            variant="outlined"
          />
        </div>
        <div className={styles.elementContainer}>
          <DatePicker
            label={'Fecha de nacimiento'}
            testId={'date-picker-test-id'}
            name="form.DoB"
            control={control}
            maxDate={subYears(new Date(), 18)}
            readOnly
          />
        </div>
      </div>
      <div className={styles.titles}>Disponibilidad</div>
      <div className={styles.splitContainer}>
        <div className={styles.elementContainer}>
          <Dropdown
            control={control}
            testId={'disponibilidadDropdown'}
            name="form.availabilityDescription"
            options={availabilityOptions}
            error
            fullWidth
          />
        </div>
        <div className={styles.elementContainer}>
          <TextInput
            control={control}
            testId={'availableHoursInput'}
            label="Horas Disponibles"
            name="form.availableHours"
            type={'number'}
            variant="outlined"
            fullWidth
          />
        </div>
      </div>

      <div className={styles.titles}>Skills del empleado</div>

      <div className={styles.elementContainer}>
        <div className={styles.skillsContainer}>
          {getValues('form.skills')?.map((skill, index) => {
            return (
              <span key={index} className={styles.profileSkillSpan}>
                {skill.name}
              </span>
            );
          })}
          <Tooltip placement={'right'} arrow title="Agregar una skill al empleado">
            <IconButton onClick={() => dispatch(openModal(ModalIDs.EMPLOYEE_SKILLS))}>
              <FaPlus color={'#373867'} size={'15px'} />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <Tooltip placement={'right'} arrow title="Agregar una skill global">
        <span>
          <Button
            label="ABM de Skills"
            type="button"
            materialVariant={Variant.CONTAINED}
            color="primary"
            size="large"
            onClick={() => dispatch(openModal(ModalIDs.SKILLS))}
          />
        </span>
      </Tooltip>

      <div className={styles.notesContainer}>
        <TextInput
          control={control}
          testId={'notes-input'}
          label="Notas"
          name="form.notes"
          type={'text'}
          variant="outlined"
          fullWidth
          multiline
          rows={4}
        />
      </div>

      {activeModal && (
        <div>
          <Modal testId="employee-skills-modal" id={ModalIDs.EMPLOYEE_SKILLS}>
            <EmployeeSkillsModal />
          </Modal>
          <Modal testId="skills-modal" id={ModalIDs.SKILLS}>
            <SkillsModal />
          </Modal>
        </div>
      )}
    </div>
  );
}

export default React.memo(Form);
