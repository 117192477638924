import styles from '../availability.module.css';
import { AbsencesBackgroundColor, AbsencesStates } from './types';
import { Typography } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate, useParams } from 'react-router-dom';
import NoResultsMatched from 'src/components/shared/common/noResultsMatched';
import { Button, Loader } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import { getResourceRequest } from 'src/config/api';
import { ApiRoutes, UiRoutes } from 'src/constants';

const individualAvailability = () => {
  const navigate = useNavigate();
  const [availability, setAvailability] = useState(null);
  const [loading, setLoading] = useState(true);
  const [chartMaxDate, setChartMaxDate] = React.useState('');
  const [filters, setFilters] = React.useState({
    period: '',
  });

  const { id } = useParams();

  useEffect(() => {
    const getAvailability = async () => {
      try {
        const data = await getResourceRequest(`${ApiRoutes.AVAILABILITY}/${id}`);
        setAvailability(data.data);
      } catch (error) {
        console.error(`An error occurred while fetching availability if employee id: ${id}`, error);
        // TODO: setErrorState(error);
      } finally {
        setLoading(false);
      }
    };
    getAvailability();
  }, []);

  const generateDates = (months: number) => {
    const dates = [];
    const currentDate = new Date();
    for (let i = 0; i <= months; i++) {
      dates.push(currentDate.toISOString().split('T')[0]);
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return dates;
  };

  const handleMonthFilters = (e) => {
    setFilters({ ...filters, period: e.target.value });
    let months;
    switch (e.target.value) {
      case 'three':
        months = 3;
        break;
      case 'six':
        months = 6;
        break;
      case 'twelve':
        months = 12;
        break;
      default:
        months = 0;
    }
    const dates = generateDates(months);
    const maxDate = dates[dates.length - 1];
    setChartMaxDate(maxDate);
  };

  const dataMemo = useMemo(() => {
    const labelsToShow = [`${availability?.lastName} ${availability?.firstName}`];

    const projects = availability?.projects?.map((project) => ({
      label: project?.projectName,
      backgroundColor: '#373867cc',
      borderRadius: 5,
      data: project?.endDate
        ? [[project?.startDate.slice(0, 10), project?.endDate?.slice(0, 10)]]
        : [
            [
              project?.startDate.slice(0, 10),
              new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
            ],
          ],
      skipNull: true,
    }));

    const absences = availability?.absences?.map((absence) => ({
      label:
        absence?.state === AbsencesStates.Aceptada
          ? absence?.motive
          : `${absence?.motive} - Pendiente de confirmación`,
      backgroundColor:
        absence?.state === AbsencesStates.Aceptada
          ? AbsencesBackgroundColor[absence?.motive]
          : '#4bc0c033',
      borderRadius: 5,
      data:
        absence?.state === AbsencesStates.Aceptada || absence?.state === AbsencesStates.Pendiente
          ? [[absence?.startDate.slice(0, 10), absence?.endDate?.slice(0, 10)]]
          : null,
      skipNull: true,
    }));

    const chartJSData = {
      labels: labelsToShow,
      datasets: [...(projects || []), ...(absences || [])],
    };

    const chartJSConfig: ChartOptions<'bar'> = {
      responsive: true,
      indexAxis: 'y' as const,
      plugins: {
        legend: {
          position: 'bottom' as const,
          align: 'end' as const,
          display: false,
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            title: (tooltipItem) => tooltipItem[0].dataset?.label,
            label: (tooltipItem) => {
              const startDate = new Date(`${tooltipItem.raw[0]}T00:00:00`).toLocaleDateString(
                'es-ES',
                {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                },
              );
              const endDate = new Date(`${tooltipItem.raw[1]}T00:00:00`).toLocaleDateString(
                'es-ES',
                {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                },
              );
              return endDate === 'Invalid Date'
                ? `${startDate} - Long Term`
                : `${startDate} - ${endDate}`;
            },
          },
          titleFont: { size: 14, family: 'Roboto' },
          bodyFont: { size: 12, family: 'Roboto' },
          footerFont: { size: 12, family: 'Roboto' },
        },
      },
      scales: {
        x: {
          type: 'time' as const,
          time: {
            unit: 'month' as const,
          },
          min: new Date().toISOString(),
          max:
            chartMaxDate ||
            new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
          bounds: 'ticks',
          stacked: false,
          ticks: {
            callback: (value) => {
              const date = new Date(value);
              const month = new Intl.DateTimeFormat('es-ES', { month: 'long' })
                .format(date)
                .slice(0, 3);
              const year = date.getFullYear().toString();
              const formattedDate = `${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
              return formattedDate;
            },
          },
        },
        y: {
          ticks: {
            display: false,
          },
          stacked: false,
        },
      },
    };
    return {
      chartJSData,
      chartJSConfig,
    };
  }, [availability, chartMaxDate]);

  ChartJS.register(CategoryScale, TimeScale, BarElement, Title, Tooltip, Legend);

  if (loading) return <Loader />;
  return (
    <div className={styles.individualAvailabilityContainer}>
      {dataMemo.chartJSData?.datasets?.length > 0 ? (
        <>
          <div className={styles.welcomeMessage}>
            <Typography variant="h1">
              Disponibilidad de {availability?.firstName} {availability?.lastName}
            </Typography>
          </div>
          <div className={styles.checkboxInput} data-testid="filters-container">
            <select className={styles.filterDropdown} onChange={handleMonthFilters}>
              <option value={'twelve'} selected={filters.period === ''} className={styles.option}>
                {'12 meses'}
              </option>
              <option value={'six'} className={styles.option}>
                {'6 meses'}
              </option>
              <option value={'three'} className={styles.option}>
                {'3 meses'}
              </option>
            </select>
          </div>
          <div className={styles.graphReferences}>
            <div>
              <div className={styles.projectsRef} />
              <p>Proyectos</p>
            </div>
            <div>
              <div className={styles.licenseRef} />
              <p>Licencia</p>
            </div>
            <div>
              <div className={styles.vacationsRef} />
              <p>Vacaciones</p>
            </div>
            <div>
              <div className={styles.studyRef} />
              <p>Estudio</p>
            </div>
            <div>
              <div className={styles.absencesPendingRef} />
              <p>Ausencias pendientes</p>
            </div>
          </div>
          <Bar options={dataMemo.chartJSConfig} data={dataMemo.chartJSData} />
        </>
      ) : (
        <>
          <div className={styles.welcomeMessage}>
            <Typography variant="h1">
              Este empleado no tiene proyectos ni ausencias asignadas
            </Typography>
          </div>
          <NoResultsMatched />
        </>
      )}
      <div className={styles.backButton}>
        <Button
          materialVariant={Variant.OUTLINED}
          onClick={() => {
            navigate(`${UiRoutes.ADMIN_AM}${UiRoutes.EDIT_EMPLOYEES}/${id}`);
          }}
          label={'Volver'}
          testId={'back-button'}
        />
      </div>
    </div>
  );
};

export default individualAvailability;
