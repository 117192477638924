import Joi from 'joi';

const createLevelValidation = (level, prevLevel, nextLevel, formattedData) => {
  return Joi.object({
    min: Joi.number()
      .min(1)
      .max(99999999)
      .required()
      .custom((value, helper) => {
        if (value >= formattedData[level]?.max) {
          return helper.error('any.invalidMin');
        }
        if (prevLevel && value <= formattedData[prevLevel]?.max) {
          return helper.error('any.invalidMinPrev');
        }
        return value;
      })
      .messages({
        'number.base': 'El mínimo debe ser un número',
        'number.min': 'El mínimo debe ser mayor a 0',
        'number.max': 'El mínimo debe ser menor a 99.999.999',
        'any.required': 'El mínimo es requerido',
        'any.invalidMin': 'El mínimo no puede ser mayor o igual al máximo',
        'any.invalidMinPrev': 'El mínimo debe ser mayor al máximo del nivel anterior',
      }),
    max: Joi.number()
      .min(1)
      .max(99999999)
      .required()
      .custom((value, helper) => {
        if (value <= formattedData[level]?.min) {
          return helper.error('any.invalidMax');
        }
        if (nextLevel && value >= formattedData[nextLevel]?.min) {
          return helper.error('any.invalidMaxNext');
        }
        return value;
      })
      .messages({
        'number.base': 'El máximo debe ser un número',
        'number.min': 'El máximo debe ser mayor a 0',
        'number.max': 'El mínimo debe ser menor a 99.999.999',
        'any.required': 'El máximo es requerido',
        'any.invalidMax': 'El máximo no puede ser menor o igual al mínimo',
        'any.invalidMaxNext': 'El máximo debe ser menor al mínimo del nivel siguiente',
      }),
  });
};

export const editSalaryRangesValidations = (valueData) => {
  const levels = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'];

  const formattedData = valueData.reduce((acc, item, index) => {
    const level = `level${levels[index]}`;
    acc[level] = {
      min: +item.min,
      max: +item.max,
    };
    return acc;
  }, {});

  const schema = levels.reduce((acc, level, index) => {
    const prevLevel = levels[index - 1] ? `level${levels[index - 1]}` : null;
    const nextLevel = levels[index + 1] ? `level${levels[index + 1]}` : null;
    acc[`level${level}`] = createLevelValidation(
      `level${level}`,
      prevLevel,
      nextLevel,
      formattedData,
    );
    return acc;
  }, {});

  return Joi.object(schema).options({ allowUnknown: true });
};
