import { subYears } from 'date-fns';
import Joi from 'joi';
import { AccessRoleType } from 'src/constants';
import { EmployeeTypeEnum } from 'src/types';

export const userValidation = (userEmailValidation) => {
  return Joi.object({
    email: Joi.string()
      .regex(/^[a-zA-Z]+\.+[a-zA-Z]+@(radiumrocket.com)$/)
      .min(20)
      .max(50)
      .trim()
      .custom((value, helper) => {
        if (value && userEmailValidation) {
          return helper.error('any.invalid');
        }
      })
      .messages({
        'string.empty': 'Este campo es requerido',
        'string.min': 'El email debe contener al menos 3 letras',
        'string.max': 'El email debe contener menos de 50 letras',
        'any.invalid': 'Email ya registrado',
        'string.pattern.base': 'El formato debe ser nombre.apellido@radiumrocket.com',
      })
      .required(),

    firstName: Joi.string()
      .min(3)
      .max(35)
      .regex(/^[a-zA-ZñáéíóúüÁÉÍÓÚÜ\s]*$/)
      .trim()
      .messages({
        'string.min': 'El nombre debe contener al menos 3 letras',
        'string.empty': 'Este campo es requerido',
        'string.max': 'El nombre debe tener máximo 35 letras',
        'string.pattern.base': 'El nombre debe contener sólo letras',
      })
      .required(),

    lastName: Joi.string()
      .regex(/^[a-zA-ZñáéíóúüÁÉÍÓÚÜ\s]*$/)
      .min(3)
      .max(35)
      .trim()
      .messages({
        'string.pattern.base': 'El apellido debe contener sólo letras',
        'string.empty': 'Este campo es requerido',
        'string.min': 'El apellido debe contener al menos 3 letras',
        'string.max': 'El apellido debe tener máximo 35 letras',
      })
      .required(),

    location: Joi.string()
      .min(3)
      .max(30)
      .regex(/^[a-zA-Z0-9ñáéíóúüÁÉÍÓÚÜ ]*$/)
      .trim()
      .messages({
        'string.pattern.base': 'El formato de localidad no es válido',
        'string.empty': 'Este campo es requerido',
        'string.min': 'La localidad debe tener al menos 3 letras',
        'string.max': 'La localidad debe tener máximo 30 caracteres',
      })
      .required(),
    dni: Joi.number()
      .integer()
      .min(1000000)
      .max(99999999)
      .messages({
        'number.min': 'El documento de identidad debe contener al menos 7 dígitos',
        'number.max': 'El documento de identidad debe contener como máximo 8 dígitos',
      })
      .required(),

    company: Joi.string().messages({
      'any.invalidObjectId': 'El ID no es válido',
    }),

    phone: Joi.number()
      .integer()
      .min(10000000)
      .max(999999999999999)
      .messages({
        'number.min': 'El teléfono/celular debe contener al menos 8 dígitos',
        'number.max': 'El teléfono/celular debe contener como máximo 11 dígitos',
      })
      .required(),

    address: Joi.string()
      .min(3)
      .max(100)
      .messages({
        'string.min': 'La dirección debe contener al menos 3 caracteres',
        'string.max': 'La dirección debe contener como máximo 100 caracteres',
      })
      .required(),

    employeeType: Joi.array().items(
      Joi.string().valid(EmployeeTypeEnum.ADMINISTRATIVO, EmployeeTypeEnum.OPERATIVO),
    ),
    accessTypes: Joi.array().items(
      Joi.string().valid(
        AccessRoleType.ACCOUNTANCY_AD,
        AccessRoleType.ACCOUNTANCY_AL,
        AccessRoleType.ACCOUNTANCY_AM,
        AccessRoleType.ADMIN_AM,
        AccessRoleType.EMPLOYEE,
      ),
    ),

    birthDate: Joi.date()
      .greater(new Date('01/01/1930'))
      .less(subYears(new Date(), 18))
      .messages({
        'date.greater': 'La fecha debe ser posterior a 01-01-1930',
        'date.less': 'El usuario debe ser mayor a 18 años',
        'date.base': 'Este campo es requerido. Formato dd/mm/aaaa',
      })
      .required(),
  }).options({ allowUnknown: true });
};
