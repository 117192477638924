import { ReqStates } from '../types';
import styles from './salaryRangesForm.module.css';
import { FormValues, EditSalaryRangesFormProps, inputNames } from './types';
import { editSalaryRangesValidations } from './validations';
import { joiResolver } from '@hookform/resolvers/joi';
import { Typography } from '@mui/material';
import { Dialog as MaterialModal } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, ConfirmationMessage, Loader, TextInput } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import api from 'src/config/api';
import { ApiRoutes } from 'src/constants';
import { closeModal } from 'src/redux/modals/modalsSlice';
import { useAppDispatch } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toastSlice';

const EditSalaryRangesForm = (props: EditSalaryRangesFormProps) => {
  const { role, chartData, setLoadingReq, loadingReq } = props;
  const [open, setOpen] = React.useState(false);
  const [valueData, setValueData] = React.useState<unknown>([]);

  const dispatch = useAppDispatch();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const defaultValues = chartData.reduce((acc, item, key) => {
    acc[inputNames[key].min] = item[1];
    acc[inputNames[key].max] = item[3] + item[1];
    return acc;
  }, {});

  const dataObject = Object.entries(defaultValues).reduce((acc, [key, value]) => {
    const [level, type] = key.split('.');
    if (!acc[level]) {
      acc[level] = {};
    }
    acc[level][type] = value;
    return acc;
  }, {});

  const {
    formState: { isDirty, isValid, isValidating },
    handleSubmit,
    control,
    getValues,
  } = useForm<FormValues>({
    defaultValues: dataObject,
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: joiResolver(editSalaryRangesValidations(Object.values(valueData))),
  });

  const formChanged = (!isDirty && !isValidating) || !isValid;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const salaryRangeData = Object.entries(getValues()).map(([key, value], index) => {
    return {
      min: +value.min,
      max: +value.max,
      level: index + 1,
      role: role,
    };
  });

  const onSubmit = async () => {
    setLoadingReq(ReqStates.LOADING);
    try {
      await api.patch(`${ApiRoutes.SALARYRANGE}`, salaryRangeData);
      dispatch(openToast({ message: 'Rango salarial editado con exito', error: false }));
    } catch (error) {
      dispatch(openToast({ message: error.message, error: true }));
      setLoadingReq(ReqStates.DONE);
    } finally {
      handleClose();
      dispatch(closeModal());
      setLoadingReq(ReqStates.DONE);
    }
  };

  useEffect(() => {
    setValueData(getValues());
  }, []);

  if (loadingReq === ReqStates.LOADING) {
    return <Loader />;
  }

  return (
    <div className={styles.modalContainer}>
      <div className={styles.headerEditEmployeeSalary} data-testid={'header-message'}>
        <p>{`Ajustar franja salarial para ${role}`}</p>
      </div>
      <div>
        <div className={styles.employeeSalaryForm}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              {chartData?.map((item, key) => (
                <div key={key} className={styles.editSalaryRangesForm}>
                  <Typography className={styles.typography}>Nivel {item[0]}</Typography>
                  <TextInput
                    control={control}
                    testId={'minimum'}
                    label="Mínimo"
                    name={inputNames[key].min}
                    type={'number'}
                    variant="outlined"
                    fullWidth
                    value={item[1]}
                  />
                  <TextInput
                    control={control}
                    testId={'maximum'}
                    label="Máximo"
                    name={inputNames[key].max}
                    type={'number'}
                    variant="outlined"
                    fullWidth
                    value={item[3]}
                  />
                </div>
              ))}
            </div>
            <div className={styles.buttonsContainer}>
              <div>
                <Button
                  materialVariant={Variant.CONTAINED}
                  label={'Cancelar'}
                  color="secondary"
                  sx={{ width: 'fit-content', marginTop: 2 }}
                  testId={'close-individual-adjustment-modal'}
                  onClick={() => dispatch(closeModal())}
                />
              </div>
              <div>
                <Button
                  materialVariant={Variant.CONTAINED}
                  label={'Confirmar'}
                  sx={{ width: 'fit-content', marginTop: 2 }}
                  testId={'update-individual-adjustment'}
                  disabled={formChanged}
                  onClick={handleOpen}
                />
              </div>
            </div>
          </form>
        </div>
        <MaterialModal open={open} onClose={handleClose}>
          <ConfirmationMessage
            description={`¿Seguro que desea editar las franjas salariales para todos los niveles de ${role}? Esto afectará al histórico de salarios de los empleados con este rol.`}
            title={`Confirmar edición de franjas salariales para ${role}`}
            confirmationLabel="Guardar cambios"
            handleConfirm={onSubmit}
            handleClose={handleClose}
          />
        </MaterialModal>
      </div>
    </div>
  );
};

export default EditSalaryRangesForm;
