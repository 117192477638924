import { notificationsCheckboxData } from '../employees/employeeEdit/constants';
import { entities, notificationsFilterOptions, notificationHomeTypes } from './constants';
import styles from './home.module.css';
import { MovementsHistoryList } from './movementsList';
import { HomeDateIntervalsValue, HomeFilterType } from './types';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Checkbox, FormControlLabel, IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import axios from 'axios';
import { addDays, format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaDeleteLeft, FaClock } from 'react-icons/fa6';
import { RxUpdate } from 'react-icons/rx';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EmptyDataHandler from 'src/components/shared/common/emptyDataHandler';
import { Button, ConfirmationMessage, Loader, Modal } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import Card from 'src/components/shared/ui/card';
import DateIntervalPicker from 'src/components/shared/ui/inputs/date-picker-interval';
import { ApiRoutes } from 'src/constants';
import { ModalIDs, closeModal } from 'src/redux/modals/modalsSlice';
import { deleteNotification, getNotifications } from 'src/redux/notifications/thunk';
import store, { RootState, useAppSelector } from 'src/redux/store';
import { ErrorType } from 'src/redux/types';
import { AppDispatch, Resources } from 'src/types';
import { capitalizeFirstLetter } from 'src/utils/formatters';
import { getFilteredDataByTextSearch } from 'src/utils/getFilteredDataByTextSearch';

const token = store.getState().auth.authUser.token;

const filterData = (list, filters: HomeFilterType, startDate, endDate) => {
  let filterDataList;

  if (filters.newest) {
    filterDataList = list.sort((a, b) => {
      const dateA = new Date(a.limitDate).getTime();
      const dateB = new Date(b.limitDate).getTime();
      return dateA > dateB ? 1 : -1;
    });
  } else {
    filterDataList = list.sort((a, b) => {
      const dateA = new Date(a.limitDate).getTime();
      const dateB = new Date(b.limitDate).getTime();
      return dateB > dateA ? 1 : -1;
    });
  }

  filterDataList = filterDataList.filter((item) =>
    item.resource.includes(filters.role) && filters.type === 'CUSTOM'
      ? item.isCustom
      : !item.isCustom,
  );

  if (filters.search) {
    filterDataList = getFilteredDataByTextSearch(
      filterDataList,
      notificationsFilterOptions,
      filters.search,
    );
  }

  if (filters.period && endDate > startDate) {
    filterDataList = filterDataList.filter((item) => {
      const date = new Date(item.limitDate).getTime();
      const formattedStartDate = new Date(startDate).getTime();
      const formattedEndDate = new Date(endDate).getTime();
      return formattedStartDate <= date && date <= formattedEndDate;
    });
  }

  if (filters.notificationType?.length > 0) {
    filterDataList = filterDataList.filter((item) =>
      filters.notificationType.includes(item.notification),
    );
  }

  return filterDataList;
};

const selectName = (item) => {
  switch (item.resource) {
    case 'PROJECT':
      return { name: item.projectName, id: item.projectId };
    case 'CLIENT':
      return { name: item.clientName, id: item.clientId };
    case 'EMPLOYEE':
      return { name: item.employeeName, id: item.employeeId };
    default:
      return {
        name: item.projectName || item.clientName || item.employeeName,
        id: item.projectId || item.clientId || item.employeeId,
      };
  }
};

const Home = () => {
  const dispatch: AppDispatch<null> = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.authUser);
  const notifications = useSelector((state: RootState) => state.notification.list);
  const notificationError = useSelector((state: RootState) => state.notification.error);
  const date = new Date(Date.now());
  const dayOfWeek = format(date, 'eeee', { locale: esLocale });
  const dayOfMonth = format(date, 'd', { locale: esLocale });
  const month = format(date, 'LLLL', { locale: esLocale });

  const today = capitalizeFirstLetter(`${dayOfWeek} ${dayOfMonth} de ${month}`);

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const liveTime = `${format(time, 'HH:mm')} hs`;

  const [filters, setFilters] = React.useState<HomeFilterType>({
    newest: true,
    period: false,
    role: '',
    search: '',
    type: '',
    notificationType: [],
  });
  const [checked, setChecked] = React.useState(false);
  const [datePicked, setDatePicked] = React.useState(false);
  const [dataList, setDataList] = React.useState([]);
  const [value, setValue] = React.useState('1');
  const [selectedCardId, setSelectedCardId] = React.useState('');

  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(addDays(new Date(), 1));
  const [excludeStartDate, setExcludeStartDate] = React.useState(false);
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [initialStartDate, setInitialStartDate] = React.useState(new Date());
  const [initialEndDate, setInitialEndDate] = React.useState(new Date());
  const [buttonPressedStyle, setButtonPressedStyle] = React.useState(false);
  const indexNameUser = user.name.indexOf(' ');
  const firstNameUser = user.name.substring(0, indexNameUser);
  const movementsData = useAppSelector((state: RootState) => state.movements.list);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [notificationFilter, setNotificationFilter] = React.useState<boolean>(false);

  const { control } = useForm<HomeDateIntervalsValue>({
    defaultValues: {
      startDate: '',
      endDate: '',
      notificationType: notificationsCheckboxData,
    },
    mode: 'onBlur',
  });

  const handleSortFilterClick = () => {
    setFilters({ ...filters, newest: !filters.newest });
    setChecked(!checked);
  };

  const handleResetClick = () => {
    setFilters({
      newest: true,
      period: false,
      role: '',
      search: '',
      type: '',
      notificationType: [],
    });
    setDatePicked(false);
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
    setChecked(false);
    setButtonPressedStyle(false);
  };

  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem('filters') || '{}');
    if (!_.isEmpty(savedFilters)) {
      setFilters(savedFilters);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('filters', JSON.stringify(filters));
  }, [filters]);

  const notificationList = useMemo(() => {
    const mappedNotifications = notifications.reduce((acc, item) => {
      if (item.isActive) {
        const filteredMembers = item.project?.members?.filter((member) => member.active);
        acc.push({
          id: item?._id,
          resource: item.notificationType,
          projectId: item.project?._id,
          projectName: item.project?.projectName || '',
          projectCriticality: item.project?.isCritic || '',
          members: filteredMembers || [],
          employeeName: item.employee?.user?.firstName + ' ' + item.employee?.user?.lastName || '',
          employeeId: item.employee?._id,
          clientName: item.client?.name || '',
          clientId: item.client?._id,
          customMessage: item.customMessage || '',
          notification: item.reasonType || '',
          limitDate: item?.limitDate || '',
          isCustom: item.isCustom,
          active: item.isActive,
        });
      }
      return acc;
    }, []);
    const filteredData = filterData(mappedNotifications, filters, startDate, endDate);
    return filteredData;
  }, [
    notifications,
    filters.newest,
    filters.role,
    filters.search,
    filters.type,
    endDate,
    filters.period,
    filters.notificationType,
  ]);

  useEffect(() => {
    setDataList(notificationList);
  }, [
    notifications,
    filters.newest,
    filters.role,
    filters.search,
    filters.type,
    filters.period,
    endDate,
    filters.notificationType,
  ]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
    setExcludeStartDate(true);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
    setDatePicked(true);
    setFilters({ ...filters, period: true });
  };

  useEffect(() => {
    setInitialEndDate(endDate);
    setInitialStartDate(startDate);
  }, []);

  useEffect(() => {
    if (endDate) {
      setShowCalendar(false);
    }
  }, [startDate, endDate]);

  const showErrorMessage =
    notificationError?.errorType === ErrorType.NETWORK_ERROR || !notificationList.length;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateNotifications = async () => {
    try {
      setIsLoading(true);
      await axios.post(
        process.env.REACT_APP_API_URL + ApiRoutes.CRONJOB,
        {},
        {
          timeout: 15000,
          headers: {
            'Content-Type': 'application/json',
            token: token,
            authorization: process.env.REACT_APP_API_SECRET_KEY,
          },
        },
      );
    } catch (error) {
      throw new Error(error.message);
    } finally {
      setIsLoading(false);
      dispatch(getNotifications());
      setDataList(notificationList);
    }
  };

  const onConfirm = (e) => {
    e.stopPropagation();
    setChecked(!checked);
    setTimeout(() => {
      dispatch(closeModal());
      dispatch(deleteNotification(selectedCardId));
    }, 50);
  };

  const onClose = (e) => {
    e.stopPropagation();
    dispatch(closeModal());
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <section className={styles.container}>
        <div className={styles.welcomeMessageContainer}>
          {user.name.length ? (
            <p className={styles.welcomeMessage}>¡Bienvenido {firstNameUser}!</p>
          ) : (
            ''
          )}
        </div>
        <div className={styles.dayTimeContainer}>
          <div className={styles.todayDate}>{today}</div>
          <div className={styles.clock}>
            <FaClock color={'#373867'} size={'15px'} />
            <div>{liveTime}</div>
          </div>
        </div>
      </section>
      <TabContext value={value}>
        <div className={styles.containerPageTop}>
          <div className={styles.switcherButtons}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Notificaciones" value="1" />
                <Tab label="Movimientos" value="2" />
              </Tabs>
            </Box>
          </div>
        </div>
        <div className={value === '1' ? styles.filterContainer : styles.modalHide}>
          <div className={styles.checkboxInput}>
            <div className={styles.filterButtonsLarge}>
              {checked ? (
                <Button
                  materialVariant={Variant.CONTAINED}
                  onClick={() => handleSortFilterClick()}
                  label={'Menos recientes'}
                  testId={'oldest-button'}
                  color={'info'}
                />
              ) : (
                <Button
                  materialVariant={Variant.TEXT}
                  onClick={() => handleSortFilterClick()}
                  label={'Más recientes'}
                  testId={'newest-button'}
                />
              )}
            </div>
            <select
              className={styles.filterDropdown}
              value={filters.role || ''}
              onChange={(e) => {
                setFilters({ ...filters, role: e.target.value });
              }}
            >
              <option value={''} className={styles.option}>
                Entidad
              </option>
              {entities?.map((item) => (
                <option key={item.value} value={item.value} className={styles.option}>
                  {item.label}
                </option>
              ))}
            </select>
            <select
              className={styles.filterDropdown}
              value={filters.type || ''}
              onChange={(e) => {
                setFilters({ ...filters, type: e.target.value });
              }}
            >
              <option value={''} className={styles.option}>
                Todas
              </option>
              {notificationHomeTypes.map((item) => (
                <option key={item.value} value={item.value} className={styles.option}>
                  {item.label}
                </option>
              ))}
            </select>
            <div className={styles.filterButtons}>
              <Button
                materialVariant={buttonPressedStyle ? Variant.CONTAINED : Variant.TEXT}
                onClick={() => {
                  if (!showCalendar) {
                    setShowCalendar(true);
                    setButtonPressedStyle(true);
                  } else {
                    setShowCalendar(false);
                    setButtonPressedStyle(false);
                  }
                }}
                label={'Período'}
                testId={'period-button'}
                color={buttonPressedStyle ? 'info' : undefined}
              />
              <div className={showCalendar ? styles.modalDisplayDesktop : styles.modalHide}>
                <DateIntervalPicker
                  control={control}
                  name="startDate"
                  setStart={handleStartDate}
                  setEnd={handleEndDate}
                  startDate={startDate}
                  endDate={endDate}
                  excludeStartDate={excludeStartDate}
                />
              </div>
            </div>
            <div className={datePicked ? styles.filterButtonsSelected : styles.filterIconButtons}>
              <Tooltip
                title="Resetear período"
                enterDelay={100}
                arrow
                classes={{
                  tooltip: styles.customTooltip,
                }}
                placement="bottom"
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 1],
                      },
                    },
                  ],
                }}
              >
                <IconButton
                  onClick={() => {
                    setFilters({ ...filters, period: false });
                    setDatePicked(false);
                    setStartDate(initialStartDate);
                    setEndDate(initialEndDate);
                    setButtonPressedStyle(false);
                  }}
                >
                  <FaDeleteLeft color={'#373867'} />
                </IconButton>
              </Tooltip>
            </div>
            <div className={styles.filterButtonsLarge}>
              <Button
                materialVariant={
                  notificationFilter || filters.notificationType.length > 0
                    ? Variant.CONTAINED
                    : Variant.TEXT
                }
                onClick={() => setNotificationFilter(!notificationFilter)}
                label={notificationFilter ? 'Menos filtros' : 'Más filtros'}
                testId={'oldest-button'}
                color={notificationFilter ? 'info' : undefined}
              />
            </div>
            <div className={styles.filterButtonsLarge}>
              <Button
                materialVariant={Variant.TEXT}
                onClick={() => handleResetClick()}
                label={'Resetear filtros'}
                testId={'reset-filters'}
              />
            </div>
            <div>
              <Tooltip
                title="Actualizar notificaciones"
                placement="bottom"
                arrow
                classes={{
                  tooltip: styles.customTooltip,
                }}
              >
                <IconButton className={styles.updateButton} onClick={() => updateNotifications()}>
                  <RxUpdate color={'#373867'} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
        {notificationFilter && (
          <div className={styles.notificationTypeFilter}>
            {notificationsCheckboxData.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <Checkbox
                    onChange={(e) => {
                      setFilters((prevFilters) => {
                        const updatedNotificationType = e.target.checked
                          ? [...prevFilters.notificationType, +e.target.value]
                          : prevFilters.notificationType.filter((item) => item !== +e.target.value);

                        return { ...prevFilters, notificationType: updatedNotificationType };
                      });
                    }}
                    data-testid={option.value}
                    checked={filters.notificationType?.includes(option.value)}
                  />
                }
                label={<p>{option.label}</p>}
              />
            ))}
          </div>
        )}
        <div className={showCalendar ? styles.modalDisplayMobile : styles.modalHide}>
          <DateIntervalPicker
            control={control}
            name="startDate"
            setStart={handleStartDate}
            setEnd={handleEndDate}
            startDate={startDate}
            endDate={endDate}
            excludeStartDate={excludeStartDate}
          />
        </div>
        <TabPanel value="2">
          <MovementsHistoryList movementsData={movementsData} />
        </TabPanel>
        {showErrorMessage && value === '1' ? (
          <TabPanel value="1">
            <div className={styles.containerPageBottom}>
              <EmptyDataHandler
                resource={Resources.Notificaciones}
                handleReload={() => handleNavigation(0)}
                error={notificationError}
              />
              <div className={styles.historyMovementsDesktopContainer}>
                <h3 className={styles.historyMovementsTitle}>Historial de movimientos</h3>
                <MovementsHistoryList movementsData={movementsData} />
              </div>
            </div>
          </TabPanel>
        ) : (
          <TabPanel value="1" sx={{ padding: '0' }}>
            <p className={styles.noticeMessage}>
              Las notificaciones en pantalla corresponden solo a los últimos cinco días.
            </p>
            <div className={styles.containerPageBottom}>
              <div className={styles.cardContainer}>
                {dataList?.map((item) => {
                  return (
                    <Card
                      id={item.id}
                      key={item.id}
                      resourceId={selectName(item).id}
                      name={selectName(item).name}
                      resource={item.resource}
                      criticality={item.projectCriticality}
                      members={item.members}
                      customMessage={item.customMessage}
                      isCustom={item.isCustom}
                      notification={item.notification}
                      limitDate={item.limitDate}
                      cardId={(id) => setSelectedCardId(id)}
                    />
                  );
                })}
              </div>
              <div className={styles.historyMovementsDesktopContainer}>
                <h3 className={styles.historyMovementsTitle}>Historial de movimientos</h3>
                <MovementsHistoryList movementsData={movementsData} />
              </div>
            </div>
          </TabPanel>
        )}
        <Modal
          testId="resolve-notification"
          styles={styles.modal}
          id={ModalIDs.NOTIFICATION_RESOLVE}
        >
          <ConfirmationMessage
            description={`Esta acción removerá la notificación de manera permanente. En caso de que no se resuelva la causa de la misma, esta será generada nuevamente. ¿Desea continuar de todos modos?`}
            title={'Resolver Notificación'}
            handleConfirm={(e) => onConfirm(e)}
            handleClose={(e) => onClose(e)}
          />
        </Modal>
      </TabContext>
    </>
  );
};

export default Home;
