import { FormValues } from '../types';
import { userValidation } from '../validations';
import styles from './userForm.module.css';
import { joiResolver } from '@hookform/resolvers/joi';
import { Alert, AlertTitle, Switch } from '@mui/material';
import { subYears } from 'date-fns';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, DatePicker, Dropdown, TextInput } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import CheckboxInput from 'src/components/shared/ui/inputs/checkbox';
import { getByFilterResourceRequest, getResourceRequest } from 'src/config/api';
import { AccessRoleType, ApiRoutes } from 'src/constants';
import { closeModal } from 'src/redux/modals/modalsSlice';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux/store';
import { handleSuccess } from 'src/redux/user/actions';
import { addUser, editUser, getUsers } from 'src/redux/user/thunks';
import { User } from 'src/redux/user/types';
import { AppDispatch, EmployeeTypeEnum } from 'src/types';

const UserForm = (props: { userToEdit?: User }) => {
  const { userToEdit } = props;

  const dispatch: AppDispatch<null> = useAppDispatch();
  const [userEmailValidation, setUserEmailValidation] = useState(false);
  const padding = 10;
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const getCompaniesData = async () => {
      try {
        const data = await getResourceRequest(ApiRoutes.COMPANY);
        const companiesData = data.data;
        const handledData = companiesData.map((company) => {
          return {
            label: company?.name,
            value: company?._id,
          };
        });
        setCompanies([...companies, ...handledData]);
      } catch (error) {
        console.log(error);
      }
    };
    getCompaniesData();
  }, []);

  const [switchState, setSwitchState] = useState<boolean>(false);

  useEffect(() => {
    setSwitchState(!!userToEdit?.accessTypes?.length);
  }, [userToEdit]);

  const emailValidationTrigger = async () => {
    await trigger('email');
  };

  useEffect(() => {
    if (getValues('email')) {
      emailValidationTrigger();
    }
  }, [userEmailValidation]);

  const userIsSuccess = useAppSelector((state: RootState) => state.user.isSuccess);

  const emailChangeHandler = useCallback(
    debounce(async (e) => {
      try {
        const response = await getByFilterResourceRequest(`${ApiRoutes.USER}/userExists`, {
          email: e.target.value,
        });
        if (!response.error) {
          setUserEmailValidation(false);
        }
      } catch (error) {
        setUserEmailValidation(true);
      }
    }, 1000),
    [],
  );

  const administrativeExists = userToEdit?.administrativeEmployee?.isActive;

  const operationalExists = userToEdit?.operationalEmployee?.isActive;

  const employeeTypeInitialValue = () => {
    if (userToEdit) {
      if (administrativeExists && operationalExists)
        return [EmployeeTypeEnum.ADMINISTRATIVO, EmployeeTypeEnum.OPERATIVO];
      if (administrativeExists && !operationalExists) return [EmployeeTypeEnum.ADMINISTRATIVO];
      if (operationalExists && !administrativeExists) return [EmployeeTypeEnum.OPERATIVO];
    } else {
      return [EmployeeTypeEnum.ADMINISTRATIVO];
    }
  };

  const { handleSubmit, control, reset, trigger, setValue, getValues, watch } = useForm<FormValues>(
    {
      defaultValues: {
        email: userToEdit?.email || '',
        firstName: userToEdit?.firstName || '',
        accessTypes: userToEdit?.accessTypes?.length ? userToEdit?.accessTypes : [],
        employeeType: employeeTypeInitialValue(),
        lastName: userToEdit?.lastName || '',
        company: userToEdit?.operationalEmployee?.company?._id || companies[0]?.value,
        address: userToEdit?.address || '',
        phone: userToEdit?.phone || 0,
        dni: userToEdit?.dni || 0,
        location: userToEdit?.location || '',
        birthDate: userToEdit?.birthDate || subYears(new Date(), 18).toString(),
        isActive: userToEdit?.isActive || true,
      },
      mode: 'onBlur',
      resolver: joiResolver(userValidation(userEmailValidation)),
    },
  );

  const employeeType = watch('employeeType');

  useEffect(() => {
    employeeTypeInitialValue();
  }, []);

  const onClose = () => {
    reset();
    dispatch(closeModal());
  };

  // TODO: Refactor access roles when radium accountancy app is developed
  const accessRolesCheckboxData = [
    {
      label: 'Accountancy AD',
      value: AccessRoleType.ACCOUNTANCY_AD,
      // disabled: accessTypesCheckbox.includes(AccessRoleType.ACCOUNTANCY_AL),
      disabled: true,
    },
    {
      label: 'Accountancy AL',
      value: AccessRoleType.ACCOUNTANCY_AL,
      // disabled: accessTypesCheckbox.includes(AccessRoleType.ACCOUNTANCY_AD),
      disabled: true,
    },
    { label: 'Accountancy AM', value: AccessRoleType.ACCOUNTANCY_AM, disabled: true },
    { label: 'Admin AM', value: AccessRoleType.ADMIN_AM },
  ];

  const employeeTypeCheckboxData = [
    { value: EmployeeTypeEnum.ADMINISTRATIVO, label: 'Administrativo' },
    { value: EmployeeTypeEnum.OPERATIVO, label: 'Operativo' },
  ];

  const company = watch('company');

  const onSubmit = async (data) => {
    data = {
      ...data,
      birthDate: data?.birthDate.toISOString(),
      email: getValues('email'),
      company: employeeType?.includes(EmployeeTypeEnum.OPERATIVO) ? company : undefined,
    };

    userToEdit
      ? await dispatch(editUser({ id: userToEdit._id, body: data }))
      : await dispatch(addUser(data));

    dispatch(getUsers());
  };

  useEffect(() => {
    if (userIsSuccess) {
      onClose();
      dispatch(handleSuccess());
    }
  }, [userIsSuccess]);

  useEffect(() => {
    const employeeTypeValues = watch('employeeType');
    if (employeeTypeValues) {
      const isOperativoSelected = employeeTypeValues?.includes(EmployeeTypeEnum.OPERATIVO);
      setValue(
        'company',
        isOperativoSelected
          ? userToEdit?.operationalEmployee?.company?._id || companies[0]?.value
          : company,
      );
    }
  }, [watch('employeeType')]);

  return (
    <div className={styles.formContainer}>
      <form>
        <div className={styles.addUserMessage}>Agregar usuario</div>

        <div className={styles.inputsContainer}>
          <div className={styles.firstInputsColumn}>
            <TextInput
              control={control}
              testId={'firstNameInput'}
              placeholder="Nombre"
              name="firstName"
              type={'text'}
              inputProps={{
                style: {
                  padding: padding,
                },
              }}
              error
            />
            <TextInput
              control={control}
              testId={'lastNameInput'}
              placeholder="Apellido"
              name="lastName"
              type={'text'}
              inputProps={{
                style: {
                  padding: padding,
                },
              }}
              error
            />
            <TextInput
              control={control}
              testId={'dniInput'}
              label="DNI / Cédula de identidad"
              name="dni"
              type={'number'}
              inputProps={{
                style: {
                  padding: padding,
                },
              }}
              error
            />
            <TextInput
              control={control}
              testId={'phoneInput'}
              label="Teléfono"
              name="phone"
              type={'number'}
              inputProps={{
                style: {
                  padding: padding,
                },
              }}
              error
            />
            <TextInput
              control={control}
              testId={'emailInput'}
              placeholder="Email"
              name="email"
              type={'text'}
              variant="outlined"
              inputProps={{
                style: {
                  padding: padding,
                },
              }}
              error
              handleOnChange={emailChangeHandler}
            />
          </div>
          <div className={styles.secondInputsColumn}>
            <Dropdown
              disabled={!employeeType?.includes(EmployeeTypeEnum.OPERATIVO)}
              control={control}
              testId={'companyType'}
              label="Compañía"
              name="company"
              options={companies}
              fullWidth
            />
            {!employeeType?.includes(EmployeeTypeEnum.OPERATIVO) && (
              <Alert severity="info" sx={{ marginBottom: 3, marginTop: 0 }}>
                Info — <strong>El país es solo para operativos</strong>
              </Alert>
            )}
            <TextInput
              control={control}
              testId={'locationInput'}
              placeholder="Localidad"
              name="location"
              type={'text'}
              inputProps={{
                style: {
                  padding: padding,
                },
              }}
              error
            />
            <div className={styles.dropDownAndDatePicker}>
              <DatePicker
                label={'Fecha de nacimiento'}
                testId={'birthDate'}
                name="birthDate"
                maxDate={subYears(new Date(), 18)}
                control={control}
              />
            </div>
            <TextInput
              control={control}
              testId={'addressInput'}
              placeholder="Dirección"
              name="address"
              type={'text'}
              size="small"
              inputProps={{
                style: {
                  padding: padding,
                },
              }}
              error
            />
          </div>
          <div className={styles.lastInputsColumn}>
            <CheckboxInput
              title="Tipo de empleado:"
              testId={'checkbox-input'}
              name="employeeType"
              control={control}
              options={employeeTypeCheckboxData}
            />
            <div>
              <span>Roles de acceso: </span>
              <Switch
                checked={switchState}
                onChange={() => setSwitchState(!switchState)}
                inputProps={{ 'aria-label': 'controlled' }}
                onClick={() => setValue('accessTypes', [], { shouldDirty: true })}
              />
            </div>
            {switchState && (
              <>
                <Alert severity="warning">
                  <AlertTitle>Cuidado</AlertTitle>
                  Cuidado — <strong>¡Está a punto de dar acceso a información sensible!</strong>
                </Alert>
                <CheckboxInput
                  testId={'checkbox-input'}
                  name="accessTypes"
                  control={control}
                  options={accessRolesCheckboxData}
                />
              </>
            )}
            <div className={styles.buttonsContainer}>
              <Button
                testId="resetBtn"
                materialVariant={Variant.OUTLINED}
                label="Cancelar"
                onClick={onClose}
              />
              <Button
                testId="submitBtn"
                materialVariant={Variant.CONTAINED}
                label="Confirmar"
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserForm;
