import styles from './checkbox.module.css';
import { CheckProps } from './types';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';
import { FieldValues, useController, useWatch } from 'react-hook-form';
import { AccessRoleType } from 'src/constants';

const CheckboxInput = <Form extends FieldValues>(
  props: CheckProps<string | AccessRoleType, Form>,
): JSX.Element => {
  const { control, name, options, testId, title } = props;
  const {
    field: { ref, onChange, value },
  } = useController({
    name,
    control,
  });
  const checkboxValues = useWatch({ control, name: name }) || [];

  const handleChange = (changedValues) => {
    const selectedValues = [...checkboxValues];

    if (selectedValues.length > 0) {
      const index = selectedValues.findIndex((item) => item === changedValues);
      if (index === -1) {
        selectedValues.push(changedValues);
      } else {
        selectedValues.splice(index, 1);
      }
    } else {
      selectedValues.push(changedValues);
    }
    onChange(selectedValues);
  };

  return (
    <div className={styles.formContainer}>
      <FormControl>
        <label>{title}</label>
        <FormGroup row>
          {options?.map((option) => (
            <FormControlLabel
              value={value && value['value']}
              control={
                <Checkbox
                  inputRef={ref}
                  onChange={() => handleChange(option['value'])}
                  data-testid={option['value']}
                  checked={value?.some((item) => item === option['value'])}
                  disabled={option.disabled && option.disabled}
                />
              }
              label={<p>{option['label']}</p>}
              key={option['value']}
              data-testid={testId}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default CheckboxInput;
