export enum Actions {
  GET_NOTIFICATIONS_PENDING = 'GET_NOTIFICATIONS_PENDING',
  GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR',
  DELETE_NOTIFICATION_PENDING = 'DELETE_NOTIFICATION_PENDING',
  DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS',
  DELETE_NOTIFICATION_ERROR = 'DELETE_NOTIFICATION_ERROR',
  ADD_NOTIFICATION_PENDING = 'ADD_NOTIFICATION_PENDING',
  ADD_NOTIFICATION_SUCCESS = 'ADD_NOTIFICATION_SUCCESS',
  ADD_NOTIFICATION_ERROR = 'ADD_NOTIFICATION_ERROR',
  CLEAN_SELECTED_NOTIFICATION = 'CLEAN_SELECTED_NOTIFICATION',
}
