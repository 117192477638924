import { ReqStates } from '../../../salaryRanges/types';
import styles from './growthPlanForm.module.css';
import { FormValues, defaultGrowthValues, objectiveDates } from './types';
import { growthPlanValidations } from './validations';
import { joiResolver } from '@hookform/resolvers/joi';
import { Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button, Dropdown, TextInput } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import ClockLoader from 'src/components/shared/ui/clockloader';
import { addResourceRequest, editResourceRequest } from 'src/config/api';
import { ApiRoutes, roles } from 'src/constants/index';
import { editEmployeeSuccess, setSelectedEmployee } from 'src/redux/employee/actions';
import { getEmployeeById } from 'src/redux/employee/thunk';
import { closeModal } from 'src/redux/modals/modalsSlice';
import { RootState, useAppSelector } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toastSlice';
import { AppDispatch } from 'src/types';

const GrowthPlanForm = () => {
  const dispatch: AppDispatch<null> = useDispatch();
  const selectedEmployee = useAppSelector((state: RootState) => state.employee?.selectedEmployee);
  const [selectedDate, setSelectedDate] = React.useState<boolean>(false);
  const [selectedYear, setSelectedYear] = React.useState<string>('');
  const [reqState, setReqState] = React.useState<ReqStates>(ReqStates.NONE);
  const [displayGuide, setDisplayGuide] = React.useState<boolean>(true);
  const {
    user: { firstName, lastName },
    _id: employeeId,
  } = useAppSelector((state) => state.employee.selectedEmployee);
  const {
    formState: { isDirty, isValid, isValidating },
    handleSubmit,
    control,
    watch,
    getValues,
    reset,
  } = useForm<FormValues>({
    defaultValues: defaultGrowthValues,
    mode: 'onChange',
    resolver: joiResolver(growthPlanValidations),
  });

  React.useEffect(() => {
    if (!selectedEmployee) dispatch(getEmployeeById(employeeId));
  }, [selectedEmployee]);

  const formChanged = (!isDirty && !isValidating) || !isValid;

  const handleDateChange = () => {
    if (selectedEmployee) {
      const selectedGrowthPlan = selectedEmployee.growthPlan.find((plan) => {
        if (typeof plan.objectiveDate === 'string') {
          return (
            (plan.objectiveDate as string).split('T')[0].split('-').splice(0, 2).join('-') ===
            `${getValues('objectiveYear')}-${getValues('objectiveDate')?.split('-')[0]}`
          );
        }
        return false;
      });
      if (selectedGrowthPlan) {
        setDisplayGuide(false);
        const {
          objectiveDate: selectedObjectiveDate,
          employee: _emp,
          ...rest
        } = selectedGrowthPlan;
        return reset({
          objectiveDate: (selectedObjectiveDate as string)
            .split('T')[0]
            .split('-')
            .splice(1, 3)
            .join('-'),
          objectiveYear: (selectedObjectiveDate as string).split('T')[0].split('-')[0],
          ...rest,
        });
      }
    }
    if (getValues('objectiveDate') !== undefined) setDisplayGuide(false);
    setSelectedDate(true);
  };

  const onSubmit = async (data: FormValues) => {
    const commonBody = {
      objectiveRole: data.objectiveRole,
      objectiveLevel: data.objectiveLevel,
      objectiveSalary: data.objectiveSalary,
      description: data.description,
    };
    try {
      setReqState(ReqStates.LOADING);
      const response =
        data._id === ''
          ? await addResourceRequest(ApiRoutes.GROWTH_PLAN, {
              ...commonBody,
              employee: employeeId,
              objectiveDate: `${data.objectiveYear}-${data.objectiveDate}T12:00:00Z`,
            })
          : await editResourceRequest(ApiRoutes.GROWTH_PLAN, {
              id: data._id,
              body: commonBody,
            });
      const updatedEmployee = {
        ...selectedEmployee,
        growthPlan: [
          ...selectedEmployee.growthPlan.filter((plan) => plan._id !== response.data._id),
          response.data,
        ],
      };
      dispatch(editEmployeeSuccess(updatedEmployee, employeeId));
      dispatch(setSelectedEmployee(updatedEmployee));
      dispatch(
        openToast({
          message: `Plan de crecimiento ${data._id ? 'editado' : 'agregado'} con exito`,
          error: false,
        }),
      );
    } catch (error) {
      dispatch(openToast({ message: error.message, error: true }));
    } finally {
      setReqState(ReqStates.DONE);
      dispatch(closeModal());
    }
  };

  const objectiveLevel = [];

  const objectiveRoleWatch = watch('objectiveRole');

  switch (objectiveRoleWatch) {
    case 'DEV':
    case 'UX/UI':
    case 'QA':
      for (let i = 1; i <= 10; i++) {
        const element = { value: i, label: i };
        objectiveLevel.push(element);
      }
      break;
    case 'TL':
    case 'UX/UI L':
    case 'QA L':
      for (let i = 1; i <= 3; i++) {
        const element = { value: i, label: i };
        objectiveLevel.push(element);
      }
      break;
    case 'PM':
      for (let i = 1; i <= 5; i++) {
        const element = { value: i, label: i };
        objectiveLevel.push(element);
      }
      break;
    default:
      break;
  }

  return (
    <div className={styles.modalContainer}>
      <div className={styles.headerGrowthPlan} data-testid={'headerMessage'}>
        <p>{`Plan de crecimiento de ${firstName} ${lastName}`}</p>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.growthPlanForm}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.inputsContainer}>
              <div>
                {displayGuide && <Typography>Seleccione año y mes objetivo</Typography>}
                <div className={styles.topContainer}>
                  <Dropdown
                    control={control}
                    testId={'dates-dropdown'}
                    label="Año Objetivo"
                    name="objectiveYear"
                    customChangeHandler={(e) => {
                      handleDateChange();
                      setSelectedYear(e.target.value);
                    }}
                    options={[
                      {
                        value: `${new Date().getFullYear()}`,
                        label: `${new Date().getFullYear()}`,
                      },
                      {
                        value: `${new Date().getFullYear() + 1}`,
                        label: `${new Date().getFullYear() + 1}`,
                      },
                    ]}
                    fullWidth
                  />
                  <Dropdown
                    control={control}
                    testId={'dates-dropdown'}
                    label={'Mes Objetivo'}
                    disabled={!selectedYear}
                    name="objectiveDate"
                    customChangeHandler={handleDateChange}
                    options={React.useMemo(() => {
                      return objectiveDates.filter(
                        (date) => new Date(`${selectedYear}-${date.value}T12:00:00Z`) >= new Date(),
                      );
                    }, [selectedYear])}
                    fullWidth
                  />
                  <TextInput
                    control={control}
                    testId={'objective-salary'}
                    label="Salario Objetivo"
                    name="objectiveSalary"
                    type="number"
                    variant="outlined"
                    fullWidth
                    disabled={!selectedDate}
                  />
                </div>
                <div className={styles.bottomContainer}>
                  <Dropdown
                    name="objectiveRole"
                    control={control}
                    testId={'roles-dropdown'}
                    options={roles}
                    label={'Rol Objetivo'}
                    fullWidth
                    disabled={!selectedDate}
                  />
                  <Dropdown
                    control={control}
                    testId={'levels-dropdown'}
                    label={'Nivel Objetivo'}
                    name="objectiveLevel"
                    options={objectiveLevel}
                    fullWidth
                    disabled={!objectiveRoleWatch}
                  />
                </div>
                <div className={styles.textArea}>
                  <TextInput
                    control={control}
                    testId={'custom-description'}
                    name="description"
                    type={'text'}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={5}
                    disabled={!selectedDate}
                    label={'Descripción'}
                  />
                </div>
              </div>
            </div>
            <div className={styles.buttonsContainer}>
              <div>
                <Button
                  testId="cancelButton"
                  materialVariant={Variant.OUTLINED}
                  onClick={() => dispatch(closeModal())}
                  label="Cancelar"
                />
              </div>
              <div>
                <Button
                  testId="confirmButton"
                  materialVariant={Variant.CONTAINED}
                  onClick={handleSubmit(onSubmit)}
                  label={'Guardar Cambios'}
                  disabled={formChanged}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      {reqState === ReqStates.LOADING && <ClockLoader hasBackground />}
    </div>
  );
};

export default GrowthPlanForm;
