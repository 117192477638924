import Joi from 'joi';
import { AbsenceMotives } from 'src/types';

export const absencesValidations = Joi.object({
  motive: Joi.string()
    .valid(AbsenceMotives.LICENSE, AbsenceMotives.STUDY, AbsenceMotives.VACATIONS)
    .messages({
      'any.only': 'Este campo es requerido',
    }),
}).options({ allowUnknown: true });
