import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  Clients,
  ClientsForm,
  EditEmployee,
  Employees,
  Home,
  PerformanceFeedback,
  LastPerformanceFeedback,
  ProjectMembersLayout,
  Projects,
  Availability,
  AvailabilityById,
} from 'src/components/pages';
import { UiRoutes } from 'src/constants';

const Admin = (): JSX.Element => {
  return (
    <Routes>
      <Route path={'/home'} element={<Home />} />
      <Route path={UiRoutes.EMPLOYEES} element={<Employees />} />
      <Route path={`${UiRoutes.EDIT_EMPLOYEES}/:id`} element={<EditEmployee />} />
      <Route
        path={`${UiRoutes.EDIT_EMPLOYEES}/:id${UiRoutes.PERFORMANCE_FEEDBACK}`}
        element={<PerformanceFeedback />}
      />
      <Route
        path={`${UiRoutes.EDIT_EMPLOYEES}/:employeeId${UiRoutes.PERFORMANCE_FEEDBACK}/:performanceId`}
        element={<LastPerformanceFeedback />}
      />
      <Route path={`${UiRoutes.EMPLOYEES}${UiRoutes.AVAILABILITY}`} element={<Availability />} />
      <Route
        path={`${UiRoutes.EMPLOYEES}${UiRoutes.AVAILABILITY}/:id`}
        element={<AvailabilityById />}
      />
      <Route path={UiRoutes.CLIENTS} element={<Clients />} />
      <Route path={UiRoutes.CLIENTS_FORM} element={<ClientsForm />} />
      <Route path={`${UiRoutes.CLIENTS_FORM}/:id`} element={<ClientsForm />} />
      <Route path={UiRoutes.PROJECTS} element={<Projects />} />
      <Route path={UiRoutes.PROJECTS_FORM} element={<ProjectMembersLayout />} />
      <Route path={`${UiRoutes.PROJECTS_FORM}/:id`} element={<ProjectMembersLayout />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default Admin;
