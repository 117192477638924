import { ErrorFormat } from '../types';
import { Actions } from './constants';
import { User } from './types';
import { action } from 'typesafe-actions';

export const getUsersPending = () => action(Actions.GET_USERS_PENDING);

export const getUsersSuccess = (users: User[]) => action(Actions.GET_USERS_SUCCESS, users);

export const getUsersError = (error: ErrorFormat) => action(Actions.GET_USERS_ERROR, error);

export const setResourceName = (resource: string) => action(Actions.SET_RESOURCE_NAME, resource);

export const getUserByUidPending = () => action(Actions.GET_USER_BY_UID_PENDING);
export const getUserByUidSuccess = (user: User) => action(Actions.GET_USER_BY_UID_SUCCESS, user);
export const getUserByUidError = (error: ErrorFormat) =>
  action(Actions.GET_USER_BY_UID_ERROR, error);

export const addUserPending = () => action(Actions.ADD_USER_PENDING);

export const addUserSuccess = (user: User) => action(Actions.ADD_USER_SUCCESS, user);

export const addUsersError = (error: ErrorFormat) => action(Actions.ADD_USER_ERROR, error);

export const handleSuccess = () => action(Actions.HANDLE_SUCCESS);

export const addUsersFromCSVPending = () => action(Actions.ADD_USER_FROM_CSV_PENDING);

export const addUsersFromCSVSuccess = (users: User[]) =>
  action(Actions.ADD_USER_FROM_CSV_SUCCESS, users);

export const addUsersFromCSVError = (error: ErrorFormat) =>
  action(Actions.ADD_USER_FROM_CSV_ERROR, error);

export const editUserSuccess = (response: User, id: string) =>
  action(Actions.EDIT_USER_SUCCESS, { response, id });

export const editUserPending = () => action(Actions.EDIT_USER_PENDING);

export const editUserError = (error: ErrorFormat) => action(Actions.EDIT_USER_ERROR, error);

export const deleteUserSuccess = (id: string) => action(Actions.DELETE_USER_SUCCESS, id);

export const deleteUserPending = () => action(Actions.DELETE_USER_PENDING);

export const deleteUserError = (error: ErrorFormat) => action(Actions.DELETE_USER_ERROR, error);

export const activateUserSuccess = (id: string) => action(Actions.ACTIVATE_USER_SUCCESS, id);

export const activateUserPending = () => action(Actions.ACTIVATE_USER_PENDING);

export const activateUserError = (error: ErrorFormat) => action(Actions.ACTIVATE_USER_ERROR, error);

export const cleanUserError = () => action(Actions.CLEAN_USER_ERROR);
