import { format } from 'date-fns';

export const capitalizeFirstLetter = (text: string) => {
  if (text?.length) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return '';
};

export const formattedTableData = (list, firstField: string, secondField?: string) => {
  const dataList = list.filter((item) =>
    secondField
      ? item[firstField]?.isActive || item[firstField]?.active
      : item?.active || item?.isActive,
  );
  const emptyString = '';
  const result = dataList.reduce((data, iterator) => {
    return `${data}!${secondField ? iterator?.[firstField][secondField] : iterator?.[firstField]} `;
  }, emptyString);
  return result;
};

export const getMonthName = (monthNumber) => {
  const date = new Date();
  date.setDate(1);
  date.setMonth(monthNumber - 1);

  const monthName = date.toLocaleString('es-ES', { month: 'short' });

  const capitalizedMonthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);

  return capitalizedMonthName;
};

export const formatHours = (stringedHours: string) => {
  if (stringedHours) {
    if (!stringedHours.includes(':')) {
      const length = stringedHours.length;
      let minutes = stringedHours.substring(length - 2, length);
      let hours = stringedHours.substring(0, length - 2);
      if (minutes.length === 1) minutes = `0${minutes}`;
      if (hours.length === 1) hours = `0${hours}`;
      return [hours, minutes];
    } else if (stringedHours.includes(':')) {
      const splitted = stringedHours.split(':');
      if (splitted[0].length === 1) splitted[0] = `0${splitted[0]}`;
      if (splitted[1].length === 1) splitted[1] = `0${splitted[1]}`;
      return [splitted[0], splitted[1]];
    }
  } else {
    return ['', ''];
  }
};

export const dateFormatter = (startDate, endDate) => {
  if (!startDate) {
    return 'No hay datos';
  } else if (!endDate) {
    return `Desde ${format(new Date(startDate), 'dd/MM/yyyy')}`;
  } else {
    return `${startDate && format(new Date(startDate), 'dd/MM/yyyy')} a ${format(
      new Date(endDate),
      'dd/MM/yyyy',
    )}`;
  }
};

export const tableRowDataFormatter = (list, indexer: string) => {
  const formattedList = list.map((item) => {
    const { [indexer]: data, ...rest } = item;
    const fieldArray = data.split('!').filter((item) => item.length > 3);
    if (fieldArray.length > 1) {
      return {
        ...rest,
        [indexer]: `${fieldArray[0]} y ${fieldArray.length - 1} más`,
      };
    } else if (fieldArray.length === 1) {
      return {
        ...rest,
        [indexer]: `${fieldArray[0]}`,
      };
    }
    return {
      ...rest,
      [indexer]: ' - ',
    };
  });
  return formattedList;
};

export const cutLastLetter = (string) => string.slice(0, string.length - 1);

export const formatDate = (dateString: string | undefined): string => {
  if (!dateString) {
    return '-';
  }
  const date = new Date(dateString);
  const utcDate = new Date(date.toISOString());
  const day = utcDate.getUTCDate().toString().padStart(2, '0');
  const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = utcDate.getUTCFullYear().toString();
  return `${day}/${month}/${year}`;
};
