import { generateIntArrayWithRange } from './generateIntArrayWithRange';
import { RadiumRoleType, RankType } from 'src/types';

// TODO: calculate rank based on role
export const calculateRanksBasedOnRole = (role: RadiumRoleType): RankType[] => {
  if (!role) return null;

  if (role === RadiumRoleType.TRAINEE) return [0];

  if (
    role === RadiumRoleType.CREW_MEMBER ||
    role === RadiumRoleType.EXPLORER ||
    role === RadiumRoleType.COMMANDER
  ) {
    return generateIntArrayWithRange(1, 3);
  }

  if (role === RadiumRoleType.CAPTAIN) {
    return generateIntArrayWithRange(1, 5);
  }

  if (role === RadiumRoleType.PILOT || role === RadiumRoleType.LIEUTENANT) {
    return generateIntArrayWithRange(1, 2);
  }
  return [];
};
