import Absences from '../Absences';
import Form from '../Form';
import Header from '../Header';
// import PerformanceFeedback from '../PerformanceFeedbacks';
import Projects from '../Projects';
import { CompleteFormType } from '../types';
import ConfirmButton from './ConfirmButton';
import styles from './completeForm.module.css';
import { CompleteFormTypeSchema } from './validations';
import { joiResolver } from '@hookform/resolvers/joi';
import { useState } from 'react';
import React, { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ConfirmationMessage, Loader, Modal } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import { addResourceRequest, deleteResourceRequest, editResourceRequest } from 'src/config/api';
import { ApiRoutes, UiRoutes } from 'src/constants';
import { getEmployeeById } from 'src/redux/employee/thunk';
import { AddMemberDTO } from 'src/redux/member/types';
import { ModalIDs, closeModal } from 'src/redux/modals/modalsSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { openToast } from 'src/redux/toast/toastSlice';
import { EmployeeTypeEnum } from 'src/types';

function CompleteForm() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading, error, selectedEmployee } = useAppSelector((state) => state.employee);
  const [loading, setLoading] = useState(false);
  const params = useParams();

  const methods = useForm<CompleteFormType>({
    defaultValues: {
      form: {
        _id: selectedEmployee._id,
        email: selectedEmployee.user?.email,
        DoB: selectedEmployee.user?.birthDate,
        dedicatedHours: selectedEmployee.availability.dedicatedHours.toString(),
        availableHours: selectedEmployee.availability.availableHours.toString(),
        availabilityDescription: selectedEmployee.availability.description,
        availabilityId: selectedEmployee.availability._id,
        role: selectedEmployee.role,
        rank: selectedEmployee.rank.toString(),
        expertiseArea: selectedEmployee.expertiseArea,
        skills: selectedEmployee.skills,
        notes: selectedEmployee.notes,
      },
      projects: {
        membersList: selectedEmployee.memberHistory,
        membersToPatch: [],
        membersToPost: [],
        membersToDelete: [],
      },
      absences: {
        absencesList: selectedEmployee.absences,
        absenceToPost: [],
        absenceToEdit: [],
      },
    },
    mode: 'onBlur',
    resolver: joiResolver(CompleteFormTypeSchema),
  });

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const onSubmit: SubmitHandler<CompleteFormType & { exit: boolean }> = async (data) => {
    setLoading(true);
    try {
      for (const member of data.projects.membersToPost) {
        const { id: _id, ...memberToCreate } = member;
        const response = await addResourceRequest<AddMemberDTO>(ApiRoutes.MEMBER, memberToCreate);
        if (response.error) {
          throw new Error(response.message);
        }
      }

      for (const memberToEdit of data.projects.membersToPatch) {
        const response = await editResourceRequest(ApiRoutes.MEMBER, memberToEdit);
        if (response.error) {
          throw new Error(response.message);
        }
      }

      for (const m of data.projects.membersToDelete) {
        const response = await deleteResourceRequest(ApiRoutes.MEMBER, m.memberId);
        if (response.error) {
          throw new Error(response.message);
        }
      }

      for (const absence of data.absences.absenceToPost) {
        const { ...absenceToCreate } = absence;
        const response = await addResourceRequest(ApiRoutes.ABSENCE, absenceToCreate);
        if (response.error) {
          throw new Error(response.message);
        }
      }

      for (const absence of data.absences.absenceToEdit) {
        const { ...absenceToEdit } = absence;
        const response = await editResourceRequest(ApiRoutes.ABSENCE, absenceToEdit);
        if (response.error) {
          throw new Error(response.message);
        }
      }

      if (isDirty) {
        const formBody = {
          notes: data.form.notes,
          _id: data.form._id,
          role: data.form.role,
          rank: +data.form.rank,
          expertiseArea: data.form.expertiseArea,
          user: selectedEmployee.user._id,
          skills: data.form.skills.map((s) => s._id),
          availability: {
            description: data.form.availabilityDescription,
            availableHours: +data.form.availableHours,
            dedicatedHours: selectedEmployee?.memberHistory?.reduce((totalDedication, project) => {
              if (project.active) {
                return totalDedication + project.memberDedication;
              }
              return totalDedication;
            }, 0),
          },
          employeeType: EmployeeTypeEnum.OPERATIVO,
        };

        const { _id, ...rest } = formBody;
        await editResourceRequest(ApiRoutes.EMPLOYEE, { body: rest, id: _id });
      }
      dispatch(openToast({ message: 'Empleado editado con éxito', error: false }));
    } catch (error) {
      dispatch(openToast({ message: error.message, error: true }));
    } finally {
      setLoading(false);
      dispatch(closeModal());

      if (data.exit) {
        navigate(`${UiRoutes.ADMIN_AM}${UiRoutes.EMPLOYEES}`);
      } else {
        dispatch(getEmployeeById(params.id));
      }
    }
  };

  const onInvalidSubmit = (e) => {
    console.error('Joi validation error', e);
    dispatch(openToast({ message: "There's been an error validating the form", error: true }));
  };

  return (
    <FormProvider {...methods}>
      <div className={styles.pageContainer}>
        <form>
          <Header />
          <div className={styles.bodyContainer}>
            <div className={styles.leftSide}>
              {!isLoading && !error && Object.keys(selectedEmployee).length && <Form />}
              <div className={styles.buttonsContainer}>
                <ConfirmButton />
                <Button
                  testId="cancel-button"
                  materialVariant={Variant.OUTLINED}
                  onClick={() => navigate(`${UiRoutes.ADMIN_AM}${UiRoutes.EMPLOYEES}`)}
                  label="Cancelar"
                />
              </div>
            </div>
            <div className={styles.rightSide}>
              <Projects />
              <Absences />
              {/* <PerformanceFeedback /> */}
            </div>
          </div>

          <Modal testId="edit-employee-modal" styles={styles.modal} id={ModalIDs.EMPLOYEE_CONFIRM}>
            {!loading ? (
              <ConfirmationMessage
                description={`¿Desea guardar cambios y editar al empleado ${selectedEmployee?.user?.firstName} ${selectedEmployee?.user?.lastName}?`}
                title={'Editar Empleado'}
                handleConfirm={() =>
                  handleSubmit(
                    (formData: CompleteFormType) => onSubmit({ ...formData, exit: false }),
                    onInvalidSubmit,
                  )()
                }
                handleClose={() => dispatch(closeModal())}
                handleConfirmAndExit={() => {
                  handleSubmit(
                    (formData: CompleteFormType) => onSubmit({ ...formData, exit: true }),
                    onInvalidSubmit,
                  )();
                }}
                confirmAndExitLabel="Confirmar y volver"
              />
            ) : (
              <Loader />
            )}
          </Modal>
        </form>

        {error && <div>There was an erorr fetching the employee</div>}
      </div>
    </FormProvider>
  );
}

export default CompleteForm;
