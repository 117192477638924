import { ServerResponse } from '../types';
import { Skills } from './types';
import { useQuery } from 'react-query';
import { getResourceRequest } from 'src/config/api';
import { ApiRoutes } from 'src/constants';
import store from 'src/redux/store';
import { openToast } from 'src/redux/toast/toastSlice';

const getSkills = async () => {
  try {
    const response: ServerResponse<Skills> = await getResourceRequest(ApiRoutes.SKILLS);
    if (response.error) {
      throw new Error(`HTTP error! Status: ${response.message}`);
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error.message);
    throw error;
  }
};

export const useSkillsQuery = () => {
  return useQuery({
    queryKey: ['Skills'],
    queryFn: getSkills,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60000,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      return store.dispatch(
        openToast({
          message: error.message,
          error: true,
        }),
      );
    },
  });
};
