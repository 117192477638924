import { AxiosResponse } from 'axios';
import { setAuthError } from 'src/redux/auth/actions';
import store from 'src/redux/store';
import { ErrorType } from 'src/redux/types';

export const responseBody = (response: AxiosResponse) => response.data;

export const parseError = (error) => {
  if (error.code === ErrorType.NETWORK_ERROR) {
    throw {
      message: error.message,
      errorType: ErrorType.NETWORK_ERROR,
    };
  }
  if (error.response.status === 403) {
    store.dispatch(setAuthError(true));

    throw {
      message: error.response.data.message,
      errorType: ErrorType.AUTH_ERROR,
    };
  }
  throw {
    message: error.response.data.message,
    errorType: ErrorType.CLIENT_ERROR,
  };
};
