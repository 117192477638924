import { CompleteFormType } from '../types';
import AbsencesModal from './absencesModal';
import { absenceHeadersEmp } from './constants';
import styles from './styles.module.css';
import { IconButton, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { BsCheckLg } from 'react-icons/bs';
import { FaBan, FaXmark } from 'react-icons/fa6';
import { RxCross2 } from 'react-icons/rx';
import { Modal } from 'src/components/shared/ui';
import AsideTable from 'src/components/shared/ui/asideTable';
import { EditAbsenceDTO } from 'src/redux/member/types';
import { ModalIDs, closeModal, openModal } from 'src/redux/modals/modalsSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { AbsenceState } from 'src/types';
import { formatDate } from 'src/utils/formatters';

function Absences() {
  const dispatch = useAppDispatch();
  const { absences, _id } = useAppSelector((state) => state.employee.selectedEmployee);
  const activeModal = useAppSelector((state) => state.modals.activeModal);
  const { getValues: getContextValues, setValue: setContextValue } =
    useFormContext<CompleteFormType>();

  const handleAbsences = (id, state) => {
    const contextAbsenceList = getContextValues('absences.absencesList');
    const originalAbsence = contextAbsenceList.find((item) => item._id === id);
    const originalAbsenceIndex = contextAbsenceList.findIndex((item) => item._id === id);

    const editedAbsence = {
      ...originalAbsence,
      state: state,
    };

    contextAbsenceList[originalAbsenceIndex] = editedAbsence;
    setContextValue('absences.absencesList', contextAbsenceList);

    const absenceToEdit: EditAbsenceDTO = {
      id: originalAbsence._id,
      body: {
        startDate: originalAbsence.startDate,
        endDate: originalAbsence.endDate,
        state: state,
      },
    };
    setContextValue(
      'absences.absenceToEdit',
      [...getContextValues('absences.absenceToEdit'), { ...absenceToEdit }],
      { shouldDirty: true },
    );
  };

  const formattedAbsences = useMemo(() => {
    const absencesList = getContextValues('absences.absencesList');

    if (!absencesList) {
      return [];
    }

    const sortedAbsences = absencesList
      .sort((a, b) => {
        const dateA = new Date(a.startDate);
        const dateB = new Date(b.startDate);
        return dateA < dateB ? 1 : -1;
      })
      .map((item) => {
        let iconContent: React.JSX.Element;

        if (item.state === AbsenceState.PENDING) {
          iconContent = (
            <>
              <Tooltip title={'Aprobar'} placement="top" arrow>
                <IconButton onClick={() => handleAbsences(item._id, AbsenceState.APPROVED)}>
                  <BsCheckLg color={'green'} size={'20px'} />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Rechazar'} placement="top" arrow>
                <IconButton onClick={() => handleAbsences(item._id, AbsenceState.REJECTED)}>
                  <RxCross2 color={'red'} size={'20px'} />
                </IconButton>
              </Tooltip>
            </>
          );
        } else if (item.state === AbsenceState.APPROVED) {
          iconContent = (
            <Tooltip title={'Cancelar'} placement="top" arrow>
              <IconButton
                className={styles.cancelButton}
                onClick={() => handleAbsences(item._id, AbsenceState.CANCELED)}
              >
                <FaBan color={'#373867'} size={'15px'} />
              </IconButton>
            </Tooltip>
          );
        }

        return {
          motive: item.motive,
          startDate: formatDate(item?.startDate?.toString()),
          endDate: formatDate(item?.endDate?.toString()),
          state: item.state,
          icon: iconContent,
        };
      });
    return sortedAbsences;
  }, [getContextValues('absences.absencesList')]);

  const pendingAbsences = absences.filter((absence) => absence.state === AbsenceState.PENDING);

  return (
    <div>
      <AsideTable
        title="Ausencias Programadas"
        isAbsencesTable
        pendingAbsences={pendingAbsences.length}
        headers={absenceHeadersEmp}
        testId="absences-table"
        dataList={formattedAbsences.slice(0, 2)}
        showFormModal={() => dispatch(openModal(ModalIDs.EMPLOYEE_ABSENCES_FORM))}
        showHistoryModal={() => dispatch(openModal(ModalIDs.EMPLOYEE_ABSENCES_LIST))}
        showPlusButton
        showViewMore={formattedAbsences.length > 2}
      />

      {activeModal && (
        <div>
          <Modal testId={'employee-absences-modal'} id={ModalIDs.EMPLOYEE_ABSENCES_FORM}>
            <AbsencesModal absences={absences} employeeId={_id} />
          </Modal>
          <Modal testId="absences-modal" id={ModalIDs.EMPLOYEE_ABSENCES_LIST}>
            <div className={styles.modalContainer}>
              <div className={styles.crossIcon}>
                <IconButton aria-label="close" onClick={() => dispatch(closeModal())}>
                  <FaXmark color={'#373867'} fontSize={'20px'} cursor={'pointer'} />
                </IconButton>
              </div>
              <div className={styles.modalTableContainer}>
                <AsideTable
                  title="Ausencias Programadas"
                  headers={absenceHeadersEmp}
                  testId="aside-table"
                  dataList={formattedAbsences}
                />
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default Absences;
