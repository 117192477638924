import { CompleteFormType } from '../../types';
import styles from './absences.module.css';
import { motiveOptions } from './constants';
import { AbsencesModalProps, FormAbsencesValue } from './types';
import { absencesValidations } from './validations';
import { joiResolver } from '@hookform/resolvers/joi';
import { addDays, areIntervalsOverlapping, format } from 'date-fns';
import React, { useEffect } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { Button, Dropdown, TextInput } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import DateIntervalPicker from 'src/components/shared/ui/inputs/date-picker-interval';
import { AddAbsenceDTO } from 'src/redux/member/types';
import { closeModal } from 'src/redux/modals/modalsSlice';
import { useAppDispatch } from 'src/redux/store';
import { AbsenceMotives, AbsenceState } from 'src/types';

const AbsencesModal = (props: AbsencesModalProps) => {
  const { absences, employeeId } = props;
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(addDays(new Date(), 1));
  const [error, setError] = React.useState(false);
  const [excludeStartDate, setExcludeStartDate] = React.useState(false);
  const [validDates, setValidDates] = React.useState(false);
  const [absencesOverlap, setAbsencesOverlap] = React.useState(false);

  const dispatch = useAppDispatch();

  const { getValues: getContextValues, setValue: setContextValue } =
    useFormContext<CompleteFormType>();

  const {
    formState: { isDirty, isValid },
    handleSubmit,
    control,
    reset,
    getValues,
  } = useForm<FormAbsencesValue>({
    defaultValues: {
      motive: '' as AbsenceMotives,
      startDate: '',
      endDate: '',
    },
    mode: 'onBlur',
    resolver: joiResolver(absencesValidations),
  });

  useEffect(() => {
    if (endDate) {
      setAbsencesOverlap(
        absences.some((item) =>
          areIntervalsOverlapping(
            { start: new Date(startDate), end: new Date(endDate) },
            { start: new Date(item.startDate), end: new Date(item.endDate) },
          ),
        ),
      );
    }

    if (!absencesOverlap) {
      if (endDate > startDate && isValid) {
        setValidDates(true);
        setError(false);
      } else {
        setValidDates(false);
      }
    } else {
      setError(true);
      setValidDates(false);
    }
  }, [endDate, absences, isValid, isDirty, absencesOverlap]);

  useEffect(() => {
    reset({
      motive: getValues('motive'),
      startDate: format(new Date(startDate), 'dd/MM/yyyy'),
      endDate: endDate ? format(new Date(endDate), 'dd/MM/yyyy') : undefined,
    });
  }, [startDate, endDate]);

  const handleStartDate = (date) => {
    setStartDate(date);
    setExcludeStartDate(true);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const onClose = () => {
    reset();
    dispatch(closeModal());
  };

  const onSubmit = (data: FormAbsencesValue) => {
    const absenceForList = {
      _id: employeeId,
      motive: data.motive,
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
      state: AbsenceState.APPROVED,
    };

    setContextValue('absences.absencesList', [
      ...getContextValues('absences.absencesList'),
      absenceForList,
    ]);

    const abscenseToPost: AddAbsenceDTO = {
      employee: employeeId,
      motive: data.motive,
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
    };

    setContextValue('absences.absenceToPost', [
      ...getContextValues('absences.absenceToPost'),
      { ...abscenseToPost },
    ]);

    dispatch(closeModal());
  };

  return (
    <div className={styles.modalContainer}>
      <div className={styles.titleContainer}>
        <p>Agregar Ausencia</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputContainer}>
          <div className={styles.input}>
            <Dropdown
              control={control}
              testId={'motive-dropdown'}
              label="Motivo"
              name="motive"
              options={motiveOptions}
              error
              fullWidth
            />
          </div>
          <div className={styles.input}>
            <TextInput
              control={control}
              testId={'start-input'}
              name="startDate"
              type={'text'}
              variant="outlined"
              error
              label={'Fecha de inicio'}
              disabled
              fullWidth
            />
          </div>
          <div className={styles.input}>
            <TextInput
              control={control}
              testId={'end-input'}
              name="endDate"
              type={'text'}
              variant="outlined"
              error
              disabled
              label={'Fecha de reintegro'}
              fullWidth
            />
          </div>
        </div>
        <div className={styles.datePicker}>
          <DateIntervalPicker
            control={control}
            name="startDate"
            setStart={handleStartDate}
            setEnd={handleEndDate}
            startDate={startDate}
            endDate={endDate}
            excludeStartDate={excludeStartDate}
          />
        </div>
        {error && (
          <div className={styles.absenceError}>
            <span>Error: Ausencia registrada en el período indicado</span>
          </div>
        )}
      </form>
      <div className={styles.buttonsContainer}>
        <div>
          <Button
            testId="reset-btn"
            materialVariant={Variant.OUTLINED}
            label="Cancelar"
            onClick={() => onClose()}
          />
        </div>
        <div>
          <Button
            testId="submit-btn"
            materialVariant={Variant.CONTAINED}
            label="Confirmar"
            onClick={handleSubmit(onSubmit)}
            disabled={!validDates}
          />
        </div>
      </div>
    </div>
  );
};

export default AbsencesModal;
