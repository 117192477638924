import React from 'react';
import { Navigate } from 'react-router-dom';
import { AccessRoleType } from 'src/constants';
import { useAppSelector } from 'src/redux/store';

const RouteSelector = () => {
  const role = useAppSelector((state) => state.auth.authUser.accessTypes);

  const redirectPath = (role: string[]) => {
    let path = '/login';
    switch (role[0]) {
      case AccessRoleType.ADMIN_AM:
        path = '/admin/home';
        break;
      case AccessRoleType.SUPER_ADMIN:
        path = '/super-admin';
        break;
      case AccessRoleType.EMPLOYEE:
        path = '/employee';
        break;
      default:
        path = '/login';
    }
    return path;
  };

  return <Navigate to={redirectPath(role)} />;
};

export default RouteSelector;
