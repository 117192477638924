import Joi from 'joi';
import { SeniorityType } from 'src/redux/types';
import { ProjectRoleType } from 'src/types';

//TODO: Refactor validation for startDate and endDate to be a custom validation
export const memberValidations = () => {
  return Joi.object({
    employee: Joi.object({
      value: Joi.string(),
      label: Joi.string(),
    })
      .messages({
        'any.required': 'Este campo es requerido',
        'string.empty': 'Este campo es requerido',
      })
      .required(),

    role: Joi.string()
      .valid(...Object.values(ProjectRoleType))
      .messages({
        'any.only': 'El rol debe ser DEV, QA, UX/UI, TL o PM',
        'any.required': 'Este campo es requerido',
      })
      .required(),

    seniority: Joi.string()
      .valid(...Object.values(SeniorityType))
      .messages({
        'any.only': `El seniority debe ser ${SeniorityType.TRAINEE}, ${SeniorityType.JUNIOR}, ${SeniorityType.SEMI_SENIOR} o ${SeniorityType.SENIOR}`,
        'any.required': 'Este campo es requerido',
      })
      .required(),

    memberDedication: Joi.number()
      .integer()
      .min(1)
      .max(100)
      .unsafe()
      .messages({
        'any.required': 'Este campo es requerido',
        'number.min': 'El porcentaje debe ser mayor a 0',
        'number.max': 'El porcentaje debe ser menor a 100',
        'number.base': 'Ingresar un número del 0 al 100',
        'number.unsafe': 'El porcentaje debe ser menor a 100',
      })
      .required(),

    helper: Joi.object({
      helperReference: Joi.object({
        value: Joi.string(),
        label: Joi.string(),
      }).messages({
        'base.string': 'Este campo debe ser un string',
      }),
      dedication: Joi.number()
        .when('helperReference.value', {
          is: Joi.exist(),
          then: Joi.number().integer().min(1).max(24).unsafe(),
          otherwise: Joi.number(),
        })
        .messages({
          'number.base': 'Dedicación debe ser un numero',
          'number.min': 'Dedicación debe ser mayor a 0',
          'number.max': 'Dedicación no debe ser mayor a 24',
        }),
    }),

    startDate: Joi.date()
      .messages({
        'any.required': 'La fecha de inicio es requerida',
        'date.base': 'Campo requerido. Formato dd/mm/aaaa',
      })
      .required(),

    endDate: Joi.date()
      .messages({
        'date.base': 'Este campo es requerido. Formato dd/mm/aaaa',
      })
      .allow(null),
  }).options({ allowUnknown: true });
};
