import { Toast } from './components/shared/ui';
import { tokenListener } from './helper/firebase';
import './index.css';
import store from './redux/store';
import reportWebVitals from './report-web-vitals';
import AppRoutes from './routes';
import { queryClient } from './services';
import { themes } from './themes';
import '@fontsource/roboto';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

export const router = createBrowserRouter(createRoutesFromElements(AppRoutes()));

tokenListener();

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes}>
          <RouterProvider router={router} />
          <Toast />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
