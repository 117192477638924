import { AvailabilityType } from 'src/types';
import { getFilteredDataByTextSearch } from 'src/utils/getFilteredDataByTextSearch';

const filterData = (list, filters) => {
  const searchOptions = ['name', 'role', 'rank', 'expertiseArea'];

  let filteredListData = list.filter((item) => item.active);

  if (filters.role) {
    filteredListData = filteredListData.filter((item) => item.role === filters.role);
  }

  if (filters.availability === AvailabilityType.Available) {
    filteredListData = filteredListData.filter(
      (item) => item.availability === AvailabilityType.Available,
    );
  }

  if (filters.role && filters.rank) {
    filteredListData = filteredListData.filter((item) => {
      return item.rank == filters.rank;
    });
  }

  if (filters.search !== '') {
    filteredListData = getFilteredDataByTextSearch(filteredListData, searchOptions, filters.search);
  }

  // TODO: add skills search filter
  return filteredListData;
};

export default filterData;
