import { parseError, responseBody } from './util';
import axios from 'axios';
import store from 'src/redux/store';
import { ErrorFormat } from 'src/redux/types';

const state = store.getState();
const token = state.auth.authUser.token;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    token: token,
  },
});

export const getResourceRequest = <T>(apiRoute) =>
  api.get<T[]>(apiRoute).then(responseBody).catch<ErrorFormat>(parseError);

export const getByFilterResourceRequest = <T>(apiRoute, filter) =>
  api
    .get<T[]>(`${apiRoute}/`, { params: filter })
    .then(responseBody)
    .catch<ErrorFormat>(parseError);

export const addResourceRequest = <T>(apiRoute, body: T) =>
  api.post<T>(apiRoute, body).then(responseBody).catch<ErrorFormat>(parseError);

export const addManyResourcesRequest = <T>(apiRoute, body: T) =>
  api.post<T[]>(`${apiRoute}/createMany`, body).then(responseBody).catch<ErrorFormat>(parseError);

export const editResourceRequest = <T>(apiRoute, options: { body: T; id: string }) =>
  api
    .patch<T>(`${apiRoute}/${options.id}`, options.body)
    .then(responseBody)
    .catch<ErrorFormat>(parseError);

export const deleteResourceRequest = <T>(apiRoute, id: string) =>
  api.patch<T>(`${apiRoute}/delete/${id}`).then(responseBody).catch<ErrorFormat>(parseError);

export const activateResourceRequest = <T>(apiRoute, id: string) =>
  api.patch<T>(`${apiRoute}/activate/${id}`).then(responseBody).catch<ErrorFormat>(parseError);

export const physicalDeleteResourceRequest = <T>(apiRoute, id: string) =>
  api.delete<T>(`${apiRoute}/delete/${id}`).then(responseBody).catch<ErrorFormat>(parseError);

export default api;
