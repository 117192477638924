import styles from './employee.module.css';
import EmployeeList from './employeeList';
import SalaryRanges from './salaryRanges';
import SalaryRangesHistory from './salaryRangesHistory';
import React, { useEffect, useState } from 'react';
import Navtab from 'src/components/shared/ui/navtab';

const Employees = () => {
  const labels = ['Lista de empleados', 'Franjas Salariales', 'Historial de Franjas Salariales'];

  const components = [
    <div key={0}>
      <EmployeeList />
    </div>,
    <div key={1}>
      <SalaryRanges />
    </div>,
    <div key={2}>
      <SalaryRangesHistory />
    </div>,
  ];

  const [activeTab, setActiveTab] = useState(() => {
    const storedTab = localStorage.getItem('activeTab');
    return storedTab ? parseInt(storedTab, 10) : 0;
  });

  const handleTabChange = (index) => {
    // TODO: Remove this line when the salary ranges history and salary ranges are implemented with the new roles
    if (index !== 0) return;
    setActiveTab(index);
  };

  useEffect(() => {
    localStorage.setItem('activeTab', activeTab.toString());
  }, [activeTab]);

  return (
    <div className={styles.navtabContainer}>
      <Navtab
        labels={labels}
        components={components}
        activeTab={activeTab}
        onTabChange={handleTabChange}
      />
    </div>
  );
};

export default Employees;
