import Joi from 'joi';
import { ProjectRoleType } from 'src/types';

export const growthPlanValidations = Joi.object({
  objectiveRole: Joi.string()
    .required()
    .valid(...Object.values(ProjectRoleType))
    .messages({
      'any.required': 'El rol objetivo es requerido',
      'any.only': 'Rol debe ser DEV, QA, UX/UI, TL, QA L, UX/UI L ó TL L',
    }),
  objectiveLevel: Joi.number().integer().min(1).max(10).messages({
    'number.base': 'Nivel objetivo debe ser un número',
    'number.integer': 'Nivel objetivo debe ser un número entero',
    'number.min': 'Nivel objetivo mínimo asignable es 1',
    'number.max': 'Nivel objetivo máximo asignable es 10',
  }),
  objectiveSalary: Joi.number().min(1).messages({
    'number.base': 'El salario objetivo debe ser un número',
    'number.min': 'El salario objetivo debe ser mayor a 0',
  }),
  objectiveDate: Joi.string().messages({
    'date.base': 'La fecha objetivo tiene que tener el formato fecha',
    'date.greater': 'La fecha objetivo debe ser posterior a la fecha actual',
  }),
  description: Joi.string().required().trim().strict().max(400).messages({
    'string.trim': 'La descripción no puede contener espacios en blanco al principio o al final',
    'string.empty': 'La descripcion es requerida',
    'string.max': 'La descripción no puede exceder los 400 caracteres',
  }),
}).options({ allowUnknown: true, abortEarly: false, convert: true });
