import { State } from '../types';
import { Actions } from './constants';
import { ActionsType } from './types';
import { Reducer } from 'redux';
import { MovementsCardProps } from 'src/components/shared/ui/movementsCard/types';

const initialState: State<MovementsCardProps> = {
  list: [],
  isLoading: false,
  error: undefined,
};

const MovementReducer: Reducer<State<MovementsCardProps>, ActionsType> = (
  state = initialState,
  action,
): State<MovementsCardProps> => {
  switch (action.type) {
    case Actions.GET_MOVEMENTS_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.GET_MOVEMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case Actions.GET_MOVEMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
      };
    default:
      return state;
  }
};

export default MovementReducer;
