import { CompleteFormType } from '../../types';
import styles from './createMemberForm.module.css';
import { CreateMemberFormType } from './types';
import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import {
  AutocompleteInput,
  Button,
  DatePicker,
  Dropdown,
  Loader,
  TextInput,
} from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import api from 'src/config/api';
import { ApiRoutes, roles, seniorityOptions } from 'src/constants';
import { Employee, MemberHistory } from 'src/redux/employee/types';
import { AddMemberDTO, EditMemberDTO } from 'src/redux/member/types';
import { closeModal } from 'src/redux/modals/modalsSlice';
import { Project } from 'src/redux/project/types';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { SeniorityType } from 'src/redux/types';
import { ProjectRoleType } from 'src/types';

function CreateMemberForm() {
  const dispatch = useAppDispatch();
  const [employeeList, setEmployeeList] = useState<Employee[]>([]);
  const [projectsList, setProjectsList] = useState<Project[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isLongTerm, setIsLongTerm] = useState<boolean>(false);

  const selectedEmployee = useAppSelector((state) => state.employee.selectedEmployee);

  const { getValues: getContextValues, setValue: setContextValue } =
    useFormContext<CompleteFormType>();

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { isDirty },
  } = useForm<CreateMemberFormType>({
    defaultValues: {
      helper: {
        helperReference: {
          value: '',
          label: '',
        },
        dedication: 0,
      },
      memberDedication: 0,
      project: {
        value: '',
        label: '',
      },
      role: ProjectRoleType.DEV,
      startDate: null,
      endDate: null,
      seniority: SeniorityType.TRAINEE,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = await Promise.all([
          api.get(`${ApiRoutes.EMPLOYEE}`),
          api.get(`${ApiRoutes.PROJECTS}`),
        ]);

        setEmployeeList(responses[0].data.data);
        setProjectsList(responses[1].data.data);
      } catch (error) {
        // TODO: handle error in toast
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const onSubmit = (data: CreateMemberFormType) => {
    // Editing local memberList from useContextForm.
    const mockId = Math.floor(Math.random() * 9999 + 1000).toString();
    const memberForList: MemberHistory = {
      _id: mockId,
      employee: {
        _id: selectedEmployee._id,
        user: {
          _id: selectedEmployee.user._id,
          firstName: selectedEmployee.user.firstName,
          lastName: selectedEmployee.user.lastName,
        },
      },
      project: {
        _id: data.project.value,
        projectName: data.project.label,
        isActive: true,
      },
      seniority: data.seniority,
      role: getValues('role') as ProjectRoleType,
      memberDedication: data.memberDedication,
      ...(data.helper && data.helper?.helperReference?.value
        ? {
            helper: [
              {
                helperReference: {
                  _id: data.helper.helperReference.value,
                  user: {
                    _id: helpers.selectedHelper._id,
                    firstName: helpers.selectedHelper.user.firstName,
                    lastName: helpers.selectedHelper.user.lastName,
                  },
                },
                dedication: data.helper.dedication,
                isActive: true,
                _id: '',
              },
            ],
          }
        : { helper: undefined }),
      startDate: startDate,
      endDate: endDate,
      active: true,
    };

    setContextValue('projects.membersList', [
      ...getContextValues('projects.membersList'),
      memberForList,
    ]);

    const memberToPost: AddMemberDTO = {
      active: true,
      employee: selectedEmployee._id,
      startDate: startDate,
      ...(!!endDate && { endDate: endDate }),
      ...(data.helper && data.helper.helperReference.value
        ? {
            helper: [
              {
                helperReference: data.helper.helperReference.value,
                dedication: data.helper.dedication,
                isActive: true,
              },
            ],
          }
        : { helper: undefined }),
      role: data.role as ProjectRoleType,
      memberDedication: data.memberDedication,
      project: data.project.value,
      seniority: data.seniority,
    };

    // If member is on the delete array we do not want to create a new memberID, we should do a patch with the same id. We will update patch array and remove form delete array.
    const memberOnDeleteArray = getContextValues('projects.membersToDelete').find(
      (m) => m.projectId === data.project.value,
    );
    if (memberOnDeleteArray) {
      const filteredMembersToDelete = getContextValues('projects.membersToDelete').filter(
        (m) => m.projectId !== data.project.value,
      );

      setContextValue('projects.membersToDelete', filteredMembersToDelete);

      const memberToPatch: EditMemberDTO = {
        id: memberOnDeleteArray.memberId,
        body: {
          startDate: startDate,
          ...(!!endDate && { endDate: endDate }),
          ...(data.helper && data.helper.helperReference.value
            ? {
                helper: [
                  {
                    helperReference: data.helper.helperReference.value,
                    dedication: +data.helper.dedication,
                    isActive: true,
                  },
                ],
              }
            : { helper: undefined }),
          role: data.role as ProjectRoleType,
          memberDedication: +data.memberDedication,
          seniority: data.seniority,
        },
      };

      setContextValue('projects.membersToPatch', [
        ...getContextValues('projects.membersToPatch'),
        memberToPatch,
      ]);
    } else {
      // updating membersToPost array.
      setContextValue('projects.membersToPost', [
        ...getContextValues('projects.membersToPost'),
        { ...memberToPost, id: mockId },
      ]);
    }

    dispatch(closeModal());
  };

  const projects = useMemo(() => {
    if (loading) return null;

    const currentMemberListProjectNames = getContextValues('projects.membersList').map(
      (member) => member.project.projectName,
    );

    const availableProjects = projectsList.filter(
      (project) => !currentMemberListProjectNames.includes(project.projectName),
    );

    const dropdownList = availableProjects.map((p) => {
      return {
        value: p._id,
        label: p.projectName,
      };
    });

    const selectedProject = projectsList.find((p) => p._id === getValues('project').value);

    return {
      dropdownList,
      selectedProject,
    };
  }, [loading, projectsList, watch('project')]);

  const helpers = useMemo(() => {
    const dropdownList = employeeList.reduce((acc, employee) => {
      if (employee._id !== selectedEmployee._id && employee?.user?.isActive) {
        acc.push({
          value: employee._id,
          label: `${employee.user?.firstName} ${employee.user?.lastName}`,
        });
      }
      return acc;
    }, []);

    dropdownList.unshift({ value: '', label: 'Sin ayudante' });

    const selectedHelper = employeeList.find(
      (e) => e._id === getValues('helper.helperReference.value'),
    );

    return {
      dropdownList,
      selectedHelper,
    };
  }, [loading, employeeList, watch('helper.helperReference.value')]);

  useEffect(() => {
    const helper = getValues('helper');

    if (!helper?.helperReference?.value) {
      setValue('helper', undefined);
    }
  }, [watch('helper.helperReference.value')]);

  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const selectedHelper = watch('helper');
  const memberDedication = watch('memberDedication');

  if (loading) return <Loader />;

  return (
    <div className={styles.modalContainer}>
      <div className={styles.headerAddMember} data-testid={'header-message'}>
        <p>
          {`Asignar proyecto a ${selectedEmployee.user.firstName} ${selectedEmployee.user.lastName}`}
        </p>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.memberForm}>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <div className={styles.inputsContainer}>
              <div className={styles.memberData}>
                <div className={styles.topContainer}>
                  <AutocompleteInput
                    name={'project'}
                    control={control}
                    options={projects.dropdownList}
                    label={'Proyecto'}
                  />
                </div>
                <div className={styles.bottomContainer}>
                  <Dropdown
                    control={control}
                    testId={'roles-dropdown'}
                    label={'Rol'}
                    name="role"
                    options={roles}
                    fullWidth
                  />
                  <TextInput
                    control={control}
                    testId={'member-dedication'}
                    label="Dedicacion"
                    name="memberDedication"
                    type={'number'}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <Dropdown
                  control={control}
                  testId={'seniorityDropdown'}
                  label={'Seniority'}
                  name="seniority"
                  options={seniorityOptions}
                  fullWidth
                />
              </div>
              <div className={styles.helperData}>
                <div className={styles.topContainer}>
                  <AutocompleteInput
                    control={control}
                    label={'Ayudante'}
                    name="helper.helperReference"
                    options={helpers.dropdownList}
                    disable={!projects.selectedProject}
                  />
                </div>
                <div className={styles.bottomContainer}>
                  <div className={styles.helperDedication}>
                    <TextInput
                      control={control}
                      testId={'helper-dedication'}
                      label="Dedicacion"
                      name="helper.dedication"
                      type={'number'}
                      variant="outlined"
                      fullWidth
                      disabled={!selectedHelper?.helperReference?.value}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.datePickers}>
              <div>
                <DatePicker
                  label={'Inicio'}
                  testId={'start-date'}
                  name="startDate"
                  control={control}
                  popperProps={{
                    placement: 'right',
                  }}
                  minDate={projects?.selectedProject?.startDate || null}
                  maxDate={projects?.selectedProject?.endDate || null}
                  disabled={!projects.selectedProject}
                />
              </div>

              <div>
                <DatePicker
                  label={'Fin'}
                  testId={'end-date'}
                  name="endDate"
                  popperProps={{
                    placement: 'right',
                  }}
                  minDate={startDate || null}
                  maxDate={projects?.selectedProject?.endDate}
                  control={control}
                  disabled={!projects.selectedProject || !startDate || isLongTerm}
                />
              </div>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e, checked) => {
                    setIsLongTerm(checked);
                    if (checked) {
                      setValue('endDate', null);
                    }
                  }}
                  disabled={!startDate}
                />
              }
              label="Long term"
            />
            <div className={styles.buttonsContainer}>
              <div>
                <Button
                  testId="cancel-button"
                  materialVariant={Variant.OUTLINED}
                  onClick={() => dispatch(closeModal())}
                  label="Cancelar"
                />
              </div>
              <div>
                <Button
                  testId="confirm-button"
                  materialVariant={Variant.CONTAINED}
                  onClick={handleSubmit(onSubmit)}
                  label="Confirmar"
                  disabled={
                    (!endDate && !isLongTerm) ||
                    !memberDedication ||
                    !isDirty ||
                    (Boolean(selectedHelper?.helperReference?.value) &&
                      Boolean(!selectedHelper?.dedication))
                  }
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateMemberForm;
