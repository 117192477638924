import Joi from 'joi';
import { ProjectRoleType } from 'src/types';

const commonValidations = Joi.number().integer().min(1).max(10).required().messages({
  'any.required': 'Campo requerido',
  'number.base': 'Debe ser un número',
  'number.integer': 'Debe ser un número entero',
  'number.min': 'Debe ser mayor o igual a 1',
  'number.max': 'Debe ser menor o igual a 10',
});

const performanceFeedbackValidations = Joi.object({
  newRole: Joi.string()
    .valid(...Object.values(ProjectRoleType))
    .required()
    .messages({
      'any.required': 'Campo requerido',
      'any.only': 'Debe ser valido.',
    }),

  newLevel: Joi.number().integer().min(1).max(10).required().messages({
    'any.required': 'Campo requerido',
    'number.base': 'Debe ser un número',
    'number.integer': 'Debe ser un número entero',
    'number.min': 'Debe ser mayor o igual a 1',
    'number.max': 'Debe ser menor o igual a 10',
  }),

  notes: Joi.string().max(200).allow('').messages({
    'string.max': 'Las notas deben tener como máximo 200 caracteres',
  }),

  hardSkills: Joi.object({
    technicalLevel: commonValidations,

    toolsAndLanguages: commonValidations,

    workQuality: commonValidations,
  }),

  softSkills: Joi.object({
    effectiveCommunication: commonValidations,

    criticalThinking: commonValidations,

    teamWork: commonValidations,

    conflictsResolution: commonValidations,

    empathy: commonValidations,

    companyCompromise: commonValidations,

    clientAccomplishment: commonValidations,

    teachesAndLearns: commonValidations,

    proactivity: commonValidations,

    autonomy: commonValidations,
  }),
}).options({ allowUnknown: true, abortEarly: false, convert: true });

export default performanceFeedbackValidations;
