import {
  MovementsCardProps,
  actionType,
  DirectlyAffectedEntity,
  IndirectlyAffectedEntity,
  Entities,
  indirectActions,
} from './types';
import { Card } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from 'src/components/shared/ui/movementsCard/movementsCard.module.css';
import { UiRoutes } from 'src/constants';

const MovementsCard = (props: MovementsCardProps) => {
  const { user, actionType, directlyAffectedEntity, indirectlyAffectedEntity } = props;

  const navigate = useNavigate();

  const handleDirectlyClick = () => {
    const secondPath =
      directlyAffectedEntity?.entityType === 'Employee'
        ? `${UiRoutes.EMPLOYEES}/edit`
        : directlyAffectedEntity?.entityType === 'Client'
        ? `${UiRoutes.CLIENTS}/form`
        : `${UiRoutes.PROJECTS}/form`;
    UiRoutes.PROJECTS;

    return navigate(`${UiRoutes.ADMIN_AM}${secondPath}/${directlyAffectedEntity.entityId}`);
  };

  const handleIndirectlyClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();

    if (indirectlyAffectedEntity?.entityType === 'Project')
      return navigate(
        `${UiRoutes.ADMIN_AM}${UiRoutes.PROJECTS}/form/${indirectlyAffectedEntity.entityId}`,
      );
  };

  const sx = React.useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 20px',
      justifyContent: 'space-around',
      gap: '5px',
      width: '300px',
      boxSizing: 'border-box',
    }),
    [],
  );

  const directActions = React.useMemo(() => {
    const res = {
      CREATE: 'añadió',
      UPDATE: 'modificó',
      DELETE: 'eliminó',
    };
    if (directlyAffectedEntity?.entityType === 'Project') res.DELETE = 'desactivó';
    return res;
  }, []);

  const pastActions = React.useMemo(() => {
    const res = {
      CREATE: 'añadido',
      UPDATE: 'modificado',
      DELETE: 'eliminado',
    };
    if (directlyAffectedEntity?.entityType === 'Project') res.DELETE = 'desactivado';
    return res;
  }, []);

  const titleGenerator = (
    user: string,
    action: actionType,
    directEntity: DirectlyAffectedEntity,
    indirectEntity: IndirectlyAffectedEntity | undefined,
  ) => {
    let title = `${user} ${directActions[action]} un ${Entities[directEntity?.entityType]}`;
    if (indirectEntity) title = `${title} ${indirectActions[actionType]} un proyecto`;
    if (directEntity?.entityType === 'MassiveUpdate')
      title = `${user} realizó un ajuste salarial masivo del ${directEntity.entityName}`;
    if (directEntity?.entityType === 'SalaryRange')
      title = `${user} ajustó las franjas salariales del rol ${directEntity.entityName}`;
    return title;
  };

  if(indirectlyAffectedEntity === undefined) {
    return null;
  }

  return (
    <Card data-testid="movementCard" sx={sx}>
      <span className={`${styles.spanColor} ${styles.title}`}>
        {titleGenerator(user, actionType, directlyAffectedEntity, indirectlyAffectedEntity)}
      </span>
      {directlyAffectedEntity?.entityType !== 'MassiveUpdate' &&
        directlyAffectedEntity?.entityType !== 'SalaryRange' && (
          <span className={`${styles.spanColor} ${styles.content}`}>
            <span>
              <span
                data-testid="directlyEntityName"
                onClick={
                  directlyAffectedEntity.entityType !== 'Skill'
                  ? handleDirectlyClick
                  : null
                }
                className={
                  directlyAffectedEntity.entityType !== 'Skill'
                  ? styles.link
                  : styles.spanColor
                }
              >
                {directlyAffectedEntity?.entityName}
              </span>
              {` ha sido ${pastActions[actionType]}`}
              {indirectlyAffectedEntity && (
                <>
                  {' a '}
                  <span>
                    <span
                      data-testid="indirectlyEntityName"
                      className={styles.link}
                      onClick={(e) => handleIndirectlyClick(e)}
                    >
                      {indirectlyAffectedEntity?.entityName || ''}
                    </span>
                  </span>
                </>
              )}
            </span>
          </span>
        )}
    </Card>
  );
};

export default MovementsCard;
