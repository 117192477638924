/* eslint-disable @typescript-eslint/no-explicit-any */
import { queryClient } from '..';
import { useMutation } from 'react-query';
import {
  addResourceRequest,
  editResourceRequest,
  physicalDeleteResourceRequest,
} from 'src/config/api';
import { ApiRoutes } from 'src/constants';
import store from 'src/redux/store';
import { openToast } from 'src/redux/toast/toastSlice';
import { SkillsData } from 'src/types';

export const useCreateSkillMutation = () => {
  return useMutation({
    mutationKey: ['createSkill'],
    mutationFn: async (data: { name: string }) => {
      return await addResourceRequest(ApiRoutes.SKILLS, data);
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: ['Skills'],
      });
      return store.dispatch(
        openToast({
          message: data.message,
          error: false,
        }),
      );
    },
    onError: (error: any) => {
      store.dispatch(
        openToast({
          message: error.message,
          error: true,
        }),
      );
    },
  });
};

export const useDeleteSkillMutation = () => {
  return useMutation({
    mutationKey: ['deleteSkill'],
    mutationFn: async (id: string) => {
      return await physicalDeleteResourceRequest(ApiRoutes.SKILLS, id);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['Skills'],
      });
      return store.dispatch(
        openToast({
          message: 'Skill eliminada exitosamente.',
          error: false,
        }),
      );
    },
    onError: (error: any) => {
      store.dispatch(
        openToast({
          message: error.message,
          error: true,
        }),
      );
    },
  });
};

export const useEditSkillMutation = () => {
  return useMutation({
    mutationKey: ['editSkill'],
    mutationFn: async (data: { body: Partial<SkillsData>; id: string }) => {
      return await editResourceRequest(ApiRoutes.SKILLS, data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['Skills'],
      });
      return store.dispatch(
        openToast({
          message: 'Skill editada exitosamente',
          error: false,
        }),
      );
    },
    onError: (error: any) => {
      store.dispatch(
        openToast({
          message: error.message,
          error: true,
        }),
      );
    },
  });
};
