export enum actionType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum entityType {
  EMPLOYEE = 'Employee',
  SKILL = 'Skill',
  PROJECT = 'Project',
  CLIENT = 'Client',
  PERFORMANCE_FEEDBACK = 'PerformanceFeedback',
  SALARY_RANGE = 'SalaryRange',
  MASSIVE_UPDATE = 'MassiveUpdate',
}

export type DirectlyAffectedEntity = {
  entityType: entityType;
  entityName: string;
  entityId?: string;
};

export type IndirectlyAffectedEntity = {
  entityType: string;
  entityName: string;
  entityId?: string;
};
export interface MovementsCardProps {
  user: string;
  actionType: actionType;
  directlyAffectedEntity: DirectlyAffectedEntity;
  indirectlyAffectedEntity?: IndirectlyAffectedEntity;
  actionDate?: string;
}

export const indirectActions = {
  CREATE: 'a',
  UPDATE: 'en',
  DELETE: 'de',
};

export const Entities = {
  [entityType.CLIENT]: 'cliente',
  [entityType.EMPLOYEE]: 'empleado',
  [entityType.SKILL]: 'skill',
  [entityType.SALARY_RANGE]: 'franja salarial',
  [entityType.PERFORMANCE_FEEDBACK]: 'feedback de desempeño',
  [entityType.PROJECT]: 'proyecto',
};
