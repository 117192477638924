import { PerformanceFeedbackFormValues } from '../types';
import styles from './lastperfomance.module.css';
import SkillsCard from './skills';
import { TextField } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Loader } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import { getResourceRequest } from 'src/config/api';
import { ApiRoutes, UiRoutes } from 'src/constants';
import { formatDate } from 'src/utils/formatters';

const LastPerformanceFeedback = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [previousPerformance, setPreviousPerformance] =
    React.useState<PerformanceFeedbackFormValues>({} as PerformanceFeedbackFormValues);
  const params = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchData = async () => {
      await getResourceRequest(`${ApiRoutes.PERFORMANCE_FEEDBACK}/${params.performanceId}`).then(
        (res) => {
          setPreviousPerformance(res.data[0]);
          setIsLoading(false);
        },
      );
    };
    fetchData();
  }, []);

  if (isLoading || !previousPerformance) {
    return <Loader />;
  }

  const previousPerformanceDate = formatDate(previousPerformance.date);

  return (
    <div className={styles.container}>
      <div className={styles.welcomeMessage} data-testid="title-container">
        Feedback de desempeño -{' '}
        {`${previousPerformance.userData.firstName} ${previousPerformance.userData.lastName}`} -{' '}
        {previousPerformanceDate}
      </div>
      <div className={styles.bottom} data-testid="feedback-resume-container">
        <div className={styles.topSide}>
          <div className={styles.categoriesContainer} data-testid="category-container">
            <div className={styles.titles}>Categorías</div>
            <div className={styles.roleLevel}>
              <span>
                <p>Nivel {previousPerformance.newLevel}</p>
              </span>
              <span>
                <p>{previousPerformance.newRole}</p>
              </span>
            </div>
          </div>
          <SkillsCard
            title="Habilidades técnicas"
            skills={previousPerformance.hardSkills}
            testId="hard-skills-container"
          />
        </div>
        <div className={styles.bottomSide}>
          <div>
            <div className={styles.feedback} data-testid="feedback-container">
              <div className={styles.titles}>Feedback para el empleado</div>
              <div className={styles.notesContainer}>
                <TextField
                  multiline
                  fullWidth
                  disabled
                  label="Notas"
                  defaultValue={previousPerformance.notes}
                  rows={5}
                />
              </div>
            </div>
            <SkillsCard
              title="Habilidades blandas"
              skills={previousPerformance.softSkills}
              testId="soft-skills-container"
            />
          </div>
          <Button
            materialVariant={Variant.OUTLINED}
            onClick={() => {
              navigate(`${UiRoutes.ADMIN_AM}${UiRoutes.EDIT_EMPLOYEES}/${params.employeeId}`);
            }}
            label={'Volver'}
            testId={'back-button'}
          />
        </div>
      </div>
    </div>
  );
};

export default LastPerformanceFeedback;
