import Joi from 'joi';
import { SeniorityType } from 'src/redux/types';
import { AbsenceMotives, ExpertiseAreaType, ProjectRoleType, RadiumRoleType } from 'src/types';

const FormSchema = Joi.object({
  _id: Joi.string(),
  email: Joi.string(),
  DoB: Joi.date(),
  salary: Joi.string().alphanum().min(0).messages({
    'number.base': 'Salario deber ser un número',
    'number.min': 'El salario no puede ser menor a 0',
  }),
  dedicatedHours: Joi.string().alphanum(),
  availabilityId: Joi.string(),
  availableHours: Joi.string().alphanum(),
  availabilityDescription: Joi.string(),
  role: Joi.string()
    .valid(...Object.values(RadiumRoleType))
    .messages({
      'any.only': `El rol debe ser ${RadiumRoleType.TRAINEE}, ${RadiumRoleType.EXPLORER}, ${RadiumRoleType.CREW_MEMBER},
    ${RadiumRoleType.PILOT}, ${RadiumRoleType.LIEUTENANT}, ${RadiumRoleType.COMMANDER} o ${RadiumRoleType.CAPTAIN}`,
      'string.base': 'Los items del array deben ser una cadena de caracteres',
    }),
  rank: Joi.string().alphanum(),
  expertiseArea: Joi.string()
    .valid(ExpertiseAreaType.DEV, ExpertiseAreaType.QA, ExpertiseAreaType.UX_UI)
    .messages({
      'any.only': `El área de expertise debe ser ${ExpertiseAreaType.DEV}, ${ExpertiseAreaType.QA} o ${ExpertiseAreaType.UX_UI}`,
      'string.base': 'Los items del array deben ser una cadena de caracteres',
    }),
  skills: Joi.array().items(
    Joi.object({
      _id: Joi.string(),
      name: Joi.string(),
      description: Joi.string(),
      isValidated: Joi.bool(),
    }),
  ),
  notes: Joi.string().min(0).max(499).trim().strict().messages({
    'string.max': 'Las notas deben contener menos de 500 caracteres',
    'string.base': 'Las notas deben ser un string',
    'string.trim': 'Las notas no pueden contener espacios vacios',
  }),
});

const ProjectsSchema = Joi.object({
  membersList: Joi.array().items(
    Joi.object({
      _id: Joi.string(),
      employee: Joi.object({
        _id: Joi.string(),
        user: Joi.object({
          _id: Joi.string(),
          firstName: Joi.string(),
          lastName: Joi.string(),
        }),
      }),
      project: Joi.object({
        _id: Joi.string(),
        projectName: Joi.string(),
        isActive: Joi.bool(),
      }),
      role: Joi.string(),
      rate: Joi.number(),
      seniority: Joi.string().valid(...Object.values(SeniorityType)),
      memberDedication: Joi.number(),
      helper: Joi.array().items(
        Joi.object({
          helperReference: Joi.object({
            _id: Joi.string(),
            user: Joi.object({
              _id: Joi.string(),
              firstName: Joi.string(),
              lastName: Joi.string(),
            }),
          }),
          dedication: Joi.number(),
          isActive: Joi.bool(),
          _id: Joi.string().empty().allow(''),
        }),
      ),
      startDate: Joi.date(),
      active: Joi.bool(),
      endDate: Joi.date().allow('', null),
    }),
  ),
  membersToPost: Joi.array().items(
    Joi.object({
      active: Joi.bool(),
      employee: Joi.string(),
      startDate: Joi.date(),
      endDate: Joi.date().allow('', null),
      helper: Joi.array()
        .items(
          Joi.object({
            helperReference: Joi.string(),
            dedication: Joi.string(),
            isActive: Joi.bool(),
          }),
        )
        .allow({}, ''),
      role: Joi.string().valid(...Object.values(ProjectRoleType)),
      seniority: Joi.string().valid(...Object.values(SeniorityType)),
      memberDedication: Joi.number(),
      project: Joi.string(),
      id: Joi.string(),
    }),
  ),
  membersToPatch: Joi.array().items(
    Joi.object({
      id: Joi.string(),
      body: {
        startDate: Joi.date(),
        endDate: Joi.date().allow('', null),
        helper: Joi.array()
          .items(
            Joi.object({
              helperReference: Joi.string(),
              dedication: Joi.number(),
              isActive: Joi.bool(),
            }),
          )
          .allow('', {}),
        role: Joi.string().valid(...Object.values(ProjectRoleType)),
        seniority: Joi.string().valid(...Object.values(SeniorityType)),
        memberDedication: Joi.number(),
      },
    }),
  ),
  membersToDelete: Joi.array().items(
    Joi.object({
      memberId: Joi.string(),
      projectId: Joi.string(),
    }),
  ),
});

const AbsencesSchema = Joi.object({
  absencesList: Joi.array().items(
    Joi.object({
      _id: Joi.string(),
      motive: Joi.string().valid(...Object.values(AbsenceMotives)),
      startDate: Joi.date(),
      endDate: Joi.date().allow('', null),
    }),
  ),
  absenceToPost: Joi.array().items(
    Joi.object({
      employee: Joi.string(),
      motive: Joi.string().valid(...Object.values(AbsenceMotives)),
      startDate: Joi.date(),
      endDate: Joi.date().allow('', null),
    }),
  ),
}).options({ allowUnknown: true });

export const CompleteFormTypeSchema = Joi.object({
  form: FormSchema.required(),
  projects: ProjectsSchema,
  absences: AbsencesSchema,
});
