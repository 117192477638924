import styles from './asideTable.module.css';
import { AsideTableProps } from './types';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { FaPlus } from 'react-icons/fa6';
import { FaPen, FaDeleteLeft } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import { UiRoutes } from 'src/constants';
import { ModalIDs, closeModal, openModal } from 'src/redux/modals/modalsSlice';
import { useAppDispatch } from 'src/redux/store';
import { AppDispatch } from 'src/types';

const AsideTable = (props: AsideTableProps) => {
  const {
    title,
    headers,
    dataList,
    showFormModal,
    showHistoryModal,
    showPlusButton,
    showViewMore,
    redirectId,
    clickableRows = false,
    testId,
    isProjectsTable = false,
    setMemberId,
    deactivateMember,
    isAbsencesTable = false,
    pendingAbsences,
  } = props;
  const navigate = useNavigate();
  const dispatch: AppDispatch<null> = useAppDispatch();

  const handleEditProject = (id: string) => {
    dispatch(openModal(ModalIDs.EMPLOYEE_EDIT_MEMBER));
    setMemberId(id);
  };

  const handleAddProject = () => {
    showFormModal();
    setMemberId('');
  };

  return (
    <div className={styles.tableContainer} data-testid={testId}>
      <div className={styles.asideIndividualContainer}>
        <div className={styles.titleContainer}>
          <span>{title}</span>
          {isAbsencesTable && pendingAbsences > 0 && (
            <Tooltip
              title={pendingAbsences > 1 ? 'Ausencias pendientes' : 'Ausencia pendiente'}
              placement="right"
              arrow
              classes={{
                tooltip: styles.customTooltip,
              }}
            >
              <span className={styles.pendingAbsences}>{pendingAbsences}</span>
            </Tooltip>
          )}
        </div>
        {showPlusButton && (
          <IconButton
            aria-label="close"
            onClick={() => (isProjectsTable ? handleAddProject() : showFormModal())}
          >
            <FaPlus color={'#373867'} fontSize={'20px'} />
          </IconButton>
        )}
      </div>
      {dataList?.length ? (
        <>
          <table className={styles.table}>
            <thead>
              <tr>
                {headers?.map((header, index) => {
                  return (
                    <th className={styles.header} key={index}>
                      {header.header}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {dataList !== undefined &&
                dataList?.map((data, index) => {
                  return (
                    <tr className={clickableRows ? styles.clickableTr : ''} key={data.id || index}>
                      {headers?.map((header, index) => {
                        return (
                          <td
                            onClick={() => {
                              if (clickableRows && !isProjectsTable) {
                                navigate(
                                  `${UiRoutes.ADMIN_AM}${UiRoutes.EDIT_EMPLOYEES}/${redirectId}${UiRoutes.PERFORMANCE_FEEDBACK}/${data._id}`,
                                );
                              } else if (isProjectsTable && header.header === 'Proyecto') {
                                navigate(
                                  `${UiRoutes.ADMIN_AM}${UiRoutes.PROJECTS_FORM}/${data.id}`,
                                );
                                dispatch(closeModal());
                              }
                            }}
                            className={
                              header.header === 'Proyecto' ? styles.projectTr : styles.rows
                            }
                            key={index}
                          >
                            {header.key === 'action' ? (
                              <div>
                                <IconButton onClick={() => handleEditProject(data.memberId)}>
                                  <FaPen color={'#373867'} fontSize={'15px'} cursor={'pointer'} />
                                </IconButton>
                                {isProjectsTable && (
                                  <IconButton
                                    onClick={() => {
                                      deactivateMember(data.memberId);
                                    }}
                                  >
                                    <FaDeleteLeft
                                      color={'#373867'}
                                      fontSize={'15px'}
                                      cursor={'pointer'}
                                    />
                                  </IconButton>
                                )}
                              </div>
                            ) : (
                              data[header.key]
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className={styles.viewMore}>
            {showViewMore && (
              <div className={styles.viewMore}>
                <Button
                  testId="view-more-button"
                  materialVariant={Variant.TEXT}
                  onClick={showHistoryModal}
                  label="Ver más"
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <table className={styles.table} data-testid={testId}>
          <thead>
            <tr>
              {headers?.map((header, index) => {
                return (
                  <th className={styles.header} key={index}>
                    {header.header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={styles.emptyRows} colSpan={headers?.length}>
                Esta tabla de datos se encuentra vacía.
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AsideTable;
