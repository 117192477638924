import { SkillsData } from 'src/types';

export const formatSkills = (skills: SkillsData[], delimiter = ' - ', limit = skills.length) => {
  const skillList = skills.map((s) => s.name);

  if (skillList.length === 0) {
    return '';
  }

  if (limit >= skillList.length) {
    return skillList.join(delimiter);
  }

  const visibleSkills = skillList.slice(0, limit);
  const hiddenSkillsCount = skillList.length - limit;

  if (hiddenSkillsCount === 0) {
    return visibleSkills.join(delimiter);
  } else {
    return `${visibleSkills.join(delimiter)} y ${hiddenSkillsCount} más`;
  }
};
