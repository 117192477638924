import styles from './home.module.css';
import { Order } from './types';
import { Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { RxUpdate } from 'react-icons/rx';
import { useDispatch } from 'react-redux';
import MovementsCard from 'src/components/shared/ui/movementsCard';
import { actionType, MovementsCardProps } from 'src/components/shared/ui/movementsCard/types';
import { getMovements } from 'src/redux/movements/thunks';
import { AppDispatch } from 'src/types';

export const MovementsHistoryList = (props: { movementsData: MovementsCardProps[] }) => {
  const dispatch: AppDispatch<null> = useDispatch();
  const { movementsData } = props;
  const [movementsDataToMap, setMovementsDataToMap] = useState([]);

  useEffect(() => {
    if (movementsData) {
      setMovementsDataToMap(movementsData);
    }
  }, [movementsData]);

  const handleChange = (e) => {
    if (movementsData) {
      const movementsReversed = [...movementsData].reverse();
      if (e.target.value === Order.RECENT) return setMovementsDataToMap(movementsData);
      setMovementsDataToMap(movementsReversed);
    }
  };

  return (
    <div className={styles.movementsContainer}>
      <div className={styles.buttons}>
        <select className={styles.filterDropdown} onChange={handleChange}>
          <option value={Order.RECENT} className={styles.option} defaultChecked>
            Más recientes
          </option>
          <option value={Order.OLDEST} className={styles.option}>
            Más antiguos
          </option>
        </select>
        <Paper sx={{ height: 18.5, padding: '2px', marginLeft: '5px' }}>
          <RxUpdate onClick={() => dispatch(getMovements())} color="#373867" />
        </Paper>
      </div>
      <div className={styles.movementsList}>
        {movementsDataToMap?.map((movement, index) => {
          return (
            <div className={styles.movement} key={index}>
              <MovementsCard
                user={movement?.user}
                // Refactor "actionType" required
                actionType={
                  movement.actionType === 'UPDATE'
                    ? actionType.UPDATE
                    : movement.actionType === 'CREATE'
                    ? actionType.CREATE
                    : actionType.DELETE
                }
                directlyAffectedEntity={movement?.directlyAffectedEntity}
                indirectlyAffectedEntity={movement?.indirectlyAffectedEntity}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
