import { SeniorityType } from '../types';
import { Employee } from './types';
import {
  AvailabilityType,
  CountriesEnum,
  EmployeeTypeEnum,
  ExpertiseAreaType,
  ProjectRoleType,
  RadiumRoleType,
  RankType,
} from 'src/types';

export enum Actions {
  GET_EMPLOYEE_PENDING = 'GET_EMPLOYEE_PENDING',
  GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS',
  GET_EMPLOYEE_ERROR = 'GET_EMPLOYEE_ERROR',
  GET_EMPLOYEE_BY_ID_PENDING = 'GET_EMPLOYEE_BY_ID_PENDING',
  GET_EMPLOYEE_BY_ID_SUCCESS = 'GET_EMPLOYEE_BY_ID_SUCCESS',
  GET_EMPLOYEE_BY_ID_ERROR = 'GET_EMPLOYEE_BY_ID_ERROR',
  EDIT_EMPLOYEE_PENDING = 'EDIT_EMPLOYEE_PENDING',
  EDIT_EMPLOYEE_SUCCESS = 'EDIT_EMPLOYEE_SUCCESS',
  EDIT_EMPLOYEE_ERROR = 'EDIT_EMPLOYEE_ERROR',
  SET_SELECTED_EMPLOYEE = 'SET_SELECTED_EMPLOYEE',
  CLEAN_SELECTED_EMPLOYEE = 'CLEAN_SELECTED_EMPLOYEE',
}

export const defaultEmployee: Employee = {
  _id: '',
  skills: [
    {
      _id: '',
      name: '',
      description: '',
      isValidated: false,
    },
    {
      _id: '',
      name: '',
      isValidated: false,
    },
    {
      _id: '',
      name: '',
      description: '',
      isValidated: false,
    },
    {
      _id: '',
      name: '',
      isValidated: false,
    },
    {
      _id: '',
      name: '',
      description: '',
      isValidated: false,
    },
  ],
  memberHistory: [
    {
      _id: '',
      employee: {
        _id: '',
        user: {
          _id: '',
          firstName: '',
          lastName: '',
        },
      },
      project: {
        _id: '',
        projectName: '',
        isActive: false,
      },
      role: ProjectRoleType.DEV,
      rate: 100,
      seniority: SeniorityType.JUNIOR,
      memberDedication: 0,
      helper: [
        {
          _id: '',
          helperReference: {
            _id: '',
            user: {
              _id: '',
              firstName: '',
              lastName: '',
            },
          },
          dedication: 0,
          isActive: false,
        },
      ],
      startDate: new Date().toString(),
      endDate: new Date().toString(),
      active: false,
    },
  ],
  user: {
    _id: '',
    email: '',
    firstName: '',
    lastName: '',
    location: '',
    birthDate: new Date().toString(),
    isActive: false,
    dni: 0,
    phone: 0,
    address: '',
    photoType: '',
  },
  role: RadiumRoleType.EXPLORER,
  rank: 1 as RankType,
  expertiseArea: ExpertiseAreaType.DEV,
  absences: [],
  availability: {
    description: AvailabilityType.Available,
    availableHours: 0,
    dedicatedHours: 0,
    _id: '',
  },
  growthPlan: [
    {
      objectiveRole: '',
      objectiveLevel: 0,
      objectiveSalary: 0,
      objectiveDate: new Date().toString(),
      description: '',
      _id: '',
    },
  ],
  notes: '',
  currentProjects: [],
  closestAvailableDate: undefined,
  categories: [{ _id: '', name: '' }],
  company: { _id: '', name: CountriesEnum.ARGENTINA },
  employeeType: EmployeeTypeEnum.OPERATIVO,
  isActive: true,
};
