import Joi from 'joi';

const salaryRangeValidation = Joi.object({
  adjustment: Joi.number().greater(0).max(100).messages({
    'number.base': 'El % debe ser un número',
    'number.greater': 'El % debe ser mayor a 0',
    'number.max': 'El % debe ser menor o igual a 100',
  }),
});

export default salaryRangeValidation;
