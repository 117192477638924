import styles from './noResultsMatched.module.css';
import animation from './no_results_marched.json';
import React from 'react';
import Lottie from 'react-lottie';

const NoResultsMatched = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
  };
  return (
    <div className={styles.lottieContainer}>
      <h2 className={styles.noResults}>No se encontraron resultados.</h2>
      <Lottie options={defaultOptions} height={400} width={600} />
    </div>
  );
};

export default NoResultsMatched;
