import { createSlice } from '@reduxjs/toolkit';

type modalsState = {
  shouldBlock: boolean;
};

const initialState: modalsState = {
  shouldBlock: false,
};

const navigationBlockerSlice = createSlice({
  name: 'navigationBlocker',
  initialState,
  reducers: {
    blockNavigation: (state) => {
      state.shouldBlock = true;
    },
    unblockNavigation: (state) => {
      state.shouldBlock = false;
    },
  },
});

export const { blockNavigation, unblockNavigation } = navigationBlockerSlice.actions;

export default navigationBlockerSlice.reducer;
