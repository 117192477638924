import { clientsProjectsHeaders } from '../constants';
import styles from './tableProjects.module.css';
import { TableProjectProps } from './types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import { RootState, useAppSelector } from 'src/redux/store';
import { openProjectsModal } from 'src/redux/ui/actions';
import { AppDispatch } from 'src/types';

const TableProjectClient = (props: TableProjectProps) => {
  const { projectList } = props;
  const selectedClient = useAppSelector((state: RootState) => state.client?.selectedClient);
  const showProjectModal = useAppSelector((state: RootState) => state.ui.showProjectModal);

  const projectsCount = selectedClient.projects?.filter((project) => project.isActive).length <= 2;

  const dispatch: AppDispatch<null> = useDispatch();

  return (
    <div className={styles.tableContainer}>
      <span>Últimos proyectos</span>
      <table className={styles.table}>
        <thead>
          <tr>
            {clientsProjectsHeaders?.map((header, index) => {
              return (
                <th className={styles.header} key={index}>
                  {header.header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {projectList?.map((data) => {
            return (
              <tr key={data.id}>
                {clientsProjectsHeaders.map((header, index) => {
                  return (
                    <td className={styles.rows} key={index}>
                      {data[header.key]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!showProjectModal && (
        <div className={styles.viewMore}>
          <Button
            testId="viewMoreButton"
            materialVariant={Variant.TEXT}
            onClick={() => dispatch(openProjectsModal())}
            label="Ver más"
            disabled={projectsCount}
          />
        </div>
      )}
    </div>
  );
};

export default TableProjectClient;
