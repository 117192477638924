import { FormValues } from '../types';
import TableProjectClient from './tableProjects';
import { joiResolver } from '@hookform/resolvers/joi';
import IconButton from '@mui/material/IconButton';
import { format } from 'date-fns';
import _, { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaXmark, FaBell } from 'react-icons/fa6';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styles from 'src/components/pages/clients/clientForm/clientsForm.module.css';
import validations from 'src/components/pages/clients/validations';
import CustomNotifications from 'src/components/shared/common/customNotificationForm';
import { Resource } from 'src/components/shared/common/customNotificationForm/types';
import {
  Button,
  ConfirmationMessage,
  DatePicker,
  Modal,
  TextInput,
} from 'src/components/shared/ui';
import { Variant } from 'src/components/shared/ui/buttons/button/types';
import EndDateCheckbox from 'src/components/shared/ui/inputs/endDateCheckbox';
import { getByFilterResourceRequest } from 'src/config/api';
import { UiRoutes } from 'src/constants';
import { clearSelectedClient } from 'src/redux/client/actions';
import { addClient, editClient, getClientsById } from 'src/redux/client/thunks';
import { RootState, useAppSelector } from 'src/redux/store';
import {
  closeConfirmationModal,
  closeFormModal,
  closeProjectsModal,
  openConfirmationModal,
  openFormModal,
} from 'src/redux/ui/actions';
import { AppDispatch, Resources } from 'src/types';
import { capitalizeFirstLetter } from 'src/utils/formatters';

const ClientForm = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch: AppDispatch<null> = useDispatch();

  const showNotificationModal = useAppSelector((state: RootState) => state.ui.showFormModal);
  const selectedClient = useAppSelector((state: RootState) => state.client?.selectedClient);
  const showConfirmModal = useAppSelector((state: RootState) => state.ui.showConfirmModal);
  const showProjectModal = useAppSelector((state: RootState) => state.ui.showProjectModal);

  const [relationshipStart, setRelationshipStart] = useState(null);
  const [relationshipEndDate, setRelationshipEndDate] = useState(null);

  const [endDateDisabled, setEndDateDisabled] = useState(false);
  const [boxChecked, setBoxChecked] = useState(false);
  const [clientNameValidation, setClientNameValidation] = useState(false);

  const nameValidationTrigger = async () => {
    await trigger('name');
  };

  const endDateValidationTrigger = async () => {
    await trigger('relationshipStart');
  };

  useEffect(() => {
    if (getValues('name')) {
      nameValidationTrigger();
    }
  }, [clientNameValidation]);

  useEffect(() => {
    id && dispatch(getClientsById(id));
    return () => {
      dispatch(clearSelectedClient());
    };
  }, []);

  useEffect(() => {
    if (Object.keys(selectedClient).length) {
      reset({
        name: selectedClient.name,
        localContact: {
          name: selectedClient.localContact.name,
          email: selectedClient.localContact.email,
        },
        clientContact: {
          name: selectedClient.clientContact.name,
          email: selectedClient.clientContact.email,
        },
        relationshipStart: selectedClient.relationshipStart,
        relationshipEnd: selectedClient.relationshipEnd,
        notes: selectedClient.notes,
        isActive: true,
      });
      setEndDateDisabled(!selectedClient.relationshipEnd);
      setRelationshipStart(selectedClient.relationshipStart);
      setRelationshipEndDate(selectedClient.relationshipEnd);
    }
  }, [selectedClient]);

  const { control, reset, trigger, getValues, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      name: '',
      localContact: {
        name: '',
        email: '',
      },
      clientContact: {
        name: '',
        email: '',
      },
      relationshipStart: null,
      relationshipEnd: null,
      notes: '',
      isActive: true,
    },
    mode: 'onChange',
    resolver: joiResolver(
      validations.clientValidation(clientNameValidation, relationshipStart, relationshipEndDate),
    ),
  });

  const startDate = watch('relationshipStart');
  const relationshipEnd = watch('relationshipEnd');

  useEffect(() => {
    setRelationshipStart(startDate);
    setRelationshipEndDate(relationshipEnd);
  }, [startDate, relationshipEnd]);

  useEffect(() => {
    endDateValidationTrigger();
  }, [relationshipStart, relationshipEndDate]);

  const nameChangeHandler = useCallback(
    debounce(async (e, client) => {
      const inputValue = e.target.value.trim();
      if (id && inputValue.toLowerCase() === client.name.toLowerCase()) {
        return;
      }
      try {
        const response = await getByFilterResourceRequest('/clients/clientExists', {
          name: inputValue,
        });

        if (!response.error) {
          setClientNameValidation(false);
        }
      } catch (error: unknown) {
        setClientNameValidation(true);
      }
    }, 500),
    [],
  );

  const latestClientsActiveProjects = selectedClient?.projects?.filter((item) => item.isActive);

  const formattedProjects = useMemo(
    () =>
      latestClientsActiveProjects?.map((item) => ({
        id: item?._id ?? '-',
        name: item?.projectName ?? '-',
        isCritic: item?.isCritic ?? '-',
        startDate: item?.startDate ? format(new Date(item?.startDate), 'dd/MM/yyyy') : '-',
        endDate: item?.endDate ? format(new Date(item?.endDate), 'dd/MM/yyyy') : '-',
      })),
    [latestClientsActiveProjects],
  );

  const projectsListTable = useMemo(
    () => formattedProjects?.reverse().slice(-2),
    [formattedProjects],
  );

  const showProjectTable = Boolean(latestClientsActiveProjects?.length && id);

  const onSubmit = async () => {
    const data = getValues();
    const body: FormValues = {
      name:
        !id || !data.name
          ? capitalizeFirstLetter(getValues('name')).trim()
          : capitalizeFirstLetter(data.name).trim(),
      localContact: {
        name: data.localContact.name,
        email: data.localContact.email,
      },
      clientContact: {
        name: data.clientContact.name,
        email: data.clientContact.email,
      },
      relationshipStart: data.relationshipStart,
      notes: data.notes,
    };

    if (!endDateDisabled) body.relationshipEnd = data.relationshipEnd;

    const options = {
      id: id,
      body: JSON.stringify(body),
    };

    if (id) {
      await dispatch(editClient(options));
    } else {
      await dispatch(addClient(options));
    }
    dispatch(closeConfirmationModal());
    onClose();
  };

  const onClose = () => {
    handleNavigation(`${UiRoutes.ADMIN_AM}${UiRoutes.CLIENTS}`);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleEndDateDisable = (data) => {
    setEndDateDisabled(data);
  };

  const datesAreValid = boxChecked
    ? Boolean(relationshipStart)
    : Boolean(relationshipStart) && (Boolean(relationshipEnd) || relationshipEnd === null);

  const enableButton = id
    ? watch('name') !== selectedClient?.name ||
      watch('localContact.name') !== selectedClient?.localContact?.name ||
      watch('localContact.email') !== selectedClient?.localContact?.email ||
      watch('clientContact.name') !== selectedClient?.clientContact?.name ||
      watch('clientContact.email') !== selectedClient?.clientContact?.email ||
      watch('notes') !== selectedClient?.notes ||
      datesAreValid
    : !(
        !watch('name') ||
        !watch('localContact.name') ||
        !watch('localContact.email') ||
        !watch('clientContact.name') ||
        !watch('clientContact.email') ||
        !datesAreValid
      );

  return (
    <div className={styles.container}>
      <div className={styles.welcomeMessage}>
        <div>{id ? `Editar ${selectedClient?.name}` : 'Nuevo Cliente'}</div>
        <div className={id}>
          {id && (
            <IconButton onClick={() => dispatch(openFormModal())}>
              <FaBell color={'#373867'} fontSize={'25px'} />
            </IconButton>
          )}
        </div>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.leftColumns}>
            <div className={styles.inputs}>
              <TextInput
                control={control}
                testId={'clientNameInput'}
                label="Cliente"
                placeholder="Nombre de la empresa"
                name="name"
                type={'text'}
                variant="outlined"
                fullWidth
                handleOnChange={(e) => nameChangeHandler(e, selectedClient)}
              />
            </div>
            <div className={styles.dateContainer}>
              <div className={styles.datePickers}>
                <div>
                  <DatePicker
                    label={'Inicio'}
                    testId={'startDatePickerTestId'}
                    name="relationshipStart"
                    control={control}
                    minDate={new Date(2017, 0, 1)}
                    maxDate={selectedClient?.relationshipEnd}
                  />
                  <EndDateCheckbox
                    changed={boxChecked}
                    setChanged={() => {
                      setBoxChecked(!boxChecked);
                      setValue('relationshipEnd', null);
                    }}
                    endDateDisabled={endDateDisabled}
                    handleEndDateDisable={handleEndDateDisable}
                    resource={Resources.Clientes}
                  />
                </div>
                <div>
                  <DatePicker
                    label={'Fin'}
                    testId={'endDatePickerTestId'}
                    name="relationshipEnd"
                    minDate={startDate}
                    maxDate={
                      new Date(
                        new Date(startDate).getFullYear() + 10,
                        new Date(startDate).getMonth(),
                        new Date(startDate).getDate(),
                      )
                    }
                    control={control}
                    disabled={endDateDisabled}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.secondWrapperInputs}>
            <div>
              <div className={`${styles.inputs} ${styles.leftInput}`}>
                <TextInput
                  control={control}
                  testId={'clientContactInput'}
                  label="Contacto cliente"
                  placeholder="Nombre y apellido del contacto del cliente"
                  name="clientContact.name"
                  type={'text'}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div className={styles.inputs}>
                <TextInput
                  control={control}
                  testId={'clientEmailInput'}
                  label="Email cliente"
                  name="clientContact.email"
                  type={'text'}
                  variant="outlined"
                  placeholder="Email del contacto del cliente"
                  fullWidth
                />
              </div>
            </div>
            <div>
              <div className={`${styles.inputs} ${styles.leftInput}`}>
                <TextInput
                  control={control}
                  testId={'localContactInput'}
                  label="Contacto Radium Rocket"
                  placeholder="Nombre y apellido del contacto de Radium Rocket"
                  name="localContact.name"
                  type={'text'}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div className={styles.inputs}>
                <TextInput
                  control={control}
                  testId={'localEmailInput'}
                  label="Email Radium Rocket"
                  name="localContact.email"
                  type={'text'}
                  variant="outlined"
                  placeholder="Email del contacto de Radium Rocket"
                  fullWidth
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightContainer}>
          {showProjectTable && <TableProjectClient projectList={projectsListTable} />}
          <div className={styles.notes}>
            <TextInput
              control={control}
              testId={'notesInput'}
              label="Notas"
              name="notes"
              type={'text'}
              variant="outlined"
              fullWidth
              multiline
              rows={5}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <div>
          <Button
            testId="cancelButton"
            materialVariant={Variant.OUTLINED}
            onClick={() => onClose()}
            label="Cancelar"
          />
        </div>
        <div>
          <Button
            testId="confirmButton"
            materialVariant={Variant.CONTAINED}
            onClick={
              selectedClient._id ? () => dispatch(openConfirmationModal()) : () => onSubmit()
            }
            label="Guardar cambios"
            disabled={!enableButton}
          />
        </div>
      </div>
      <div>
        <Modal
          testId={'client-custom-notification'}
          isOpen={showNotificationModal}
          onClose={() => dispatch(closeFormModal())}
        >
          <CustomNotifications resource={Resource.CLIENT} id={id} />
        </Modal>
      </div>
      <Modal
        testId="editClientModal"
        styles={styles.modal}
        isOpen={!showNotificationModal && showConfirmModal}
        onClose={() => dispatch(closeConfirmationModal())}
      >
        <ConfirmationMessage
          description={`¿Desea editar al cliente ${selectedClient.name}?`}
          title={'Editar Cliente'}
          handleConfirm={() => onSubmit()}
          handleClose={() => dispatch(closeConfirmationModal())}
        />
      </Modal>
      <Modal
        testId="projectModal"
        isOpen={showProjectModal}
        onClose={() => dispatch(closeProjectsModal())}
      >
        <div className={styles.modalContainer}>
          <div className={styles.crossIcon}>
            <IconButton aria-label="close" onClick={() => dispatch(closeProjectsModal())}>
              <FaXmark color={'#373867'} fontSize={'20px'} />
            </IconButton>
          </div>
          <div className={styles.modalTableContainer}>
            <TableProjectClient projectList={formattedProjects} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ClientForm;
