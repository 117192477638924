import { openToast } from '../toast/toastSlice';
import { AppThunk } from '../types';
import { setLoaderOff, setLoaderOn } from '../ui/actions';
import {
  editEmployeeError,
  editEmployeePending,
  editEmployeeSuccess,
  getEmployeeByIdError,
  getEmployeeByIdPending,
  getEmployeeByIdSuccess,
  getEmployeeError,
  getEmployeePending,
  getEmployeeSuccess,
} from './actions';
import { Employee } from './types';
import { Dispatch } from 'redux';
import { editResourceRequest, getResourceRequest } from 'src/config/api';
import { ApiRoutes } from 'src/constants';

export const getEmployees: AppThunk = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getEmployeePending());
    dispatch(setLoaderOn());
    try {
      const response = await getResourceRequest(`${ApiRoutes.EMPLOYEE}?type=operational`);
      if (response.error) throw new Error(response.error);
      dispatch(getEmployeeSuccess(response.data));
    } catch (error) {
      dispatch(getEmployeeError({ message: error.message, errorType: error.errorType }));
      dispatch(openToast({ message: error.message, error: true }));
    } finally {
      dispatch(setLoaderOff());
    }
  };
};

export const getEmployeeById: AppThunk = (id) => {
  return async (dispatch: Dispatch) => {
    dispatch(getEmployeeByIdPending());
    dispatch(setLoaderOn());
    try {
      const response = await getResourceRequest(`${ApiRoutes.EMPLOYEE}/${id}`);
      if (response.error) throw new Error(response.error);
      dispatch(getEmployeeByIdSuccess(response.data));
    } catch (error) {
      dispatch(getEmployeeByIdError({ message: error.message, errorType: error.errorType }));
      dispatch(openToast({ message: error.message, error: true }));
    } finally {
      dispatch(setLoaderOff());
    }
  };
};

// NOt in use currently. Remove one we move to react query.
export const editEmployee: AppThunk = (options: { body: Employee; id: string }) => {
  return async (dispatch: Dispatch) => {
    dispatch(editEmployeePending());
    dispatch(setLoaderOn());
    try {
      const response = await editResourceRequest(ApiRoutes.EMPLOYEE, options);
      if (response.error) throw new Error(response.error);
      dispatch(editEmployeeSuccess(response.data, options.id));
      dispatch(openToast({ message: 'Empleado editado con éxito', error: false }));
    } catch (error) {
      dispatch(editEmployeeError({ message: error.message, errorType: error.errorType }));
      dispatch(openToast({ message: error.message, error: true }));
    } finally {
      dispatch(setLoaderOff());
    }
  };
};
