import { AvailabilityType, NotificationType, NotificationTypes, RadiumRoleType } from 'src/types';

export const expertiseAreaOptions = [
  { label: 'DEV', value: 'DEV' },
  { label: 'QA', value: 'QA' },
  { label: 'UX/UI', value: 'UX/UI' },
];

export const roleCheckboxOptions = [
  { label: 'TRAINEE', value: RadiumRoleType.TRAINEE },
  { label: 'EXPLORER', value: RadiumRoleType.EXPLORER },
  { label: 'CREW MEMBER', value: RadiumRoleType.CREW_MEMBER },
  { label: 'PILOT', value: RadiumRoleType.PILOT },
  { label: 'LIEUTENANT', value: RadiumRoleType.LIEUTENANT },
  { label: 'COMMANDER', value: RadiumRoleType.COMMANDER },
  { label: 'CAPTAIN', value: RadiumRoleType.CAPTAIN },
];

export const availabilityOptions = [
  { value: AvailabilityType.Available, label: 'Disponible' },
  { value: AvailabilityType.NotAvailable, label: 'No Disponible' },
  { value: AvailabilityType.PartTime, label: 'Tiempo Parcial' },
];

export const notificationsCheckboxData: NotificationType[] = [
  { label: 'Empleados sin proyecto', value: NotificationTypes.EMPLOYEES_WITHOUT_PROJECTS },
  { label: 'Ausencias', value: NotificationTypes.ABSENCES_EMPLOYEE },
  { label: 'Empleados finalizando proyectos', value: NotificationTypes.EMPLOYEES_ENDING_PROJECTS },
  {
    label: 'Feedback de desempeño',
    value: NotificationTypes.EMPLOYEES_PERFORMANCE_FEEDBACK_PENDING,
  },
  { label: 'Plan de crecimiento', value: NotificationTypes.GROWTH_PLAN_OBJECTIVE_DATE },
  { label: 'Clientes sin proyectos', value: NotificationTypes.CLIENTS_WITHOUT_PROJECTS },
  { label: 'Clientes finalizando', value: NotificationTypes.CLIENTS_WITH_CLOSE_END_DATE },
  { label: 'Proyectos sin miembros', value: NotificationTypes.PROJECTS_WITHOUT_MEMBERS },
  { label: 'Proyectos finalizando', value: NotificationTypes.PROJECTS_ABOUT_TO_END },
];
